<mat-card [hidden]="checklist.id == 0" [@slideInOut]>
  <mat-card-title>
    License Checklist
  </mat-card-title>
  <mat-card-content>
    <form [formGroup]="checklistForm"  fxFlex="100" fxLayout="column" fxLayoutGap="12px">

      <div fxLayout="column" fxLayoutGap="12px" [hidden]="entityType != sharedService.workerPermit" class="text-wrap">
        <mat-form-field>
          <input matInput placeholder="License Name" formControlName="licenseName">
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="License Id" formControlName="licenseId">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Reviewing Team Member</mat-label>
          <mat-select formControlName="teamMember" placeholder="Team Member">
            <mat-option *ngFor="let staff of staff" value={{staff.name}}>{{staff.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Review Date</mat-label>
          <input placeholder="mm/dd/yyyy" formControlName="reviewDate" matInput [matDatepicker]="reviewDate"
            [max]="today" />
          <mat-datepicker-toggle matSuffix [for]="reviewDate"></mat-datepicker-toggle>
          <mat-datepicker #reviewDate>
            <mat-datepicker-actions>
              <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
              <button mat-button matDatepickerCancel>Cancel</button>
            </mat-datepicker-actions>
          </mat-datepicker>
          <mat-error *ngIf="checklistForm.get('reviewDate').value > today">
            Please choose an earlier date.
          </mat-error>
        </mat-form-field>

        <mat-checkbox formControlName="demographicAndContactInfoComplete">
          <span class="text-wrap" *ngIf="!checklistForm.get('demographicAndContactInfoComplete').value" [ngStyle]="{'color': checklistForm.get('demographicAndContactInfoComplete').hasError('required')? 'red': 'black'}">Verified Demographic and Contact Info Complete<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('demographicAndContactInfoComplete').value">Verified Demographic and Contact Info Complete</span>
        </mat-checkbox>

        <mat-checkbox formControlName="attestationsAndSignedComplete">
          <span class="text-wrap" *ngIf="!checklistForm.get('attestationsAndSignedComplete').value" [ngStyle]="{'color': checklistForm.get('attestationsAndSignedComplete').hasError('required')? 'red': 'black'}">Verified Attestations Complete and Application Signed<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('attestationsAndSignedComplete').value">Verified Attestations Complete and Application Signed</span>
        </mat-checkbox>

        <mat-checkbox formControlName="photoIdComplete">
          <span class="text-wrap" *ngIf="!checklistForm.get('photoIdComplete').value" [ngStyle]="{'color': checklistForm.get('photoIdComplete').hasError('required')? 'red': 'black'}">Verified Valid and Unexpired Photo Identification Received and Uploaded in TLC<strong *ngIf="!renewal">*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('photoIdComplete').value">Verified Valid and Unexpired Photo Identification Received and Uploaded in TLC</span>
          <span class="text-wrap" *ngIf="renewal"><i> (A new document is not required for a renewal, please ensure you have the document from the original application)</i></span>
        </mat-checkbox>

        <mat-form-field>
          <mat-label>Identification Type</mat-label>
          <mat-select formControlName="photoIdType" placeholder="Identification Type">
            <mat-option style="min-height: 48px; line-height: normal; height: auto; padding: 10px 20px;" class="text-wrap" *ngFor="let type of photoIdTypes" value={{type}}>{{type}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-checkbox formControlName="idNameMatchCompleted">
          <span class="text-wrap" *ngIf="!checklistForm.get('idNameMatchCompleted').value" [ngStyle]="{'color': checklistForm.get('idNameMatchCompleted').hasError('required')? 'red': 'black'}">Verified Name and DOB on ID Match Information Provided on the Application<strong *ngIf="!renewal">*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('idNameMatchCompleted').value">Verified Name and DOB on ID Match Information Provided on the Application</span>
          <span class="text-wrap" *ngIf="renewal"><i> (A new document is not required for a renewal, please ensure you have the document from the original application)</i></span>
        </mat-checkbox>

        <mat-checkbox formControlName="legalAgeComplete">
          <span *ngIf="!checklistForm.get('legalAgeComplete').value" [ngStyle]="{'color': checklistForm.get('legalAgeComplete').hasError('required')? 'red': 'black'}">Verified Age 21+<strong>*</strong></span>
          <span *ngIf="checklistForm.get('legalAgeComplete').value">Verified Age 21+</span>

        </mat-checkbox>

        <mat-checkbox formControlName="backgroundCheckPassed">
          <span *ngIf="!checklistForm.get('backgroundCheckPassed').value" [ngStyle]="{'color': checklistForm.get('backgroundCheckPassed').hasError('required')? 'red': 'black'}">Background Check Passed<strong>*</strong></span>
          <span *ngIf="checklistForm.get('backgroundCheckPassed').value">Background Check Passed</span>
        </mat-checkbox>

        <mat-form-field>
          <mat-label [ngStyle]="{'color': checklistForm.get('backgroundCheckDate').value == ''? 'red': 'black'}">Background Check Date</mat-label>
          <input placeholder="mm/dd/yyyy" formControlName="backgroundCheckDate" matInput [matDatepicker]="backgroundCheckDate"
            [max]="today" />
          <mat-datepicker-toggle matSuffix [for]="backgroundCheckDate"></mat-datepicker-toggle>
          <mat-datepicker #backgroundCheckDate>
            <mat-datepicker-actions>
              <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
              <button mat-button matDatepickerCancel>Cancel</button>
            </mat-datepicker-actions>
          </mat-datepicker>
          <mat-error *ngIf="checklistForm.get('backgroundCheckDate').value > today">
            Please choose an earlier date.
          </mat-error>
        </mat-form-field>

      <span fxFlex></span>
      <span fxFlex></span>
      <mat-checkbox formControlName="peerReviewComplete">
        <span class="text-wrap" *ngIf="!checklistForm.get('peerReviewComplete').value" [ngStyle]="{'color': checklistForm.get('peerReviewComplete').hasError('required')? 'red': 'black'}">Peer Review Complete<strong>*</strong></span>
        <span class="text-wrap" *ngIf="checklistForm.get('peerReviewComplete').value">Peer Review Complete</span>
      </mat-checkbox>

      <mat-form-field>
        <mat-label [ngStyle]="{'color': !checklistForm.get('peerReviewDate').value? 'red': 'black'}">Peer Review Completed On</mat-label>
        <input placeholder="mm/dd/yyyy" formControlName="peerReviewDate" matInput [matDatepicker]="peerReviewDatePermit"
          [max]="today" />
        <mat-datepicker-toggle matSuffix [for]="peerReviewDatePermit"></mat-datepicker-toggle>
        <mat-datepicker #peerReviewDatePermit>
          <mat-datepicker-actions>
            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
            <button mat-button matDatepickerCancel>Cancel</button>
          </mat-datepicker-actions>
        </mat-datepicker>
        <mat-error *ngIf="checklistForm.get('peerReviewDate').value > today">
          Please choose an earlier date.
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Peer Reviewed by</mat-label>
        <mat-select formControlName="peerReviewMember" placeholder="Team Member">
          <mat-option *ngFor="let staff of staff" value={{staff.name}}>{{staff.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

        <mat-form-field fxFlex="50" fxFlex.lt-md="100">
          <textarea matInput placeholder="Reviewer Notes Log" formControlName="reviewNotes"></textarea>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Requirements Met Date</mat-label>
          <input placeholder="mm/dd/yyyy" formControlName="requirementsMetDate" matInput [matDatepicker]="requirementsMetDate"
            [max]="today" />
          <mat-datepicker-toggle matSuffix [for]="requirementsMetDate"></mat-datepicker-toggle>
          <mat-datepicker #requirementsMetDate>
            <mat-datepicker-actions>
              <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
              <button mat-button matDatepickerCancel>Cancel</button>
            </mat-datepicker-actions>
          </mat-datepicker>
          <mat-error *ngIf="checklistForm.get('requirementsMetDate').value > today">
            Please choose an earlier date.
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="column" fxLayoutGap="12px" [hidden]="entityType != sharedService.manufacturer" class="text-wrap">
        <mat-form-field>
          <input matInput placeholder="Operational Name" formControlName="licenseName">
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="License Id" formControlName="licenseId">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Reviewing Team Member</mat-label>
          <mat-select formControlName="teamMember" placeholder="Team Member">
            <mat-option *ngFor="let staff of staff" value={{staff.name}}>{{staff.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Review Date</mat-label>
          <input placeholder="mm/dd/yyyy" formControlName="reviewDate" matInput [matDatepicker]="reviewDateManufacturer"
            [max]="today" />
          <mat-datepicker-toggle matSuffix [for]="reviewDateManufacturer"></mat-datepicker-toggle>
          <mat-datepicker #reviewDateManufacturer>
            <mat-datepicker-actions>
              <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
              <button mat-button matDatepickerCancel>Cancel</button>
            </mat-datepicker-actions>
          </mat-datepicker>
          <mat-error *ngIf="checklistForm.get('reviewDate').value > today">
            Please choose an earlier date.
          </mat-error>
        </mat-form-field>

        <mat-checkbox formControlName="applicantInfoComplete" [hidden]="entityType != sharedService.manufacturer">
          <span class="text-wrap" *ngIf="!checklistForm.get('applicantInfoComplete').value" [ngStyle]="{'color': checklistForm.get('applicantInfoComplete').hasError('required')? 'red': 'black'}">Manufacturer Applicant Information Complete<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('applicantInfoComplete').value">Manufacturer Applicant Information Complete</span>
        </mat-checkbox>
        <span class="text-wrap" style="padding-left:1em">
          • When reviewing an application, please consider the following: Review the name(s),
          contact information, and demographics to ensure all information is complete and seems
          reasonable (examples: phone number 555-555-5555 is not a real phone number or entering “9dk3nfl”
          in a field to get through the system).
        </span>

        <mat-checkbox formControlName="attestationsAndSignedComplete">
          <span class="text-wrap" *ngIf="!checklistForm.get('attestationsAndSignedComplete').value" [ngStyle]="{'color': checklistForm.get('attestationsAndSignedComplete').hasError('required')? 'red': 'black'}">Verified Attestations Complete and Application Signed<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('attestationsAndSignedComplete').value">Verified Attestations Complete and Application Signed</span>
        </mat-checkbox>

        <mat-checkbox formControlName="primaryContactIdentified">
          <span class="text-wrap" *ngIf="!checklistForm.get('primaryContactIdentified').value" [ngStyle]="{'color': checklistForm.get('primaryContactIdentified').hasError('required')? 'red': 'black'}">Primary Contact Identified<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('primaryContactIdentified').value">Primary Contact Identified</span>
        </mat-checkbox>


        <span class="text-indigo-A700" color="blue"><em>Individual and Legal Entity Applicants</em></span>
        <mat-checkbox formControlName="individualEntityApplicantInfoComplete">
          <span class="text-wrap" *ngIf="!checklistForm.get('individualEntityApplicantInfoComplete').value" [ngStyle]="{'color': checklistForm.get('individualEntityApplicantInfoComplete').hasError('required')? 'red': 'black'}">Individual and/or Entity Applicant Information Complete<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('individualEntityApplicantInfoComplete').value">Individual and/or Entity Applicant Information Complete</span>
        </mat-checkbox>

        <mat-checkbox formControlName="applicantInformationFormsReceived">
          <span class="text-wrap" *ngIf="!checklistForm.get('applicantInformationFormsReceived').value" [ngStyle]="{'color': checklistForm.get('applicantInformationFormsReceived').hasError('required')? 'red': 'black'}">Individual and Legal Entity Applicant Information Forms Received and Uploaded for Each Identified Applicant<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('applicantInformationFormsReceived').value">Individual and Legal Entity Applicant Information Forms Received and Uploaded for Each Identified Applicant</span>
        </mat-checkbox>

        <mat-checkbox formControlName="photoIdComplete">
          <span class="text-wrap" *ngIf="!checklistForm.get('photoIdComplete').value" [ngStyle]="{'color': checklistForm.get('photoIdComplete').hasError('required')? 'red': 'black'}">Valid and Unexpired Photo Identification Received and Uploaded in TLC for Each Applicant<strong *ngIf="!renewal">*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('photoIdComplete').value">Valid and Unexpired Photo Identification Received and Uploaded in TLC for Each Applicant</span>
          <span class="text-wrap" *ngIf="renewal"><i> (A new document is not required for a renewal, please ensure you have the document from the original application)</i></span>
        </mat-checkbox>
        <div fxLayout="column" class="text-wrap">
          <span style="padding-left:1em">The following forms of Photo Identification are accepted:</span>
          <span style="padding-left:1em">• Passport;</span>
          <span style="padding-left:1em">• Driver license, whether issued by the State of Oregon or by another state of the United States;</span>
          <span style="padding-left:1em">• Identification card issued under ORS 807.400;</span>
          <span style="padding-left:1em">• United States military identification card</span>
          <span style="padding-left:1em">• An identification card issued by a federally recognized Indian tribe with photo, name, and date of birth; or</span>
          <span style="padding-left:1em">• Any other identification card issued by a state or territory that bears a picture of the person, the name of the person, the person's date of birth, and a physical description of the person.</span>
        </div>

        <button mat-flat-button color="primary" *ngIf="checklist.id != 0" (click)="openApplicants()">
          View Applicants
        </button>


        <span class="text-indigo-A700" color="blue"><em>Manufacturer Endorsements</em></span>
        <mat-checkbox formControlName="manufacturerEndorsements">
          <span class="text-wrap" *ngIf="!checklistForm.get('manufacturerEndorsements').value" [ngStyle]="{'color': checklistForm.get('manufacturerEndorsements').hasError('required')? 'red': 'black'}">Manufacturer Endorsements Selected<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('manufacturerEndorsements').value">Manufacturer Endorsements Selected</span>
        </mat-checkbox>


        <span class="text-indigo-A700" color="blue"><em>Additional Required Documents</em></span>
        <mat-checkbox formControlName="approvedLandUseCompatibility" *ngIf="!renewal">
          <span class="text-wrap" *ngIf="!checklistForm.get('approvedLandUseCompatibility').value" [ngStyle]="{'color': checklistForm.get('approvedLandUseCompatibility').hasError('required')? 'red': 'black'}">Approved Land Use Compatibility Statement Plan Meets Requirements<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('approvedLandUseCompatibility').value">Approved Land Use Compatibility Statement Plan Meets Requirements</span>
        </mat-checkbox>
        <mat-checkbox formControlName="approvedLandUseCompatibility" *ngIf="renewal">
          <span class="text-wrap" [ngStyle]="{'color': checklistForm.get('approvedLandUseCompatibility').hasError('required')? 'red': 'black'}">Approved Land Use Compatibility Statement Plan Meets Requirements <i>(A new document is not required for a renewal, please ensure you have the document from the original application)</i></span>
        </mat-checkbox>

        <mat-checkbox formControlName="propertyOwnerStatement">
          <span class="text-wrap" [ngStyle]="{'color': validatePropertyOwner()? 'red': 'black'}">Property Owner Statement</span>
          <span class="text-wrap" *ngIf="renewal"><i> (A new document is not required for a renewal, please ensure you have the document from the original application)</i></span>
          <mat-error *ngIf="validatePropertyOwner()">
            This field is required
          </mat-error>
        </mat-checkbox>
        <mat-checkbox formControlName="propertyOwnerStatementNA">
          <span class="text-wrap" [ngStyle]="{'color': validatePropertyOwner()? 'red': 'black'}">Property Owner Statement NA</span>
          <span class="text-wrap" *ngIf="renewal"><i> (A new document is not required for a renewal, please ensure you have the document from the original application)</i></span>
          <mat-error *ngIf="validatePropertyOwner()">
            This field is required
          </mat-error>
        </mat-checkbox>

        <mat-checkbox formControlName="premisesPlanMeetsRequirements">
          <span class="text-wrap" *ngIf="!checklistForm.get('premisesPlanMeetsRequirements').value" [ngStyle]="{'color': checklistForm.get('premisesPlanMeetsRequirements').hasError('required')? 'red': 'black'}">Premises Plan<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('premisesPlanMeetsRequirements').value">Premises Plan</span>
          <span class="text-wrap" *ngIf="renewal"><i> (A new document is not required for a renewal, please ensure you have the document from the original application)</i></span>
        </mat-checkbox>

      <mat-checkbox formControlName="socialEquityPlanMeetsRequirements">
        <span class="text-wrap" *ngIf="!checklistForm.get('socialEquityPlanMeetsRequirements').value" [ngStyle]="{'color': checklistForm.get('socialEquityPlanMeetsRequirements').hasError('required')? 'red': 'black'}">Social Equity Plan Meets Requirements<strong>*</strong></span>
        <span class="text-wrap" *ngIf="checklistForm.get('socialEquityPlanMeetsRequirements').value">Social Equity Plan Meets Requirements</span>
        <span class="text-wrap" *ngIf="renewal"><i> (A new document is not required for a renewal, please ensure you have the document from the original application)</i></span>
      </mat-checkbox>

      <div fxLayout="column" class="text-wrap">
        <span style="padding-left:1em">
          A Social Equity Plan must:
        </span>
        <span style="padding-left:1em">
          ✓ An Application of diversity, equity, justice and inclusion principles to the licensee's internal practices and policies.
        </span>
        <span style="padding-left:1em">
          ✓ Objective performance measures that the licensee will use to evaluate their social equity plan.
        </span>
      </div>

      <mat-checkbox formControlName="socialEquityPlanEvaluationMeetsRequirements" *ngIf="renewal">
        <span class="text-wrap" *ngIf="!checklistForm.get('socialEquityPlanEvaluationMeetsRequirements').value" [ngStyle]="{'color': checklistForm.get('socialEquityPlanEvaluationMeetsRequirements').hasError('required')? 'red': 'black'}">Social Equity Plan Evaluation Meets Requirements<strong>*</strong></span>
        <span class="text-wrap" *ngIf="checklistForm.get('socialEquityPlanEvaluationMeetsRequirements').value">Social Equity Plan Evaluation Meets Requirements</span>
      </mat-checkbox>

      <div fxLayout="column" class="text-wrap" *ngIf="renewal">
        <span style="padding-left:1em">
          A Social Equity Plan must:
        </span>
        <span style="padding-left:1em">
          ✓ Objective performance measures from the licensee are evaluated.
        </span>
      </div>

      <mat-checkbox formControlName="reducedLicensingFeeDocumentation">
        <span class="text-wrap" [ngStyle]="{'color': validateReducedFee()? 'red': 'black'}">Reduced Licensing Fee Documentation</span>
        <mat-error *ngIf="validateReducedFee()">
          This field is required
        </mat-error>
      </mat-checkbox>
      <mat-checkbox formControlName="reducedLicensingFeeDocumentationNA">
        <span class="text-wrap" [ngStyle]="{'color': validateReducedFee()? 'red': 'black'}">Reduced Licensing Fee Documentation NA</span>
        <mat-error *ngIf="validateReducedFee()">
          This field is required
        </mat-error>
      </mat-checkbox>
      <span class="text-wrap">If requesting a reduced fee, which qualifying documentation was provided:</span>
      <mat-checkbox formControlName="nonProfitEntity">
        <span class="text-wrap" [ngStyle]="{'color': validateReducedFeeDocumentation()? 'red': 'black'}">Proposed licensed manufacturer is a non-profit entity and supporting documentation of the manufacturer's non-profit status was provided</span>
        <mat-error *ngIf="validateReducedFeeDocumentation()">
          This field is required
        </mat-error>
      </mat-checkbox>
      <span><strong>OR</strong></span>
      <span class="text-wrap">Proposed licensed manufacturer is an individual applicant and one of the following required supporting documents was provided:</span>
      <mat-checkbox formControlName="socialSecurityIncomeBenefitCompleted">
        <span class="text-wrap" [ngStyle]="{'color': validateReducedFeeDocumentation()? 'red': 'black'}">A copy of a current monthly Supplemental Security Income benefit statement showing dates of coverage</span>
        <mat-error *ngIf="validateReducedFeeDocumentation()">
          This field is required
        </mat-error>
      </mat-checkbox>
      <mat-checkbox formControlName="applicantsCurrentEligibilityStatement">
        <span class="text-wrap" [ngStyle]="{'color': validateReducedFeeDocumentation()? 'red': 'black'}">A copy of the applicant's current eligibility statement or card if enrolled in Oregon Health Plan</span>
        <mat-error *ngIf="validateReducedFeeDocumentation()">
          This field is required
        </mat-error>
      </mat-checkbox>
      <mat-checkbox formControlName="foodStampBenefits">
        <span class="text-wrap" [ngStyle]="{'color': validateReducedFeeDocumentation()? 'red': 'black'}">A copy of current proof of food stamp benefits from the Oregon Supplemental Nutrition Assistance Program</span>
        <mat-error *ngIf="validateReducedFeeDocumentation()">
          This field is required
        </mat-error>
      </mat-checkbox>
      <mat-checkbox formControlName="servedInArmedForces">
        <span class="text-wrap" [ngStyle]="{'color': validateReducedFeeDocumentation()? 'red': 'black'}">A copy of proof of having served in the Armed Forces, such as but not limited to, submitting a Veteran's Administration form DD-214</span>
        <mat-error *ngIf="validateReducedFeeDocumentation()">
          This field is required
        </mat-error>
      </mat-checkbox>

      <span fxFlex></span>
      <span fxFlex></span>
      <mat-checkbox formControlName="peerReviewComplete">
        <span class="text-wrap" *ngIf="!checklistForm.get('peerReviewComplete').value" [ngStyle]="{'color': checklistForm.get('peerReviewComplete').hasError('required')? 'red': 'black'}">Peer Review Complete<strong>*</strong></span>
        <span class="text-wrap" *ngIf="checklistForm.get('peerReviewComplete').value">Peer Review Complete</span>
      </mat-checkbox>

      <mat-form-field>
        <mat-label [ngStyle]="{'color': !checklistForm.get('peerReviewDate').value? 'red': 'black'}">Peer Review Completed On</mat-label>
        <input placeholder="mm/dd/yyyy" formControlName="peerReviewDate" matInput [matDatepicker]="peerReviewDateManufacturer"
          [max]="today" />
        <mat-datepicker-toggle matSuffix [for]="peerReviewDateManufacturer"></mat-datepicker-toggle>
        <mat-datepicker #peerReviewDateManufacturer>
          <mat-datepicker-actions>
            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
            <button mat-button matDatepickerCancel>Cancel</button>
          </mat-datepicker-actions>
        </mat-datepicker>
        <mat-error *ngIf="checklistForm.get('peerReviewDate').value > today">
          Please choose an earlier date.
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Peer Reviewed by</mat-label>
        <mat-select formControlName="peerReviewMember" placeholder="Team Member">
          <mat-option *ngFor="let staff of staff" value={{staff.name}}>{{staff.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="50" fxFlex.lt-md="100">
        <textarea matInput placeholder="Reviewer Notes Log" formControlName="reviewNotes"></textarea>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Requirements Met Date</mat-label>
        <input placeholder="mm/dd/yyyy" formControlName="requirementsMetDate" matInput [matDatepicker]="requirementsMetDateManufacturer"
          [max]="today" />
        <mat-datepicker-toggle matSuffix [for]="requirementsMetDateManufacturer"></mat-datepicker-toggle>
        <mat-datepicker #requirementsMetDateManufacturer>
          <mat-datepicker-actions>
            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
            <button mat-button matDatepickerCancel>Cancel</button>
          </mat-datepicker-actions>
        </mat-datepicker>
        <mat-error *ngIf="checklistForm.get('requirementsMetDate').value > today">
          Please choose an earlier date.
        </mat-error>
      </mat-form-field>
      </div>

      <div fxLayout="column" fxLayoutGap="12px" [hidden]="entityType != sharedService.testingLab" class="text-wrap">
        <mat-form-field>
          <input matInput placeholder="Operational Name" formControlName="licenseName">
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="License Id" formControlName="licenseId">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Reviewing Team Member</mat-label>
          <mat-select formControlName="teamMember" placeholder="Team Member">
            <mat-option *ngFor="let staff of staff" value={{staff.name}}>{{staff.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Review Date</mat-label>
          <input placeholder="mm/dd/yyyy" formControlName="reviewDate" matInput [matDatepicker]="reviewDateTestingLab"
            [max]="today" />
          <mat-datepicker-toggle matSuffix [for]="reviewDateTestingLab"></mat-datepicker-toggle>
          <mat-datepicker #reviewDateTestingLab>
            <mat-datepicker-actions>
              <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
              <button mat-button matDatepickerCancel>Cancel</button>
            </mat-datepicker-actions>
          </mat-datepicker>
          <mat-error *ngIf="checklistForm.get('reviewDate').value > today">
            Please choose an earlier date.
          </mat-error>
        </mat-form-field>

        <mat-checkbox formControlName="applicantInfoComplete">
          <span class="text-wrap" *ngIf="!checklistForm.get('applicantInfoComplete').value" [ngStyle]="{'color': checklistForm.get('applicantInfoComplete').hasError('required')? 'red': 'black'}">Laboratory Applicant Information Complete<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('applicantInfoComplete').value">Laboratory Applicant Information Complete</span>
        </mat-checkbox>
        <span class="text-wrap" style="padding-left:1em">
          • When reviewing an application, please consider the following: Review the name(s),
          contact information, and demographics to ensure all information is complete and seems
          reasonable (examples: phone number 555-555-5555 is not a real phone number or entering “9dk3nfl”
          in a field to get through the system).
        </span>

        <mat-checkbox formControlName="attestationsAndSignedComplete">
          <span class="text-wrap" *ngIf="!checklistForm.get('attestationsAndSignedComplete').value" [ngStyle]="{'color': checklistForm.get('attestationsAndSignedComplete').hasError('required')? 'red': 'black'}">All Attestations Complete and Application Signed<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('attestationsAndSignedComplete').value">All Attestations Complete and Application Signed</span>
        </mat-checkbox>

        <mat-checkbox formControlName="primaryContactIdentified">
          <span class="text-wrap" *ngIf="!checklistForm.get('primaryContactIdentified').value" [ngStyle]="{'color': checklistForm.get('primaryContactIdentified').hasError('required')? 'red': 'black'}">Primary Contact Identified<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('primaryContactIdentified').value">Primary Contact Identified</span>
        </mat-checkbox>


        <span class="text-indigo-A700" color="blue"><em>Individual and Legal Entity Applicants</em></span>
        <mat-checkbox formControlName="individualEntityApplicantInfoComplete">
          <span class="text-wrap" *ngIf="!checklistForm.get('individualEntityApplicantInfoComplete').value" [ngStyle]="{'color': checklistForm.get('individualEntityApplicantInfoComplete').hasError('required')? 'red': 'black'}">Individual and/or Entity Applicant Information Complete<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('individualEntityApplicantInfoComplete').value">Individual and/or Entity Applicant Information Complete</span>
        </mat-checkbox>

        <mat-checkbox formControlName="applicantInformationFormsReceived">
          <span class="text-wrap" *ngIf="!checklistForm.get('applicantInformationFormsReceived').value" [ngStyle]="{'color': checklistForm.get('applicantInformationFormsReceived').hasError('required')? 'red': 'black'}">Individual and Legal Entity Applicant Information Forms Received and Uploaded for Each Identified Applicant<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('applicantInformationFormsReceived').value">Individual and Legal Entity Applicant Information Forms Received and Uploaded for Each Identified Applicant</span>
        </mat-checkbox>

        <mat-checkbox formControlName="photoIdComplete">
          <span class="text-wrap" *ngIf="!checklistForm.get('photoIdComplete').value" [ngStyle]="{'color': checklistForm.get('photoIdComplete').hasError('required')? 'red': 'black'}">Valid and Unexpired Photo Identification Received and Uploaded in TLC for Each Applicant<strong *ngIf="!renewal">*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('photoIdComplete').value">Valid and Unexpired Photo Identification Received and Uploaded in TLC for Each Applicant</span>
          <span class="text-wrap" *ngIf="renewal"><i> (A new document is not required for a renewal, please ensure you have the document from the original application)</i></span>
        </mat-checkbox>
        <div fxLayout="column" class="text-wrap">
          <span style="padding-left:1em">The following forms of Photo Identification are accepted:</span>
          <span style="padding-left:1em">• Passport;</span>
          <span style="padding-left:1em">• Driver license, whether issued by the State of Oregon or by another state of the United States;</span>
          <span style="padding-left:1em">• Identification card issued under ORS 807.400;</span>
          <span style="padding-left:1em">• United States military identification card</span>
          <span style="padding-left:1em">• An identification card issued by a federally recognized Indian tribe with photo, name, and date of birth; or</span>
          <span style="padding-left:1em">• Any other identification card issued by a state or territory that bears a picture of the person, the name of the person, the person's date of birth, and a physical description of the person.</span>
        </div>

        <button mat-flat-button color="primary" *ngIf="checklist.id != 0" (click)="openApplicants()">
          View Applicants
        </button>


        <span class="text-indigo-A700" color="blue"><em>ORELAP Endorsement</em></span>
        <mat-checkbox formControlName="orelapCertificateValid">
          <span class="text-wrap" *ngIf="!checklistForm.get('orelapCertificateValid').value" [ngStyle]="{'color': checklistForm.get('orelapCertificateValid').hasError('required')? 'red': 'black'}">ORELAP Endorsement Certificate Valid and Field of Accreditation (FOA) Identifies Psilocybin<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('orelapCertificateValid').value">ORELAP Endorsement Certificate Valid and Field of Accreditation (FOA) Identifies Psilocybin</span>
          <span class="text-wrap" *ngIf="renewal"><i> (A new document is not required for a renewal, please ensure you have the document from the original application)</i></span>
        </mat-checkbox>
        <mat-checkbox formControlName="labListedOnline">
          <span class="text-wrap" *ngIf="!checklistForm.get('labListedOnline').value" [ngStyle]="{'color': checklistForm.get('labListedOnline').hasError('required')? 'red': 'black'}">Confirmed Lab is Listed on the <a href="https://www.oregon.gov/oha/PH/LABORATORYSERVICES/ENVIRONMENTALLABORATORYACCREDITATION/Documents/psilocybin_labs.pdf" target="_blank" rel="noreferrer noopener" >ORELAP Accrediting Psilocybin Testing Laboratories</a> webpage<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('labListedOnline').value">Confirmed Lab is Listed on the <a href="https://www.oregon.gov/oha/PH/LABORATORYSERVICES/ENVIRONMENTALLABORATORYACCREDITATION/Documents/psilocybin_labs.pdf" target="_blank" rel="noreferrer noopener" >ORELAP Accrediting Psilocybin Testing Laboratories</a> webpage</span>
        </mat-checkbox>


        <span class="text-indigo-A700" color="blue"><em>Additional Required Documents</em></span>
        <mat-checkbox formControlName="premisesPlanMeetsRequirements">
          <span class="text-wrap" *ngIf="!checklistForm.get('premisesPlanMeetsRequirements').value" [ngStyle]="{'color': checklistForm.get('premisesPlanMeetsRequirements').hasError('required')? 'red': 'black'}">Premises Plan<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('premisesPlanMeetsRequirements').value">Premises Plan</span>
          <span class="text-wrap" *ngIf="renewal"><i> (A new document is not required for a renewal, please ensure you have the document from the original application)</i></span>
        </mat-checkbox>
      <mat-checkbox formControlName="socialEquityPlanMeetsRequirements">
        <span class="text-wrap" *ngIf="!checklistForm.get('socialEquityPlanMeetsRequirements').value" [ngStyle]="{'color': checklistForm.get('socialEquityPlanMeetsRequirements').hasError('required')? 'red': 'black'}">Social Equity Plan Meets Requirements<strong>*</strong></span>
        <span class="text-wrap" *ngIf="checklistForm.get('socialEquityPlanMeetsRequirements').value">Social Equity Plan Meets Requirements</span>
        <span class="text-wrap" *ngIf="renewal"><i> (A new document is not required for a renewal, please ensure you have the document from the original application)</i></span>
      </mat-checkbox>
      <div fxLayout="column" class="text-wrap">
        <span style="padding-left:1em">
          A Social Equity Plan must:
        </span>
        <span style="padding-left:1em">
          ✓ An Application of diversity, equity, justice and inclusion principles to the licensee's internal practices and policies.
        </span>
        <span style="padding-left:1em">
          ✓ Objective performance measures that the licensee will use to evaluate their social equity plan.
        </span>
      </div>
      <mat-checkbox formControlName="socialEquityPlanEvaluationMeetsRequirements" *ngIf="renewal">
        <span class="text-wrap" *ngIf="!checklistForm.get('socialEquityPlanEvaluationMeetsRequirements').value" [ngStyle]="{'color': checklistForm.get('socialEquityPlanEvaluationMeetsRequirements').hasError('required')? 'red': 'black'}">Social Equity Plan Evaluation Meets Requirements<strong>*</strong></span>
        <span class="text-wrap" *ngIf="checklistForm.get('socialEquityPlanEvaluationMeetsRequirements').value">Social Equity Plan Evaluation Meets Requirements</span>
      </mat-checkbox>

      <div fxLayout="column" class="text-wrap" *ngIf="renewal">
        <span style="padding-left:1em">
          A Social Equity Plan must:
        </span>
        <span style="padding-left:1em">
          ✓ Objective performance measures from the licensee are evaluated.
        </span>
      </div>

      <span fxFlex></span>
      <span fxFlex></span>
      <mat-checkbox formControlName="peerReviewComplete">
        <span class="text-wrap" *ngIf="!checklistForm.get('peerReviewComplete').value" [ngStyle]="{'color': checklistForm.get('peerReviewComplete').hasError('required')? 'red': 'black'}">Peer Review Complete<strong>*</strong></span>
        <span class="text-wrap" *ngIf="checklistForm.get('peerReviewComplete').value">Peer Review Complete</span>
      </mat-checkbox>

      <mat-form-field>
        <mat-label [ngStyle]="{'color': !checklistForm.get('peerReviewDate').value? 'red': 'black'}">Peer Review Completed On</mat-label>
        <input placeholder="mm/dd/yyyy" formControlName="peerReviewDate" matInput [matDatepicker]="peerReviewDateTestingLab"
          [max]="today" />
        <mat-datepicker-toggle matSuffix [for]="peerReviewDateTestingLab"></mat-datepicker-toggle>
        <mat-datepicker #peerReviewDateTestingLab>
          <mat-datepicker-actions>
            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
            <button mat-button matDatepickerCancel>Cancel</button>
          </mat-datepicker-actions>
        </mat-datepicker>
        <mat-error *ngIf="checklistForm.get('peerReviewDate').value > today">
          Please choose an earlier date.
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Peer Reviewed by</mat-label>
        <mat-select formControlName="peerReviewMember" placeholder="Team Member">
          <mat-option *ngFor="let staff of staff" value={{staff.name}}>{{staff.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="50" fxFlex.lt-md="100">
        <textarea matInput placeholder="Reviewer Notes Log" formControlName="reviewNotes"></textarea>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Requirements Met Date</mat-label>
        <input placeholder="mm/dd/yyyy" formControlName="requirementsMetDate" matInput [matDatepicker]="requirementsMetDateTestinglab"
          [max]="today" />
        <mat-datepicker-toggle matSuffix [for]="requirementsMetDateTestinglab"></mat-datepicker-toggle>
        <mat-datepicker #requirementsMetDateTestinglab>
          <mat-datepicker-actions>
            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
            <button mat-button matDatepickerCancel>Cancel</button>
          </mat-datepicker-actions>
        </mat-datepicker>
        <mat-error *ngIf="checklistForm.get('requirementsMetDate').value > today">
          Please choose an earlier date.
        </mat-error>
      </mat-form-field>
      </div>

      <div fxLayout="column" fxLayoutGap="12px" [hidden]="entityType != sharedService.serviceCenter" class="text-wrap">
        <mat-form-field>
          <input matInput placeholder="Operational Name" formControlName="licenseName">
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="License Id" formControlName="licenseId">
        </mat-form-field>

        <mat-form-field>
          <mat-label [ngStyle]="{'color': checklistForm.get('teamMember').hasError('required')? 'red': 'black'}">Reviewing Team Member</mat-label>
          <mat-select formControlName="teamMember" placeholder="Team Member">
            <mat-option *ngFor="let staff of staff" value={{staff.name}}>{{staff.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label [ngStyle]="{'color': checklistForm.get('reviewDate').hasError('required')? 'red': 'black'}">Review Date</mat-label>
          <input placeholder="mm/dd/yyyy" formControlName="reviewDate" matInput [matDatepicker]="reviewDateServiceCenter"
            [max]="today" />
          <mat-datepicker-toggle matSuffix [for]="reviewDateServiceCenter"></mat-datepicker-toggle>
          <mat-datepicker #reviewDateServiceCenter>
            <mat-datepicker-actions>
              <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
              <button mat-button matDatepickerCancel>Cancel</button>
            </mat-datepicker-actions>
          </mat-datepicker>
          <mat-error *ngIf="checklistForm.get('reviewDate').value > today">
            Please choose an earlier date.
          </mat-error>
        </mat-form-field>

        <mat-checkbox formControlName="applicantInfoComplete">
          <span class="text-wrap" *ngIf="!checklistForm.get('applicantInfoComplete').value" style="color:red">Service Center Applicant Information Complete<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('applicantInfoComplete').value">Service Center Applicant Information Complete</span>
        </mat-checkbox>
        <span class="text-wrap" style="padding-left:1em">
          • When reviewing an application, please consider the following: Review the name(s),
          contact information, and demographics to ensure all information is complete and seems
          reasonable (examples: phone number 555-555-5555 is not a real phone number or entering “9dk3nfl”
          in a field to get through the system).
        </span>

        <mat-checkbox formControlName="attestationsAndSignedComplete">
          <span class="text-wrap" *ngIf="!checklistForm.get('attestationsAndSignedComplete').value" style="color:red">All Attestations Complete and Application Signed<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('attestationsAndSignedComplete').value">All Attestations Complete and Application Signed</span>
        </mat-checkbox>

        <mat-checkbox formControlName="primaryContactIdentified">
          <span class="text-wrap" *ngIf="!checklistForm.get('primaryContactIdentified').value" style="color:red">Primary Contact Identified<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('primaryContactIdentified').value">Primary Contact Identified</span>
        </mat-checkbox>


        <span class="text-indigo-A700" color="blue"><em>Individual and Legal Entity Applicants</em></span>
        <mat-checkbox formControlName="individualEntityApplicantInfoComplete">
          <span class="text-wrap" *ngIf="!checklistForm.get('individualEntityApplicantInfoComplete').value" style="color:red">Individual and/or Entity Applicant Information Complete<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('individualEntityApplicantInfoComplete').value">Individual and/or Entity Applicant Information Complete</span>
        </mat-checkbox>

        <mat-checkbox formControlName="applicantInformationFormsReceived">
          <span class="text-wrap" *ngIf="!checklistForm.get('applicantInformationFormsReceived').value" style="color:red">Individual and Legal Entity Applicant Information Forms Received and Uploaded for Each Identified Applicant<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('applicantInformationFormsReceived').value">Individual and Legal Entity Applicant Information Forms Received and Uploaded for Each Identified Applicant</span>
        </mat-checkbox>

        <mat-checkbox formControlName="photoIdComplete">
          <span class="text-wrap" *ngIf="!checklistForm.get('photoIdComplete').value" [ngStyle]="{'color': checklistForm.get('photoIdComplete').hasError('required')? 'red': 'black'}">Valid and Unexpired Photo Identification Received and Uploaded in TLC for Each Applicant<strong *ngIf="!renewal">*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('photoIdComplete').value">Valid and Unexpired Photo Identification Received and Uploaded in TLC for Each Applicant</span>
          <span class="text-wrap" *ngIf="renewal"><i> (A new document is not required for a renewal, please ensure you have the document from the original application)</i></span>
        </mat-checkbox>
        <div fxLayout="column" class="text-wrap">
          <span style="padding-left:1em">The following forms of Photo Identification are accepted:</span>
          <span style="padding-left:1em">• Passport;</span>
          <span style="padding-left:1em">• Driver license, whether issued by the State of Oregon or by another state of the United States;</span>
          <span style="padding-left:1em">• Identification card issued under ORS 807.400;</span>
          <span style="padding-left:1em">• United States military identification card</span>
          <span style="padding-left:1em">• An identification card issued by a federally recognized Indian tribe with photo, name, and date of birth; or</span>
          <span style="padding-left:1em">• Any other identification card issued by a state or territory that bears a picture of the person, the name of the person, the person's date of birth, and a physical description of the person.</span>
        </div>
        <button mat-flat-button color="primary" *ngIf="checklist.id != 0" (click)="openApplicants()">
          View Applicants
        </button>


        <span class="text-indigo-A700" color="blue"><em>Health Care Facility Subject to ORS Chapter 441</em></span>
        <mat-checkbox formControlName="notSubjectToHealthCareFacility" [ngStyle]="{'color': checklistForm.get('notSubjectToHealthCareFacility').hasError('required')? 'red': 'black'}">
          <span class="text-wrap" *ngIf="!checklistForm.get('notSubjectToHealthCareFacility').value"> Confirmed Not Subject to 441 Through the Health Care Regulation and Quality Improvement (HCRQI)<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('notSubjectToHealthCareFacility').value"> Confirmed Not Subject to 441 Through the Health Care Regulation and Quality Improvement (HCRQI)</span>
        </mat-checkbox>

        <span class="text-wrap" style="padding-left:1em">Email: Matt Gilman, HCRQI Compliance and Regulatory Manager: matt.s.gilman@oha.oregon.gov</span>
        <mat-form-field>
          <mat-label [ngStyle]="{'color': checklistForm.get('dateNotSubjectToHealthCareFacility').value == ''? 'red': 'black'}">Date Confirmed</mat-label>
          <input placeholder="mm/dd/yyyy" formControlName="dateNotSubjectToHealthCareFacility" matInput [matDatepicker]="dateNotSubjectToHealthCareFacility"
            [max]="today" />
          <mat-datepicker-toggle matSuffix [for]="dateNotSubjectToHealthCareFacility"></mat-datepicker-toggle>
          <mat-datepicker #dateNotSubjectToHealthCareFacility>
            <mat-datepicker-actions>
              <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
              <button mat-button matDatepickerCancel>Cancel</button>
            </mat-datepicker-actions>
          </mat-datepicker>
          <mat-error *ngIf="checklistForm.get('dateNotSubjectToHealthCareFacility').value > today">
            Please choose an earlier date.
          </mat-error>
        </mat-form-field>


        <span class="text-indigo-A700" color="blue"><em>School Proximity</em></span>
        <mat-checkbox formControlName="completedSchoolProximityAttestations">
          <span class="text-wrap" *ngIf="!checklistForm.get('completedSchoolProximityAttestations').value" [ngStyle]="{'color': checklistForm.get('completedSchoolProximityAttestations').hasError('required')? 'red': 'black'}">Completed School Proximity Attestations<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('completedSchoolProximityAttestations').value">Completed School Proximity Attestations</span>
        </mat-checkbox>

        <mat-checkbox formControlName="meetsSchoolProximityRequirements">
          <span class="text-wrap" *ngIf="!checklistForm.get('meetsSchoolProximityRequirements').value" [ngStyle]="{'color': checklistForm.get('meetsSchoolProximityRequirements').hasError('required')? 'red': 'black'}">Indicated the Service Center Meets School Proximity Requirements <strong>OR</strong> Qualifies for an Exception<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('meetsSchoolProximityRequirements').value">Indicated the Service Center Meets School Proximity Requirements <strong>OR</strong> Qualifies for an Exception</span>
        </mat-checkbox>

        <mat-checkbox formControlName="gisLiasonConfirmed">
          <span class="text-wrap" *ngIf="!checklistForm.get('gisLiasonConfirmed').value" [ngStyle]="{'color': checklistForm.get('gisLiasonConfirmed').hasError('required')? 'red': 'black'}">GIS Liaison confirmed school proximity<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('gisLiasonConfirmed').value">GIS Liaison confirmed school proximity</span>
        </mat-checkbox>


        <span class="text-indigo-A700" color="blue"><em>Practicum Site</em></span>
        <mat-checkbox formControlName="completedPracticumSiteAttestations">
          <span class="text-wrap" *ngIf="!checklistForm.get('completedPracticumSiteAttestations').value" [ngStyle]="{'color': checklistForm.get('completedPracticumSiteAttestations').hasError('required')? 'red': 'black'}">Completed Practicum Site Attestations<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('completedPracticumSiteAttestations').value">Completed Practicum Site Attestations</span>
        </mat-checkbox>

        <mat-checkbox formControlName="indicatedServesAsPracticumSite">
          <span class="text-wrap" [ngStyle]="{'color': validateServesAsPracticum()? 'red': 'black'}">Indicated Serves as a Practicum Site and Provided Training Program Information</span>
          <mat-error *ngIf="validateServesAsPracticum()">
            This field is required
          </mat-error>
        </mat-checkbox>

        <mat-checkbox formControlName="indicatedServesAsPracticumSiteNA">
          <span class="text-wrap" [ngStyle]="{'color': validateServesAsPracticum()? 'red': 'black'}">Indicated Serves as a Practicum Site and Provided Training Program Information NA</span>
          <mat-error *ngIf="validateServesAsPracticum()">
            This field is required
          </mat-error>
        </mat-checkbox>


        <span class="text-indigo-A700" color="blue"><em>Additional Required Documents</em></span>
        <mat-checkbox formControlName="approvedLandUseCompatibility" *ngIf="!renewal" [ngStyle]="{'color': checklistForm.get('approvedLandUseCompatibility').hasError('required')? 'red': 'black'}">
          <span class="text-wrap" *ngIf="!checklistForm.get('approvedLandUseCompatibility').value">Approved Land Use Compatibility Statement Plan Meets Requirements<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('approvedLandUseCompatibility').value">Approved Land Use Compatibility Statement Plan Meets Requirements</span>
        </mat-checkbox>
        <mat-checkbox formControlName="approvedLandUseCompatibility" *ngIf="renewal">
          <span class="text-wrap">Approved Land Use Compatibility Statement Plan Meets Requirements <i>(A new document is not required for a renewal, please ensure you have the document from the original application)</i></span>
        </mat-checkbox>
        <mat-checkbox formControlName="premisesPlanMeetsRequirements">
          <span class="text-wrap" *ngIf="!checklistForm.get('premisesPlanMeetsRequirements').value" [ngStyle]="{'color': checklistForm.get('premisesPlanMeetsRequirements').hasError('required')? 'red': 'black'}">Premises Plan<strong>*</strong></span>
          <span class="text-wrap" *ngIf="checklistForm.get('premisesPlanMeetsRequirements').value">Premises Plan</span>
          <span class="text-wrap" *ngIf="renewal"><i> (A new document is not required for a renewal, please ensure you have the document from the original application)</i></span>
        </mat-checkbox>
      <mat-checkbox formControlName="socialEquityPlanMeetsRequirements">
        <span class="text-wrap" *ngIf="!checklistForm.get('socialEquityPlanMeetsRequirements').value" [ngStyle]="{'color': checklistForm.get('socialEquityPlanMeetsRequirements').hasError('required')? 'red': 'black'}">Social Equity Plan Meets Requirements<strong>*</strong></span>
        <span class="text-wrap" *ngIf="checklistForm.get('socialEquityPlanMeetsRequirements').value">Social Equity Plan Meets Requirements</span>
        <span class="text-wrap" *ngIf="renewal"><i> (A new document is not required for a renewal, please ensure you have the document from the original application)</i></span>
      </mat-checkbox>
      <div fxLayout="column" class="text-wrap">
        <span style="padding-left:1em">
          A Social Equity Plan must:
        </span>
        <span style="padding-left:1em">
          ✓ An Application of diversity, equity, justice and inclusion principles to the licensee's internal practices and policies.
        </span>
        <span style="padding-left:1em">
          ✓ Objective performance measures that the licensee will use to evaluate their social equity plan.
        </span>
      </div>
      <mat-checkbox formControlName="socialEquityPlanEvaluationMeetsRequirements" *ngIf="renewal">
        <span class="text-wrap" *ngIf="!checklistForm.get('socialEquityPlanEvaluationMeetsRequirements').value" [ngStyle]="{'color': checklistForm.get('socialEquityPlanEvaluationMeetsRequirements').hasError('required')? 'red': 'black'}">Social Equity Plan Evaluation Meets Requirements<strong>*</strong></span>
        <span class="text-wrap" *ngIf="checklistForm.get('socialEquityPlanEvaluationMeetsRequirements').value">Social Equity Plan Evaluation Meets Requirements</span>
      </mat-checkbox>

      <div fxLayout="column" class="text-wrap" *ngIf="renewal">
        <span style="padding-left:1em">
          A Social Equity Plan must:
        </span>
        <span style="padding-left:1em">
          ✓ Objective performance measures from the licensee are evaluated.
        </span>
      </div>
      <mat-checkbox formControlName="reducedLicensingFeeDocumentation">
        <span class="text-wrap" [ngStyle]="{'color': validateReducedFee()? 'red': 'black'}">Reduced Licensing Fee Documentation</span>
        <mat-error *ngIf="validateReducedFee()">
          This field is required
        </mat-error>
      </mat-checkbox>
      <mat-checkbox formControlName="reducedLicensingFeeDocumentationNA">
        <span class="text-wrap" [ngStyle]="{'color': validateReducedFee()? 'red': 'black'}">Reduced Licensing Fee Documentation NA</span>
        <mat-error *ngIf="validateReducedFee()">
          This field is required
        </mat-error>
      </mat-checkbox>
      <span class="text-wrap">If requesting a reduced fee, which qualifying documentation was provided:</span>
      <mat-checkbox formControlName="nonProfitEntity">
        <span class="text-wrap" [ngStyle]="{'color': validateReducedFeeDocumentation()? 'red': 'black'}">Proposed licensed service center is a non-profit entity and supporting documentation of the manufacturer's non-profit status was provided</span>
        <mat-error *ngIf="validateReducedFeeDocumentation()">
          This field is required
        </mat-error>
      </mat-checkbox>
      <span><strong>OR</strong></span>
      <span class="text-wrap">Proposed licensed service center is an individual applicant and one of the following required supporting documents was provided:</span>
      <mat-checkbox formControlName="socialSecurityIncomeBenefitCompleted">
        <span class="text-wrap" [ngStyle]="{'color': validateReducedFeeDocumentation()? 'red': 'black'}">A copy of a current monthly Supplemental Security Income benefit statement showing dates of coverage</span>
        <mat-error *ngIf="validateReducedFeeDocumentation()">
          This field is required
        </mat-error>
      </mat-checkbox>
      <mat-checkbox formControlName="applicantsCurrentEligibilityStatement">
        <span class="text-wrap" [ngStyle]="{'color': validateReducedFeeDocumentation()? 'red': 'black'}">A copy of the applicant's current eligibility statement or card if enrolled in Oregon Health Plan</span>
        <mat-error *ngIf="validateReducedFeeDocumentation()">
          This field is required
        </mat-error>
      </mat-checkbox>
      <mat-checkbox formControlName="foodStampBenefits">
        <span class="text-wrap" [ngStyle]="{'color': validateReducedFeeDocumentation()? 'red': 'black'}">A copy of current proof of food stamp benefits from the Oregon Supplemental Nutrition Assistance Program</span>
        <mat-error *ngIf="validateReducedFeeDocumentation()">
          This field is required
        </mat-error>
      </mat-checkbox>
      <mat-checkbox formControlName="servedInArmedForces">
        <span class="text-wrap" [ngStyle]="{'color': validateReducedFeeDocumentation()? 'red': 'black'}">A copy of proof of having served in the Armed Forces, such as but not limited to, submitting a Veteran's Administration form DD-214</span>
        <mat-error *ngIf="validateReducedFeeDocumentation()">
          This field is required
        </mat-error>
      </mat-checkbox>

      <span fxFlex></span>
      <span fxFlex></span>
      <mat-checkbox formControlName="peerReviewComplete">
        <span class="text-wrap" *ngIf="!checklistForm.get('peerReviewComplete').value" [ngStyle]="{'color': checklistForm.get('peerReviewComplete').hasError('required')? 'red': 'black'}">Peer Review Complete<strong>*</strong></span>
        <span class="text-wrap" *ngIf="checklistForm.get('peerReviewComplete').value">Peer Review Complete</span>
      </mat-checkbox>

      <mat-form-field>
        <mat-label [ngStyle]="{'color': !checklistForm.get('peerReviewDate').value? 'red': 'black'}">Peer Review Completed On</mat-label>
        <input placeholder="mm/dd/yyyy" formControlName="peerReviewDate" matInput [matDatepicker]="peerReviewDateServiceCenter"
          [max]="today" />
        <mat-datepicker-toggle matSuffix [for]="peerReviewDateServiceCenter"></mat-datepicker-toggle>
        <mat-datepicker #peerReviewDateServiceCenter>
          <mat-datepicker-actions>
            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
            <button mat-button matDatepickerCancel>Cancel</button>
          </mat-datepicker-actions>
        </mat-datepicker>
        <mat-error *ngIf="checklistForm.get('peerReviewDate').value > today">
          Please choose an earlier date.
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Peer Reviewed by</mat-label>
        <mat-select formControlName="peerReviewMember" placeholder="Team Member">
          <mat-option *ngFor="let staff of staff" value={{staff.name}}>{{staff.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="50" fxFlex.lt-md="100">
        <textarea matInput placeholder="Reviewer Notes Log" formControlName="reviewNotes"></textarea>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Requirements Met Date</mat-label>
        <input placeholder="mm/dd/yyyy" formControlName="requirementsMetDate" matInput [matDatepicker]="requirementsMetDateServiceCenter"
          [max]="today" />
        <mat-datepicker-toggle matSuffix [for]="requirementsMetDateServiceCenter"></mat-datepicker-toggle>
        <mat-datepicker #requirementsMetDateServiceCenter>
          <mat-datepicker-actions>
            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
            <button mat-button matDatepickerCancel>Cancel</button>
          </mat-datepicker-actions>
        </mat-datepicker>
        <mat-error *ngIf="checklistForm.get('requirementsMetDate').value > today">
          Please choose an earlier date.
        </mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="column" fxLayoutGap="12px" [hidden]="entityType != sharedService.facilitator" class="text-wrap" >
      <mat-form-field>
        <input matInput placeholder="License Name" formControlName="licenseName">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="License Id" formControlName="licenseId">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Reviewing Team Member</mat-label>
        <mat-select formControlName="teamMember" placeholder="Team Member *">
            <mat-option *ngFor="let staff of staff" value={{staff.name}}>{{staff.name}}
            </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Review Date</mat-label>
        <input placeholder="mm/dd/yyyy" formControlName="reviewDate" matInput [matDatepicker]="reviewDateFacilitator"
          [max]="today" />
        <mat-datepicker-toggle matSuffix [for]="reviewDateFacilitator"></mat-datepicker-toggle>
        <mat-datepicker #reviewDateFacilitator>
          <mat-datepicker-actions>
            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
            <button mat-button matDatepickerCancel>Cancel</button>
          </mat-datepicker-actions>
        </mat-datepicker>
        <mat-error *ngIf="checklistForm.get('reviewDate').value > today">
          Please choose an earlier date.
        </mat-error>
      </mat-form-field>

      <mat-checkbox formControlName="workerPermitSubmitted">
        <span class="text-wrap" *ngIf="!checklistForm.get('workerPermitSubmitted').value" [ngStyle]="{'color': checklistForm.get('workerPermitSubmitted').hasError('required')? 'red': 'black'}">Verified Worker Permit Application Submitted<strong>*</strong></span>
        <span class="text-wrap" *ngIf="checklistForm.get('workerPermitSubmitted').value">Verified Worker Permit Application Submitted</span>
      </mat-checkbox>

      <mat-checkbox formControlName="applicantInfoComplete">
        <span class="text-wrap" *ngIf="!checklistForm.get('applicantInfoComplete').value" [ngStyle]="{'color': checklistForm.get('applicantInfoComplete').hasError('required')? 'red': 'black'}">Verified Demographic and Contact Info Complete<strong>*</strong></span>
        <span class="text-wrap" *ngIf="checklistForm.get('applicantInfoComplete').value">Verified Demographic and Contact Info Complete</span>
      </mat-checkbox>
      <span class="text-wrap" style="padding-left:1em">
        • When reviewing an application, please consider the following: Review the name(s),
        contact information, and demographics to ensure all information is complete and seems
        reasonable (examples: phone number 555-555-5555 is not a real phone number or entering “9dk3nfl”
        in a field to get through the system).
      </span>

      <mat-checkbox formControlName="attestationsAndSignedComplete">
        <span class="text-wrap" *ngIf="!checklistForm.get('attestationsAndSignedComplete').value" [ngStyle]="{'color': checklistForm.get('attestationsAndSignedComplete').hasError('required')? 'red': 'black'}">Verified Attestations Complete and Application Signed<strong>*</strong></span>
        <span class="text-wrap" *ngIf="checklistForm.get('attestationsAndSignedComplete').value">Verified Attestations Complete and Application Signed</span>
      </mat-checkbox>

      <mat-checkbox formControlName="photoIdComplete">
        <span class="text-wrap" *ngIf="!checklistForm.get('photoIdComplete').value" [ngStyle]="{'color': checklistForm.get('photoIdComplete').hasError('required')? 'red': 'black'}">Verified Valid and Unexpired Photo Identification Received and Uploaded in TLC<strong *ngIf="!renewal">*</strong></span>
        <span class="text-wrap" *ngIf="checklistForm.get('photoIdComplete').value">Verified Valid and Unexpired Photo Identification Received and Uploaded in TLC</span>
        <span class="text-wrap" *ngIf="renewal"><i> (A new document is not required for a renewal, please ensure you have the document from the original application)</i></span>
      </mat-checkbox>

      <mat-form-field>
        <mat-label>Identification Type</mat-label>
        <mat-select formControlName="photoIdType" placeholder="Identification Type">
          <mat-option style="min-height: 48px; line-height: normal; height: auto; padding: 10px 20px;" class="text-wrap" *ngFor="let type of photoIdTypes" value={{type}}>{{type}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-checkbox formControlName="idNameMatchCompleted">
        <span class="text-wrap" *ngIf="!checklistForm.get('idNameMatchCompleted').value" [ngStyle]="{'color': checklistForm.get('idNameMatchCompleted').hasError('required')? 'red': 'black'}">Verified Name and DOB on ID Match Information Provided on the Application<strong *ngIf="!renewal">*</strong></span>
        <span class="text-wrap" *ngIf="checklistForm.get('idNameMatchCompleted').value">Verified Name and DOB on ID Match Information Provided on the Application</span>
        <span class="text-wrap" *ngIf="renewal"><i> (A new document is not required for a renewal, please ensure you have the document from the original application)</i></span>
      </mat-checkbox>

      <mat-checkbox formControlName="legalAgeComplete">
        <span *ngIf="!checklistForm.get('legalAgeComplete').value" [ngStyle]="{'color': checklistForm.get('legalAgeComplete').hasError('required')? 'red': 'black'}">Verified Age 21+<strong>*</strong></span>
        <span *ngIf="checklistForm.get('legalAgeComplete').value">Verified Age 21+</span>
        <span class="text-wrap" *ngIf="renewal"><i> (A new document is not required for a renewal, please ensure you have the document from the original application)</i></span>
      </mat-checkbox>

      <mat-checkbox formControlName="backgroundCheckPassed">
        <span *ngIf="!checklistForm.get('backgroundCheckPassed').value" [ngStyle]="{'color': checklistForm.get('backgroundCheckPassed').hasError('required')? 'red': 'black'}">Background Check Passed<strong>*</strong></span>
        <span *ngIf="checklistForm.get('backgroundCheckPassed').value">Background Check Passed</span>
      </mat-checkbox>

      <mat-form-field>
        <mat-label [ngStyle]="{'color': checklistForm.get('backgroundCheckDate').value == ''? 'red': 'black'}">Background Check Date</mat-label>
        <input placeholder="mm/dd/yyyy" formControlName="backgroundCheckDate" matInput [matDatepicker]="backgroundCheckDateFacilitator"
          [max]="today" />
        <mat-datepicker-toggle matSuffix [for]="backgroundCheckDateFacilitator"></mat-datepicker-toggle>
        <mat-datepicker #backgroundCheckDateFacilitator>
          <mat-datepicker-actions>
            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
            <button mat-button matDatepickerCancel>Cancel</button>
          </mat-datepicker-actions>
        </mat-datepicker>
        <mat-error *ngIf="checklistForm.get('backgroundCheckDate').value > today">
          Please choose an earlier date.
        </mat-error>
      </mat-form-field>

      <mat-checkbox formControlName="passedExam">
        <span class="text-wrap" *ngIf="!checklistForm.get('passedExam').value" [ngStyle]="{'color': checklistForm.get('passedExam').hasError('required')? 'red': 'black'}">Regulations Exam passed (75% or better)<strong>*</strong></span>
        <span class="text-wrap" *ngIf="checklistForm.get('passedExam').value">Regulations Exam passed (75% or better)</span>
        <span class="text-wrap" *ngIf="renewal"><i> (A new Exam is not required for a renewal, please ensure you have the exam results from the original application)</i></span>
      </mat-checkbox>

      <mat-checkbox formControlName="trainingProgramCertificate">
        <span class="text-wrap" *ngIf="!checklistForm.get('trainingProgramCertificate').value" [ngStyle]="{'color': checklistForm.get('trainingProgramCertificate').hasError('required')? 'red': 'black'}">Training Program Completion Certificate from Training Program with Approved Curriculum.<strong>*</strong></span>
        <span class="text-wrap" *ngIf="checklistForm.get('trainingProgramCertificate').value">Training Program Completion Certificate from Training Program with Approved Curriculum.</span>
        <span class="text-wrap" *ngIf="renewal"><i> (A new document is not required for a renewal, please ensure you have the document from the original application)</i></span>
      </mat-checkbox>
      <span>Ensure:</span>
      <div fxLayout="column">
        <span class="text-wrap" style="padding-left:1em">
          • The correct training program name is on the certificate
        </span>
        <span class="text-wrap" style="padding-left:1em">
          • The certificate is signed by a lead educator
        </span>
        <span class="text-wrap" style="padding-left:1em">
          • The certificate must have a completion date at the time the program has OPS approved curriculum
        </span>
      </div>

      <mat-checkbox formControlName="socialEquityPlanMeetsRequirements">
        <span class="text-wrap" *ngIf="!checklistForm.get('socialEquityPlanMeetsRequirements').value" [ngStyle]="{'color': checklistForm.get('socialEquityPlanMeetsRequirements').hasError('required')? 'red': 'black'}">Social Equity Plan Meets Requirements<strong>*</strong></span>
        <span class="text-wrap" *ngIf="checklistForm.get('socialEquityPlanMeetsRequirements').value">Social Equity Plan Meets Requirements</span>
        <span class="text-wrap" *ngIf="renewal"><i> (A new document is not required for a renewal, please ensure you have the document from the original application)</i></span>
      </mat-checkbox>

      <div fxLayout="column" class="text-wrap">
        <span style="padding-left:1em">
          A Social Equity Plan must:
        </span>
        <span style="padding-left:1em">
          ✓ An Application of diversity, equity, justice and inclusion principles to the licensee's internal practices and policies.
        </span>
        <span style="padding-left:1em">
          ✓ Objective performance measures that the licensee will use to evaluate their social equity plan.
        </span>
        <span>If a facilitator is an employee of a licensed service center, the social equity plan requirements can be fulfilled by submitting a statement that references the service center's
          social equity plan. If a facilitator is an employee of more than one service center, the statement must identify each service center that employs the facilitator. If a facilitator contracts
          with a service center, they must submit their own social equity plan.</span>
      </div>

      <mat-checkbox formControlName="socialEquityPlanEvaluationMeetsRequirements" *ngIf="renewal">
        <span class="text-wrap" *ngIf="!checklistForm.get('socialEquityPlanEvaluationMeetsRequirements').value" [ngStyle]="{'color': checklistForm.get('socialEquityPlanEvaluationMeetsRequirements').hasError('required')? 'red': 'black'}">Social Equity Plan Evaluation Meets Requirements<strong>*</strong></span>
        <span class="text-wrap" *ngIf="checklistForm.get('socialEquityPlanEvaluationMeetsRequirements').value">Social Equity Plan Evaluation Meets Requirements</span>
      </mat-checkbox>

      <div fxLayout="column" class="text-wrap" *ngIf="renewal">
        <span style="padding-left:1em">
          A Social Equity Plan must:
        </span>
        <span style="padding-left:1em">
          ✓ Objective performance measures from the licensee are evaluated.
        </span>
      </div>

      <mat-checkbox formControlName="reducedLicensingFeeDocumentation">
        <span class="text-wrap" [ngStyle]="{'color': validateReducedFee()? 'red': 'black'}">Reduced Licensing Fee Documentation</span>
          <mat-error *ngIf="validateReducedFee()">
            This field is required
          </mat-error>
      </mat-checkbox>

      <mat-checkbox formControlName="reducedLicensingFeeDocumentationNA">
        <span class="text-wrap" [ngStyle]="{'color': validateReducedFee()? 'red': 'black'}">Reduced Licensing Fee Documentation NA</span>
        <mat-error *ngIf="validateReducedFee()">
          This field is required
        </mat-error>
      </mat-checkbox>

      <span class="text-wrap">If requesting a reduced fee, which qualifying documentation was provided:</span>
      <mat-checkbox formControlName="socialSecurityIncomeBenefitCompleted">
        <span class="text-wrap" [ngStyle]="{'color': validateReducedFeeDocumentation()? 'red': 'black'}">A copy of a current monthly Supplemental Security Income benefit statement showing dates of coverage</span>
        <mat-error *ngIf="validateReducedFeeDocumentation()">
          This field is required
        </mat-error>
      </mat-checkbox>
      <mat-checkbox formControlName="applicantsCurrentEligibilityStatement">
        <span class="text-wrap" [ngStyle]="{'color': validateReducedFeeDocumentation()? 'red': 'black'}">A copy of the applicant's current eligibility statement or card if enrolled in Oregon Health Plan</span>
        <mat-error *ngIf="validateReducedFeeDocumentation()">
          This field is required
        </mat-error>
      </mat-checkbox>
      <mat-checkbox formControlName="foodStampBenefits">
        <span class="text-wrap" [ngStyle]="{'color': validateReducedFeeDocumentation()? 'red': 'black'}">A copy of current proof of food stamp benefits from the Oregon Supplemental Nutrition Assistance Program</span>
        <mat-error *ngIf="validateReducedFeeDocumentation()">
          This field is required
        </mat-error>
      </mat-checkbox>
      <mat-checkbox formControlName="servedInArmedForces">
        <span class="text-wrap" [ngStyle]="{'color': validateReducedFeeDocumentation()? 'red': 'black'}">A copy of proof of having served in the Armed Forces, such as but not limited to, submitting a Veteran's Administration form DD-214</span>
        <mat-error *ngIf="validateReducedFeeDocumentation()">
          This field is required
        </mat-error>
      </mat-checkbox>

      <span fxFlex></span>
      <span fxFlex></span>
      <mat-checkbox formControlName="peerReviewComplete">
        <span class="text-wrap" *ngIf="!checklistForm.get('peerReviewComplete').value" [ngStyle]="{'color': checklistForm.get('peerReviewComplete').hasError('required')? 'red': 'black'}">Peer Review Complete<strong>*</strong></span>
        <span class="text-wrap" *ngIf="checklistForm.get('peerReviewComplete').value">Peer Review Complete</span>
      </mat-checkbox>

      <mat-form-field>
        <mat-label [ngStyle]="{'color': !checklistForm.get('peerReviewDate').value? 'red': 'black'}">Peer Review Completed On</mat-label>
        <input placeholder="mm/dd/yyyy" formControlName="peerReviewDate" matInput [matDatepicker]="peerReviewDateFacilitator"
          [max]="today" />
        <mat-datepicker-toggle matSuffix [for]="peerReviewDateFacilitator"></mat-datepicker-toggle>
        <mat-datepicker #peerReviewDateFacilitator>
          <mat-datepicker-actions>
            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
            <button mat-button matDatepickerCancel>Cancel</button>
          </mat-datepicker-actions>
        </mat-datepicker>
        <mat-error *ngIf="checklistForm.get('peerReviewDate').value > today">
          Please choose an earlier date.
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Peer Reviewed by</mat-label>
        <mat-select formControlName="peerReviewMember" placeholder="Team Member">
          <mat-option *ngFor="let staff of staff" value={{staff.name}}>{{staff.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    <mat-form-field fxFlex="50" fxFlex.lt-md="100">
      <textarea matInput placeholder="Reviewer Notes Log" formControlName="reviewNotes"></textarea>
    </mat-form-field>

    <mat-form-field>
      <mat-label [ngStyle]="{'color': validateSubmittingChecklist()? 'red': 'black'}">Requirements Met Date</mat-label>
      <input placeholder="mm/dd/yyyy" formControlName="requirementsMetDate" matInput [matDatepicker]="requirementsMetDateFacilitator"
        [max]="today" />
      <mat-datepicker-toggle matSuffix [for]="requirementsMetDateFacilitator"></mat-datepicker-toggle>
      <mat-datepicker #requirementsMetDateFacilitator>
        <mat-datepicker-actions>
          <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
          <button mat-button matDatepickerCancel>Cancel</button>
        </mat-datepicker-actions>
      </mat-datepicker>
      <mat-error *ngIf="checklistForm.get('requirementsMetDate').value > today">
        Please choose an earlier date.
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="50" fxFlex.lt-md="100">
      <textarea matInput placeholder="Closure Explanation" formControlName="submitForClosingNotes"></textarea>
    </mat-form-field>

    <mat-form-field fxFlex="50" fxFlex.lt-md="100">
      <textarea matInput placeholder="Denial Explanation" formControlName="submitForDenialNotes"></textarea>
    </mat-form-field>
  </div>

  <div fxLayout="column" fxLayoutGap="12px" [hidden]="entityType != sharedService.trainingProgram" class="text-wrap">
    <mat-form-field>
      <input matInput placeholder="License Name" formControlName="licenseName">
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="License Id" formControlName="licenseId">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Reviewing Team Member</mat-label>
      <mat-select formControlName="teamMember" placeholder="Team Member">
        <mat-option *ngFor="let staff of staff" value={{staff.name}}>{{staff.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Review Date</mat-label>
      <input placeholder="mm/dd/yyyy" formControlName="reviewDate" matInput [matDatepicker]="reviewDateTrainingProgram"
        [max]="today" />
      <mat-datepicker-toggle matSuffix [for]="reviewDateTrainingProgram"></mat-datepicker-toggle>
      <mat-datepicker #reviewDateTrainingProgram>
        <mat-datepicker-actions>
          <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
          <button mat-button matDatepickerCancel>Cancel</button>
        </mat-datepicker-actions>
      </mat-datepicker>
      <mat-error *ngIf="checklistForm.get('reviewDate').value > today">
        Please choose an earlier date.
      </mat-error>
    </mat-form-field>


    <mat-checkbox formControlName="applicantInfoComplete">
      <span class="text-wrap" *ngIf="!checklistForm.get('applicantInfoComplete').value" [ngStyle]="{'color': checklistForm.get('applicantInfoComplete').hasError('required')? 'red': 'black'}">Verify training program name and contact information complete<strong>*</strong></span>
      <span class="text-wrap" *ngIf="checklistForm.get('applicantInfoComplete').value">Verify training program name and contact information complete</span>
    </mat-checkbox>
    <span class="text-wrap" style="padding-left:1em">
      • When reviewing an application, please consider the following: Review the name(s),
      contact information, and demographics to ensure all information is complete and seems
      reasonable (examples: phone number 555-555-5555 is not a real phone number or entering “9dk3nfl”
      in a field to get through the system).
    </span>

    <mat-checkbox formControlName="responsiblePartyInformationComplete">
      <span class="text-wrap" *ngIf="!checklistForm.get('responsiblePartyInformationComplete').value" [ngStyle]="{'color': checklistForm.get('responsiblePartyInformationComplete').hasError('required')? 'red': 'black'}">Responsible party and contact information complete<strong>*</strong></span>
      <span class="text-wrap" *ngIf="checklistForm.get('responsiblePartyInformationComplete').value">Responsible party and contact information complete</span>
    </mat-checkbox>

    <mat-checkbox formControlName="registeredAgentInformationComplete">
      <span class="text-wrap" *ngIf="!checklistForm.get('registeredAgentInformationComplete').value" [ngStyle]="{'color': checklistForm.get('registeredAgentInformationComplete').hasError('required')? 'red': 'black'}">Registered agent for the business and contact information complete<strong>*</strong></span>
      <span class="text-wrap" *ngIf="checklistForm.get('registeredAgentInformationComplete').value">Registered agent for the business and contact information complete</span>
    </mat-checkbox>

    <mat-form-field fxFlex="50" fxFlex.lt-md="100">
      <textarea matInput placeholder="State/territory where business is registered" formControlName="registeredState"></textarea>
    </mat-form-field>

    <mat-checkbox formControlName="directorInformationComplete">
      <span class="text-wrap" *ngIf="!checklistForm.get('directorInformationComplete').value" [ngStyle]="{'color': checklistForm.get('directorInformationComplete').hasError('required')? 'red': 'black'}">Training program director and contact information complete<strong>*</strong></span>
      <span class="text-wrap" *ngIf="checklistForm.get('directorInformationComplete').value">Training program director and contact information complete</span>
    </mat-checkbox>

    <mat-checkbox formControlName="firstLeadInformationComplete">
      <span class="text-wrap" *ngIf="!checklistForm.get('firstLeadInformationComplete').value" [ngStyle]="{'color': checklistForm.get('firstLeadInformationComplete').hasError('required')? 'red': 'black'}">First lead instructor and contact information complete<strong>*</strong></span>
      <span class="text-wrap" *ngIf="checklistForm.get('firstLeadInformationComplete').value">First lead instructor and contact information complete</span>
    </mat-checkbox>

    <mat-checkbox formControlName="secondLeadInformationComplete">
      <span class="text-wrap" *ngIf="!checklistForm.get('secondLeadInformationComplete').value" [ngStyle]="{'color': checklistForm.get('secondLeadInformationComplete').hasError('required')? 'red': 'black'}">Second lead instructor and contact information complete<strong>*</strong></span>
      <span class="text-wrap" *ngIf="checklistForm.get('secondLeadInformationComplete').value">Second lead instructor and contact information complete</span>
    </mat-checkbox>

    <mat-form-field fxFlex="50" fxFlex.lt-md="100">
      <textarea matInput placeholder="Instructor Names" formControlName="instructorNames"></textarea>
    </mat-form-field>

    <mat-checkbox formControlName="responsiblePartyAttestationsComplete">
      <span class="text-wrap"*ngIf="!checklistForm.get('responsiblePartyAttestationsComplete').value" [ngStyle]="{'color': checklistForm.get('responsiblePartyAttestationsComplete').hasError('required')? 'red': 'black'}">Responsible party attested to all and signed<strong>*</strong></span>
      <span class="text-wrap"*ngIf="checklistForm.get('responsiblePartyAttestationsComplete').value">Responsible party attested to all and signed</span>
    </mat-checkbox>

    <button mat-flat-button color="primary" *ngIf="checklist.id != 0" (click)="openCurriculum()">
      View Curriculum Requirements
    </button>

    <span fxFlex></span>
    <span fxFlex></span>
    <mat-checkbox formControlName="peerReviewComplete">
      <span class="text-wrap" *ngIf="!checklistForm.get('peerReviewComplete').value" [ngStyle]="{'color': checklistForm.get('peerReviewComplete').hasError('required')? 'red': 'black'}">Peer Review Complete<strong>*</strong></span>
      <span class="text-wrap" *ngIf="checklistForm.get('peerReviewComplete').value">Peer Review Complete</span>
    </mat-checkbox>

    <mat-form-field>
      <mat-label [ngStyle]="{'color': !checklistForm.get('peerReviewDate').value? 'red': 'black'}">Peer Review Completed On</mat-label>
      <input placeholder="mm/dd/yyyy" formControlName="peerReviewDate" matInput [matDatepicker]="peerReviewDateProgram"
        [max]="today" />
      <mat-datepicker-toggle matSuffix [for]="peerReviewDateProgram"></mat-datepicker-toggle>
      <mat-datepicker #peerReviewDateProgram>
        <mat-datepicker-actions>
          <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
          <button mat-button matDatepickerCancel>Cancel</button>
        </mat-datepicker-actions>
      </mat-datepicker>
      <mat-error *ngIf="checklistForm.get('peerReviewDate').value > today">
        Please choose an earlier date.
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Peer Reviewed by</mat-label>
      <mat-select formControlName="peerReviewMember" placeholder="Team Member">
        <mat-option *ngFor="let staff of staff" value={{staff.name}}>{{staff.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="50" fxFlex.lt-md="100">
      <textarea matInput placeholder="Reviewer Notes Log" formControlName="reviewNotes"></textarea>
    </mat-form-field>

  <mat-form-field>
    <mat-label [ngStyle]="{'color': validateSubmittingChecklist()? 'red': 'black'}">Requirements Met Date</mat-label>
    <input placeholder="mm/dd/yyyy" formControlName="requirementsMetDate" matInput [matDatepicker]="requirementsMetDateTrainingProgram"
      [max]="today" />
    <mat-datepicker-toggle matSuffix [for]="requirementsMetDateTrainingProgram"></mat-datepicker-toggle>
    <mat-datepicker #requirementsMetDateTrainingProgram>
      <mat-datepicker-actions>
        <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
        <button mat-button matDatepickerCancel>Cancel</button>
      </mat-datepicker-actions>
    </mat-datepicker>
    <mat-error *ngIf="checklistForm.get('requirementsMetDate').value > today">
      Please choose an earlier date.
    </mat-error>
  </mat-form-field>

    <mat-form-field fxFlex="50" fxFlex.lt-md="100">
      <textarea matInput placeholder="Closure Explanation" formControlName="submitForClosingNotes"></textarea>
    </mat-form-field>

    <mat-form-field fxFlex="50" fxFlex.lt-md="100">
      <textarea matInput placeholder="Denial Explanation" formControlName="submitForDenialNotes"></textarea>
    </mat-form-field>

    </div>

    </form>
  </mat-card-content>
</mat-card>




