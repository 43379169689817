import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AuthService } from "app/auth/auth.service";
import { IClientData } from "app/models/303/clientData";
import { ISB303 } from "app/models/303/sb303";
import { IServiceCenterData } from "app/models/303/serviceCenterData";
import { SB303Service } from "app/services/303/303.service";
import { SharedService } from "app/services/core/shared.service";
import { ToastrService } from "ngx-toastr";
import { DialogViewClientDataComponent } from "./dialogs/dialog-view-client-data/dialog-view-client-data.component";
import { DialogViewServiceCenterDataComponent } from "./dialogs/dialog-view-service-center-data/dialog-view-service-center-data.component";
import { DialogDownloadServiceCenterDataComponent } from "./dialogs/dialog-download-service-center-data/dialog-download-service-center-data.component";
import { DialogDownloadClientDataComponent } from "./dialogs/dialog-download-client-data/dialog-download-client-data.component";
import { DialogUploadDeIdentifiedDataComponent } from "./dialogs/dialog-upload-deidentified-data/dialog-upload-deidentified-data.component";
import { ISB303DeIdentifiedData } from "app/models/303/sb303DeIdentifiedData";
import { IServiceCenterLicense } from "app/models/licenses/service-center-license";
import { DialogCreateNewReportComponent } from "./dialogs/dialog-create-new-report/dialog-create-new-report.component";

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-303-portal',
  templateUrl: './303.component.html',
  styleUrls: ['./303.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }]
})

export class SB303Component implements OnInit {

  public sb303Records: ISB303[] = [];
  public clientData: IClientData[] = [];
  public serviceCenterData: IServiceCenterData[] = [];
  public sb303DeIdentifiedData: ISB303DeIdentifiedData[] = [];
  public nonReportingLicenses: IServiceCenterLicense[] = [];

  public clientDataColumns: string[] = ['licenseId', 'entityName', 'submitted', 'submittedDate', 'open', 'actions'];
  public clientDataSource = new MatTableDataSource<IClientData>(this.clientData);
  @ViewChild('clientDataPaginator', { static: false }) clientDataPaginator: MatPaginator;
  @ViewChild('clientDataSort', { static: false }) clientDataSort: MatSort;

  public serviceCenterDataColumns: string[] = ['licenseId', 'entityName', 'submitted', 'submittedDate', 'open', 'actions'];
  public serviceCenterDataSource = new MatTableDataSource<IServiceCenterData>(this.serviceCenterData);
  @ViewChild('serviceCenterDataPaginator', { static: false }) serviceCenterDataPaginator: MatPaginator;
  @ViewChild('serviceCenterDataSort', { static: false }) serviceCenterDataSort: MatSort;

  public deIdentifiedDataColumns: string[] = ['startDate', 'endDate', 'actions'];
  public deIdentifiedDataSource = new MatTableDataSource<ISB303DeIdentifiedData>(this.sb303DeIdentifiedData);
  @ViewChild('deIdentifiedDataPaginator', { static: false }) deIdentifiedDataPaginator: MatPaginator;
  @ViewChild('deIdentifiedDataSort', { static: false }) deIdentifiedDataSort: MatSort;

  public licenseColumns: string[] = ['licenseId', 'entityName', 'primaryContactName'];
  public licenseSource = new MatTableDataSource<IServiceCenterLicense>(this.nonReportingLicenses);
  @ViewChild('licensePaginator', { static: false }) licensePaginator: MatPaginator;
  @ViewChild('licenseSort', { static: false }) licenseSort: MatSort;

  public dateForm = new FormGroup({
    from: new FormControl('', Validators.required),
    to: new FormControl('', Validators.required)
  });

  public serviceCenterForm = new FormGroup({
    id: new FormControl('', Validators.required)
  });

  constructor(public sharedService: SharedService,
              public sb303Service: SB303Service,
              public authService: AuthService,
              public toastr: ToastrService,
              public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.sb303Service.getDeIdentifiedData().subscribe({
      next: response => this.sb303DeIdentifiedData = response,
      error: e => console.log('error', e),
      complete: () =>
      {
        this.updateDeIdentifiedTable();
      }
    });

    this.sb303Service.getNonReportingLicense().subscribe({
      next: response => {
          this.nonReportingLicenses = response;
          this.updateNonReportingLicensesTable();
        },
      error: e => console.log('error', e)
    });
  }

  getRecordsByDate(): void {
    let form = this.dateForm.value;
    let from = new Date(form.from).toDateString();
    let to = new Date(form.to).toDateString();

    this.sb303Service.getSB303Records(from, to).subscribe({
      next: response => this.sb303Records = response,
      error: e => console.log('error', e),
      complete: () =>
      {
        this.serviceCenterData = this.sb303Records.map(record => record.serviceCenterData);
        this.clientData = this.sb303Records.map(record => record.clientData);
        this.updateTables();
      }
    });
  }

  getRecordsById(): void {
    let form = this.serviceCenterForm.value;
    let id = form.id;

    this.sb303Service.getSB303RecordsById(id).subscribe({
      next: response => this.sb303Records = response,
      error: e => console.log('error', e),
      complete: () =>
      {
        this.serviceCenterData = this.sb303Records.map(record => record.serviceCenterData);
        this.clientData = this.sb303Records.map(record => record.clientData);
        this.updateTables();
      }
    });
  }

  updateTables(): void {
    this.clientDataSource.data = this.clientData;
    this.clientDataSource.paginator = this.clientDataPaginator;
    this.clientDataSource.sort = this.clientDataSort;
    this.clientDataSource.sortingDataAccessor = this.customClientDataSortAccessor;

    this.serviceCenterDataSource.data = this.serviceCenterData;
    this.serviceCenterDataSource.paginator = this.serviceCenterDataPaginator;
    this.serviceCenterDataSource.sort = this.serviceCenterDataSort;
    this.serviceCenterDataSource.sortingDataAccessor = this.customServiceCenterDataSortAccessor;
  }

  closeReportingPeriod(): void {
    this.sharedService.openConfirm("Are you sure you want to close the reporting period? This will close the reporting period for all users and they will not be able to submit any more data. This action CANNOT be undone.");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.sb303Service.closeReportingPeriod().subscribe({
            next: response => {
              if(response)
                this.toastr.success('Reporting period closed successfully');

              if(!response)
                this.toastr.error('Reporting period could not be closed');
            },
            error: e => console.log('error', e)
          });
        }
      }
    )
  }

  createReportingPeriod(): void {
    const dialogRef = this.dialog.open(DialogCreateNewReportComponent, {
      role: 'dialog',
      ariaLabel: "Create New Report",
      minWidth: !this.sharedService.mobile ? '800px' : '400px',
      maxWidth: !this.sharedService.mobile ? '1000px' : '800px',
      maxHeight: !this.sharedService.mobile ? '1000px' : '800px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : "",
    });
  }

  //Client Data
  filterClientDataTable(event: Event) {
    let value = (event.target as HTMLInputElement).value;
    this.clientDataSource.filterPredicate = (data: any, filter: string) => {
      const isOpen = data.open ? 'open' : 'closed';
      const isSubmitted = data.submitted ? 'submitted' : 'not submitted';

      return isOpen.indexOf(filter) !== -1 ||
             isSubmitted.indexOf(filter) !== -1 ||
             JSON.stringify(data).toLowerCase().indexOf(filter) !== -1;
    };

    this.clientDataSource.filter = value;
  }

  customClientDataSortAccessor = (item: any, property: string) => {
    const direction = this.clientDataSort.direction;

    if (property === 'submittedDate') {
      const dateValue = item.submittedDate ? new Date(item.submittedDate) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }
    return item[property];
  };

  viewClientData(id: number): void {
    const dialogRef = this.dialog.open(DialogViewClientDataComponent, {
      data: id,
      role: 'dialog',
      ariaLabel: "Client Data",
      minWidth: !this.sharedService.mobile ? '800px' : '400px',
      maxWidth: !this.sharedService.mobile ? '1000px' : '800px',
      maxHeight: !this.sharedService.mobile ? '1000px' : '800px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : "",
    });
  }

  openClientData(id: number): void {
    this.sharedService.openConfirm("Do you want to open the client data for editing?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.sb303Service.openClientData(id).subscribe({
            next: response => {
              if(response) {
                this.toastr.success('Client data opened successfully');
                this.clientData.find(data => data.id === id).open = true;
                this.updateTables();
              }
              if(!response)
                this.toastr.error('Client data could not be opened');
            },
            error: e => console.log('error', e)
          });
        }
      }
    )
  }

  closeClientData(id: number): void {
    this.sharedService.openConfirm("Do you want to close the client data for editing?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.sb303Service.closeClientData(id).subscribe({
            next: response => {
              if(response){
                this.toastr.success('Client data closed successfully');
                this.clientData.find(data => data.id === id).open = false;
                this.updateTables();
              }
              if(!response)
                this.toastr.error('Client data could not be closed');
            },
            error: e => console.log('error', e)
          });
        }
      }
    )
  }

  downloadClientData(clientData: IClientData): void {
    let records: ISB303[]= [];
    this.sb303Service.downloadClientData(clientData.id).subscribe({
      next: response => {
        records.push(response);
        this.dialog.open(DialogDownloadClientDataComponent, {
          data: records,
          role: 'dialog',
          ariaLabel: "Download Client Data",
          minWidth: '350px',
          maxWidth: '350px'
        });

      },
      error: e => console.log('error', e)
    });
  }

  //Service Center Data
  filterServiceCenterDataTable(event: Event) {
    let value = (event.target as HTMLInputElement).value;
    this.serviceCenterDataSource.filterPredicate = (data: any, filter: string) => {
      const isOpen = data.open ? 'open' : 'closed';
      const isSubmitted = data.submitted ? 'submitted' : 'not submitted';

      return isOpen.indexOf(filter) !== -1 ||
             isSubmitted.indexOf(filter) !== -1 ||
             JSON.stringify(data).toLowerCase().indexOf(filter) !== -1;
    };

    this.serviceCenterDataSource.filter = value;
  }

  customServiceCenterDataSortAccessor = (item: any, property: string) => {
    const direction = this.serviceCenterDataSort.direction;

    if (property === 'submittedDate') {
      const dateValue = item.submittedDate ? new Date(item.submittedDate) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }
    return item[property];
  };

  viewServiceCenterData(id: number): void {
    const dialogRef = this.dialog.open(DialogViewServiceCenterDataComponent, {
      data: id,
      role: 'dialog',
      ariaLabel: "Service Center Data",
      minWidth: !this.sharedService.mobile ? '800px' : '400px',
      maxWidth: !this.sharedService.mobile ? '1000px' : '800px',
      maxHeight: !this.sharedService.mobile ? '1000px' : '800px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : "",
    });
  }

  openServiceCenterData(id: number): void {
    this.sharedService.openConfirm("Do you want to open the service center data for editing?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.sb303Service.openServiceCenterData(id).subscribe({
            next: response => {
              if(response) {
                this.toastr.success('Service Center data opened successfully');
                this.serviceCenterData.find(data => data.id === id).open = true;
                this.updateTables();
              }
              if(!response)
                this.toastr.error('Service Center data could not be opened');
            },
            error: e => console.log('error', e)
          });
        }
      }
    )
  }

  closeServiceCenterData(id: number): void {
    this.sharedService.openConfirm("Do you want to close the service center data for editing?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.sb303Service.closeServiceCenterData(id).subscribe({
            next: response => {
              if(response){
                this.toastr.success('Service Center data closed successfully');
                this.serviceCenterData.find(data => data.id === id).open = false;
                this.updateTables();
              }
              if(!response)
                this.toastr.error('Service Center data could not be closed');
            },
            error: e => console.log('error', e)
          });
        }
      }
    )
  }

  downloadServiceCenterData(serviceCenterData: IServiceCenterData): void {
    let records: ISB303[]= [];
    this.sb303Service.downloadServiceCenterData(serviceCenterData.id).subscribe({
      next: response => {
        records.push(response);
        this.dialog.open(DialogDownloadServiceCenterDataComponent, {
          data: records,
          role: 'dialog',
          ariaLabel: "Download Service Center Data",
          minWidth: '350px',
          maxWidth: '350px'
        });

      },
      error: e => console.log('error', e)
    });
  }

  //De-Identified Data
  filterDeIdentifiedTable(event: Event) {
    let value = (event.target as HTMLInputElement).value;
    this.deIdentifiedDataSource.filter = value.trim().toLowerCase();
  }

  updateDeIdentifiedTable(): void {
    this.deIdentifiedDataSource.data = this.sb303DeIdentifiedData;
    this.deIdentifiedDataSource.paginator = this.deIdentifiedDataPaginator;
    this.deIdentifiedDataSource.sort = this.deIdentifiedDataSort;
    this.deIdentifiedDataSource.sortingDataAccessor = this.customDeIdentifiedDataSortAccessor;
  }

  deleteDeIdentifiedData(id: number): void {
    this.sharedService.openConfirm("Are you sure you want to delete this De-Identified data?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.sb303Service.deleteDeIdentifiedData(id).subscribe({
            next: response => {
              if(response)
                this.toastr.success('De-Identified data deleted successfully');
                this.sb303DeIdentifiedData = this.sb303DeIdentifiedData.filter(data => data.id !== id);
                this.updateDeIdentifiedTable();
              if(!response)
                this.toastr.error('This De-Identified data could not be deleted');
            },
            error: e => console.log('error', e)
         });
        }
      });
  }

  downloadDeIdentifiedData(id: number): void {
    let deIdentifiedData = this.sb303DeIdentifiedData.find(data => data.id === id);
    let fileName = `${deIdentifiedData.startDate}-${deIdentifiedData.endDate} De-Identified Data.csv`

    this.sb303Service.downloadDeIdentifiedData(id).subscribe(blob => {
      const url = window.URL.createObjectURL(blob);
      const download = document.createElement('a');
      download.href = url;
      download.download = fileName;
      download.click();
      window.URL.revokeObjectURL(url);
    });
  }

  addDeIdentifiedData(data: ISB303DeIdentifiedData): void {
    this.sb303DeIdentifiedData.push(data);
    this.updateDeIdentifiedTable();
  }

  uploadDeIdentifiedData(): void {
    const dialogRef = this.dialog.open(DialogUploadDeIdentifiedDataComponent, {
      role: 'dialog',
      ariaLabel: "Upload De-Identified Data",
      minWidth: !this.sharedService.mobile ? '800px' : '400px',
      maxWidth: !this.sharedService.mobile ? '1000px' : '800px',
      maxHeight: !this.sharedService.mobile ? '1000px' : '800px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : "",
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        this.toastr.success('De-Identified data uploaded successfully');
        this.addDeIdentifiedData(response);
      }
    });
  }

  customDeIdentifiedDataSortAccessor = (item: any, property: string) => {
    const direction = this.deIdentifiedDataSort.direction;

    if (property === 'startDate') {
      const dateValue = item.startDate ? new Date(item.startDate) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }
    if (property === 'endDate') {
      const dateValue = item.endDate ? new Date(item.endDate) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }
    return item[property];
  };

  //Non-Reporting Licenses
  filterLicensesTable(event: Event) {
    let value = (event.target as HTMLInputElement).value;
    this.licenseSource.filter = value.trim().toLowerCase();
  }

  updateNonReportingLicensesTable(): void {
    this.licenseSource.data = this.nonReportingLicenses;
    this.licenseSource.paginator = this.licensePaginator;
    this.licenseSource.sort = this.licenseSort;
  }
 }
