import { trigger, transition, style, animate } from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn, AbstractControl } from "@angular/forms";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { IApplicant } from "app/models/licenses/applicant";
import { ILicenseChecklist } from "app/models/licenses/license-checklist";
import { IStaff } from "app/models/user-info/staff";
import { SharedService } from "app/services/core/shared.service";
import { LicenseChecklistService } from "app/services/license-checklist/license-checklist.service";
import { LicenseChecklistApplicantComponent } from "./license-checklist-applicant/license-checklist-applicant.component";
import { LicenseChecklistCurriculumComponent } from "./license-checklist-curriculum/license-checklist-curriculum.component";

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-licenseChecklist',
  templateUrl: './license-checklist.component.html',
  styleUrls: ['./license-checklist.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class LicenseChecklistComponent implements OnInit {

  @Input() entityType: string = '';
  @Input() license: any;
  @Input() staff: IStaff[] = [];
  @Input() applicants: IApplicant[] = [];
  @Input() renewal: boolean = false;
  @Output() notifyParent: EventEmitter<ILicenseChecklist> = new EventEmitter<ILicenseChecklist>();

  public checklist: ILicenseChecklist ={
    id: 0,
    entityType: "",
    licenseName: "",
    licenseId: "",
    teamMember: "",
    reviewDate: "",
    demographicAndContactInfoComplete: false,
    attestationsAndSignedComplete: false,
    photoIdComplete: false,
    photoIdType: "",
    idNameMatchCompleted: false,
    legalAgeComplete: false,
    backgroundCheckPassed: false,
    backgroundCheckDate: "",
    reviewNotes: "",
    requirementsMetDate: "",
    applicantInfoComplete: false,
    manufacturerEndorsements: false,
    approvedLandUseCompatibility: false,
    propertyOwnerStatement: false,
    propertyOwnerStatementNA: false,
    premisesPlanMeetsRequirements: false,
    socialEquityPlanMeetsRequirements: false,
    socialEquityPlanEvaluationMeetsRequirements: false,
    authorizedIndividualsFormPlan: false,
    reducedLicensingFeeDocumentation: false,
    reducedLicensingFeeDocumentationNA: false,
    socialSecurityIncomeBenefitCompleted: false,
    applicantsCurrentEligibilityStatement: false,
    foodStampBenefits: false,
    servedInArmedForces: false,
    primaryContactIdentified: false,
    nonProfitEntity: false,
    individualEntityApplicantInfoComplete: false,
    applicantInformationFormsReceived: false,
    licenseChecklistApplicants: [],
    orelapCertificateValid: false,
    labListedOnline: false,
    notSubjectToHealthCareFacility: false,
    dateNotSubjectToHealthCareFacility: "",
    completedSchoolProximityAttestations: false,
    meetsSchoolProximityRequirements: false,
    gisLiasonConfirmed: false,
    completedPracticumSiteAttestations: false,
    indicatedServesAsPracticumSite: false,
    indicatedServesAsPracticumSiteNA: false,
    passedExam: false,
    trainingProgramCertificate: false,
    submitForApproval: false,
    submitForClosing: false,
    submitForDenial: false,
    submitForClosingNotes: "",
    submitForDenialNotes: "",
    responsiblePartyInformationComplete: false,
    registeredAgentInformationComplete: false,
    directorInformationComplete: false,
    firstLeadInformationComplete: false,
    secondLeadInformationComplete: false,
    responsiblePartyAttestationsComplete: false,
    registeredState: "",
    instructorNames: "",
    licenseChecklistCurriculums: [],
    workerPermitSubmitted: false,
    peerReviewComplete: false,
    peerReviewMember: "",
    peerReviewDate: ""
  };
  public today: Date = new Date(Date.now());
  public photoIdTypes = ["Passport", "Identification card issued under ORS 807.400",
"Driver license, whether issued by the State of Oregon or by another state of the United States",
"United States military identification card",
"An identification card issued by a federally recognized Indian tribe with photo, name, and 	date of birth",
"Any other identification card issued by a state or territory that bears a picture of the person, the name of the person, the person's date of birth, and a physical description of the person."]
  public checklistForm = new UntypedFormGroup({
    licenseName: new UntypedFormControl("", [Validators.required]),
    licenseId: new UntypedFormControl("", [Validators.required]),
    teamMember: new UntypedFormControl("", [Validators.required]),
    reviewDate: new UntypedFormControl("", [Validators.required]),
    demographicAndContactInfoComplete: new UntypedFormControl(false),
    attestationsAndSignedComplete: new UntypedFormControl(false),
    photoIdComplete: new UntypedFormControl(false),
    photoIdType: new UntypedFormControl(""),
    idNameMatchCompleted: new UntypedFormControl(false),
    legalAgeComplete: new UntypedFormControl(false),
    backgroundCheckPassed: new UntypedFormControl(false),
    backgroundCheckDate: new UntypedFormControl(""),
    reviewNotes: new UntypedFormControl(""),
    requirementsMetDate: new UntypedFormControl("", [Validators.required]),
    applicantInfoComplete: new UntypedFormControl(false),
    primaryContactIdentified: new UntypedFormControl(false),
    individualEntityApplicantInfoComplete: new UntypedFormControl(false),
    applicantInformationFormsReceived: new UntypedFormControl(false),
    manufacturerEndorsements: new UntypedFormControl(false),
    approvedLandUseCompatibility: new UntypedFormControl(false),
    propertyOwnerStatement: new UntypedFormControl(false),
    propertyOwnerStatementNA: new UntypedFormControl(false),
    premisesPlanMeetsRequirements: new UntypedFormControl(false),
    socialEquityPlanMeetsRequirements: new UntypedFormControl(false),
    socialEquityPlanEvaluationMeetsRequirements: new UntypedFormControl(false),
    reducedLicensingFeeDocumentation: new UntypedFormControl(false),
    reducedLicensingFeeDocumentationNA: new UntypedFormControl(false),
    nonProfitEntity:new UntypedFormControl(false),
    socialSecurityIncomeBenefitCompleted: new UntypedFormControl(false),
    applicantsCurrentEligibilityStatement: new UntypedFormControl(false),
    foodStampBenefits: new UntypedFormControl(false),
    servedInArmedForces: new UntypedFormControl(false),
    orelapCertificateValid: new UntypedFormControl(false),
    labListedOnline: new UntypedFormControl(false),
    notSubjectToHealthCareFacility: new UntypedFormControl(false),
    dateNotSubjectToHealthCareFacility: new UntypedFormControl(""),
    completedSchoolProximityAttestations: new UntypedFormControl(false),
    meetsSchoolProximityRequirements: new UntypedFormControl(false),
    gisLiasonConfirmed: new UntypedFormControl(false),
    completedPracticumSiteAttestations: new UntypedFormControl(false),
    indicatedServesAsPracticumSite: new UntypedFormControl(false),
    indicatedServesAsPracticumSiteNA: new UntypedFormControl(false),
    passedExam: new UntypedFormControl(false),
    trainingProgramCertificate: new UntypedFormControl(false),
    submitForClosingNotes: new UntypedFormControl(""),
    submitForDenialNotes: new UntypedFormControl(""),
    responsiblePartyInformationComplete: new UntypedFormControl(false),
    registeredAgentInformationComplete: new UntypedFormControl(false),
    directorInformationComplete: new UntypedFormControl(false),
    firstLeadInformationComplete: new UntypedFormControl(false),
    secondLeadInformationComplete: new UntypedFormControl(false),
    responsiblePartyAttestationsComplete: new UntypedFormControl(false),
    registeredState: new UntypedFormControl(""),
    instructorNames: new UntypedFormControl(""),
    workerPermitSubmitted: new UntypedFormControl(false),
    peerReviewComplete: new UntypedFormControl(false, [Validators.requiredTrue]),
    peerReviewMember: new UntypedFormControl("", [Validators.required]),
    peerReviewDate: new UntypedFormControl("", [Validators.required]),

  }, {
    validators: [
      this.propertyOwnerValidator(),
      this.reducedFeeValidator(),
      this.reducedFeeDocumentationValidator(),
      this.servesAsPracticumValidator(),
      this.submittingChecklistValidator()
    ]
  });



  constructor(public sharedService: SharedService,
    private licenseChecklistService: LicenseChecklistService,
    private dialog: MatDialog,) {}

  ngOnInit(): void {
    this.licenseChecklistService.getLicenseChecklist(this.license.id, this.entityType).subscribe(
      response => {
        this.checklist = response
        this.updateForm();
        this.notifyParent.emit(this.checklist);
      },
      error => console.log('error', error),
    )
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.license && !changes.license.firstChange) {
      this.reload();
    }
  }

  reload() {
    this.licenseChecklistService.getLicenseChecklist(this.license.id, this.entityType).subscribe(
      response => {
        this.checklist = response
        this.updateForm();
        this.notifyParent.emit(this.checklist);
      },
      error => console.log('error', error),
    )
  }
  openApplicants(): void {
    const dialogRef = this.dialog.open(LicenseChecklistApplicantComponent, {
      data: { licenseChecklistApplicants: this.checklist.licenseChecklistApplicants, checklistId: this.checklist.id, licenseApplicants: this.applicants},
      minWidth: !this.sharedService.mobile? '1000px' : '300px',
      maxWidth: !this.sharedService.mobile? '1000px' : '300px',
      maxHeight: !this.sharedService.mobile? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });

    dialogRef.afterClosed().subscribe((response) => {
      this.checklist.licenseChecklistApplicants = response;
    });
  }

  openCurriculum(): void {
    this.dialog.open(LicenseChecklistCurriculumComponent, {
      data: { licenseCurriculums: this.checklist.licenseChecklistCurriculums, checklistId: this.checklist.id},
      minWidth: !this.sharedService.mobile? '1000px' : '300px',
      maxWidth: !this.sharedService.mobile? '1000px' : '300px',
      maxHeight: !this.sharedService.mobile? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
  }

  createChecklist(): void {
    this.licenseChecklistService.createLicenseChecklist(this.license.id, this.entityType).subscribe(
      response => {
        this.checklist = response;
        this.updateForm();
        this.notifyParent.emit(this.checklist);
      },
      error => console.log('error', error),
    )
  }

  isFormValid(): boolean {
    return this.checklistForm.valid;
  }

  updateForm(): void {
    if(this.entityType == this.sharedService.workerPermit)
      this.updatePermitList();
    if(this.entityType == this.sharedService.manufacturer)
      this.updateManufacturerList();
    if(this.entityType == this.sharedService.testingLab)
      this.updateTestingLabList();
    if(this.entityType == this.sharedService.serviceCenter)
      this.updateServiceCenterList();
    if(this.entityType == this.sharedService.facilitator)
      this.updateFacilitatorList();
    if(this.entityType == this.sharedService.trainingProgram)
      this.updateTrainingProgramList();

    this.checklistForm.markAllAsTouched();
  }

  propertyOwnerValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const propertyOwnerStatement = control.value.propertyOwnerStatement;
      const propertyOwnerStatementNA = control.value.propertyOwnerStatementNA;
      if(propertyOwnerStatement || propertyOwnerStatementNA || this.entityType != this.sharedService.manufacturer)
        return null;
      if (!propertyOwnerStatement && !propertyOwnerStatementNA) {
        return {propertyOwnerStatementRequired: true} ;
      }
    };
  }

  validatePropertyOwner(): boolean {
    if (this.checklistForm.hasError('propertyOwnerStatementRequired')) {
      this.checklistForm.get('propertyOwnerStatement').setErrors(['required']);
      this.checklistForm.get('propertyOwnerStatementNA').setErrors(['required']);
      return true;
    }
    this.checklistForm.get('propertyOwnerStatement').clearValidators();
    this.checklistForm.get('propertyOwnerStatement').updateValueAndValidity();
    this.checklistForm.get('propertyOwnerStatementNA').clearValidators();
    this.checklistForm.get('propertyOwnerStatementNA').updateValueAndValidity();
    return false;
  }

  reducedFeeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const propertyOwnerStatement = control.value.reducedLicensingFeeDocumentation;
      const propertyOwnerStatementNA = control.value.reducedLicensingFeeDocumentationNA;
      if(propertyOwnerStatement || propertyOwnerStatementNA || (this.entityType != this.sharedService.manufacturer && this.entityType != this.sharedService.serviceCenter && this.entityType != this.sharedService.facilitator))
        return null;
      if (!propertyOwnerStatement && !propertyOwnerStatementNA) {
        return {reducedFeeStatementRequired: true} ;
      }
    };
  }

  validateReducedFee(): boolean {
    if (this.checklistForm.hasError('reducedFeeStatementRequired')) {
      this.checklistForm.get('reducedLicensingFeeDocumentation').setErrors(['required']);
      this.checklistForm.get('reducedLicensingFeeDocumentationNA').setErrors(['required']);
      return true;
    }
    this.checklistForm.get('reducedLicensingFeeDocumentation').clearValidators();
    this.checklistForm.get('reducedLicensingFeeDocumentation').updateValueAndValidity();
    this.checklistForm.get('reducedLicensingFeeDocumentationNA').clearValidators();
    this.checklistForm.get('reducedLicensingFeeDocumentationNA').updateValueAndValidity();
    return false;
  }

  reducedFeeDocumentationValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const reducedFee = control.value.reducedLicensingFeeDocumentation;
      const nonProfitEntity = control.value.nonProfitEntity;
      const socialSecuirity = control.value.socialSecurityIncomeBenefitCompleted;
      const applicantsCurrentEligibilityStatement = control.value.applicantsCurrentEligibilityStatement;
      const foodStampBenefits = control.value.foodStampBenefits;
      const servedInArmedForces = control.value.servedInArmedForces;
      if((this.entityType != this.sharedService.manufacturer && this.entityType != this.sharedService.serviceCenter && this.entityType != this.sharedService.facilitator) || !reducedFee)
        return null;
      if (reducedFee && (!nonProfitEntity && !socialSecuirity && !applicantsCurrentEligibilityStatement && !foodStampBenefits && !servedInArmedForces)) {
        return {reducedFeeDocumentationRequired: true} ;
      }
    };
  }

  validateReducedFeeDocumentation(): boolean {
    if (this.checklistForm.hasError('reducedFeeDocumentationRequired')) {
      this.checklistForm.get('nonProfitEntity').setErrors(['required']);
      this.checklistForm.get('socialSecurityIncomeBenefitCompleted').setErrors(['required']);
      this.checklistForm.get('applicantsCurrentEligibilityStatement').setErrors(['required']);
      this.checklistForm.get('foodStampBenefits').setErrors(['required']);
      this.checklistForm.get('servedInArmedForces').setErrors(['required']);

      return true;
    }
    this.checklistForm.get('nonProfitEntity').clearValidators();
    this.checklistForm.get('nonProfitEntity').updateValueAndValidity();
    this.checklistForm.get('socialSecurityIncomeBenefitCompleted').clearValidators();
    this.checklistForm.get('socialSecurityIncomeBenefitCompleted').updateValueAndValidity();
    this.checklistForm.get('applicantsCurrentEligibilityStatement').clearValidators();
    this.checklistForm.get('applicantsCurrentEligibilityStatement').updateValueAndValidity();
    this.checklistForm.get('foodStampBenefits').clearValidators();
    this.checklistForm.get('foodStampBenefits').updateValueAndValidity();
    this.checklistForm.get('servedInArmedForces').clearValidators();
    this.checklistForm.get('servedInArmedForces').updateValueAndValidity();
    return false;
  }

  updateManufacturerList():void {
    this.checklistForm.controls['applicantInfoComplete'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['attestationsAndSignedComplete'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['primaryContactIdentified'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['individualEntityApplicantInfoComplete'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['applicantInformationFormsReceived'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['manufacturerEndorsements'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['approvedLandUseCompatibility'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['propertyOwnerStatement'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['propertyOwnerStatementNA'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['premisesPlanMeetsRequirements'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['socialEquityPlanMeetsRequirements'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['reducedLicensingFeeDocumentation'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['reducedLicensingFeeDocumentationNA'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['nonProfitEntity'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['socialSecurityIncomeBenefitCompleted'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['applicantsCurrentEligibilityStatement'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['foodStampBenefits'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['servedInArmedForces'].setValidators([Validators.requiredTrue]);

    if(!this.renewal) {
      this.checklistForm.controls['photoIdComplete'].setValidators([Validators.requiredTrue]);
    }
    if(this.renewal) {
      this.checklistForm.controls['socialEquityPlanEvaluationMeetsRequirements'].setValidators([Validators.requiredTrue]);
    }

    this.checklistForm.patchValue({
      licenseName: this.license.entityName,
      licenseId: this.license.licenseId,
      teamMember: this.checklist.teamMember,
      applicantInfoComplete: this.checklist.applicantInfoComplete,
      attestationsAndSignedComplete: this.checklist.attestationsAndSignedComplete,
      primaryContactIdentified: this.checklist.primaryContactIdentified,
      individualEntityApplicantInfoComplete: this.checklist.individualEntityApplicantInfoComplete,
      applicantInformationFormsReceived: this.checklist.applicantInformationFormsReceived,
      photoIdComplete: this.checklist.photoIdComplete,
      manufacturerEndorsements: this.checklist.manufacturerEndorsements,
      approvedLandUseCompatibility: this.checklist.approvedLandUseCompatibility,
      propertyOwnerStatement: this.checklist.propertyOwnerStatement,
      propertyOwnerStatementNA: this.checklist.propertyOwnerStatementNA,
      premisesPlanMeetsRequirements: this.checklist.premisesPlanMeetsRequirements,
      socialEquityPlanMeetsRequirements: this.checklist.socialEquityPlanMeetsRequirements,
      socialEquityPlanEvaluationMeetsRequirements: this.checklist.socialEquityPlanEvaluationMeetsRequirements,
      reducedLicensingFeeDocumentation: this.checklist.reducedLicensingFeeDocumentation,
      reducedLicensingFeeDocumentationNA: this.checklist.reducedLicensingFeeDocumentationNA,
      nonProfitEntity: this.checklist.nonProfitEntity,
      socialSecurityIncomeBenefitCompleted: this.checklist.socialSecurityIncomeBenefitCompleted,
      applicantsCurrentEligibilityStatement: this.checklist.applicantsCurrentEligibilityStatement,
      foodStampBenefits: this.checklist.foodStampBenefits,
      servedInArmedForces: this.checklist.servedInArmedForces,
      reviewNotes: this.checklist.reviewNotes,
      peerReviewComplete: this.checklist.peerReviewComplete,
      peerReviewMember: this.checklist.peerReviewMember,
    })

    if(this.checklist.reviewDate != null && this.checklist.reviewDate.length > 0)
      this.checklistForm.patchValue({
        reviewDate: new Date(this.checklist.reviewDate)
      });

    if(this.checklist.requirementsMetDate != null && this.checklist.requirementsMetDate.length > 0)
      this.checklistForm.patchValue({
        requirementsMetDate: new Date(this.checklist.requirementsMetDate)
      });

    if(this.checklist.peerReviewDate != null && this.checklist.peerReviewDate.length > 0)
      this.checklistForm.patchValue({
        peerReviewDate: new Date(this.checklist.peerReviewDate)
      });
  }

  updatePermitList():void {
    this.checklistForm.controls['demographicAndContactInfoComplete'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['attestationsAndSignedComplete'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['photoIdType'].setValidators([Validators.required]);
    this.checklistForm.controls['legalAgeComplete'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['backgroundCheckPassed'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['backgroundCheckDate'].setValidators([Validators.required]);

    if(!this.renewal) {
      this.checklistForm.controls['photoIdComplete'].setValidators([Validators.requiredTrue]);
      this.checklistForm.controls['idNameMatchCompleted'].setValidators([Validators.requiredTrue]);
    }

    this.checklistForm.patchValue({
      licenseName: this.license.applicants[0].legalFirstName + ' ' + this.license.applicants[0].legalLastName,
      licenseId: this.license.licenseId,
      teamMember: this.checklist.teamMember,
      demographicAndContactInfoComplete: this.checklist.demographicAndContactInfoComplete,
      attestationsAndSignedComplete: this.checklist.attestationsAndSignedComplete,
      photoIdComplete: this.checklist.photoIdComplete,
      photoIdType: this.checklist.photoIdType,
      idNameMatchCompleted: this.checklist.idNameMatchCompleted,
      legalAgeComplete: this.checklist.legalAgeComplete,
      backgroundCheckPassed: this.license.applicants[0].backgroundCheckPassed,
      reviewNotes: this.checklist.reviewNotes,
      peerReviewComplete: this.checklist.peerReviewComplete,
      peerReviewMember: this.checklist.peerReviewMember,
    })

    if(this.checklist.reviewDate != null && this.checklist.reviewDate.length > 0)
      this.checklistForm.patchValue({
        reviewDate: new Date(this.checklist.reviewDate)
      });

    if(this.checklist.requirementsMetDate != null && this.checklist.requirementsMetDate.length > 0)
      this.checklistForm.patchValue({
        requirementsMetDate: new Date(this.checklist.requirementsMetDate)
      });

    if(this.license.applicants[0].backgroundCheckDate != null && this.license.applicants[0].backgroundCheckDate.length > 0)
      this.checklistForm.patchValue({
        backgroundCheckDate: new Date(this.license.applicants[0].backgroundCheckDate)
      });

    if(this.checklist.peerReviewDate != null && this.checklist.peerReviewDate.length > 0)
      this.checklistForm.patchValue({
        peerReviewDate: new Date(this.checklist.peerReviewDate)
      });
  }

  updateTestingLabList():void {
    this.checklistForm.controls['applicantInfoComplete'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['attestationsAndSignedComplete'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['primaryContactIdentified'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['individualEntityApplicantInfoComplete'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['applicantInformationFormsReceived'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['premisesPlanMeetsRequirements'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['socialEquityPlanMeetsRequirements'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['orelapCertificateValid'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['labListedOnline'].setValidators([Validators.requiredTrue]);

    if(!this.renewal) {
      this.checklistForm.controls['photoIdComplete'].setValidators([Validators.requiredTrue]);
    }
    if(this.renewal) {
      this.checklistForm.controls['socialEquityPlanEvaluationMeetsRequirements'].setValidators([Validators.requiredTrue]);
    }

    this.checklistForm.patchValue({
      licenseName: this.license.entityName,
      licenseId: this.license.licenseId,
      teamMember: this.checklist.teamMember,
      applicantInfoComplete: this.checklist.applicantInfoComplete,
      attestationsAndSignedComplete: this.checklist.attestationsAndSignedComplete,
      primaryContactIdentified: this.checklist.primaryContactIdentified,
      individualEntityApplicantInfoComplete: this.checklist.individualEntityApplicantInfoComplete,
      applicantInformationFormsReceived: this.checklist.applicantInformationFormsReceived,
      photoIdComplete: this.checklist.photoIdComplete,
      premisesPlanMeetsRequirements: this.checklist.premisesPlanMeetsRequirements,
      socialEquityPlanMeetsRequirements: this.checklist.socialEquityPlanMeetsRequirements,
      socialEquityPlanEvaluationMeetsRequirements: this.checklist.socialEquityPlanEvaluationMeetsRequirements,
      orelapCertificateValid: this.checklist.orelapCertificateValid,
      labListedOnline: this.checklist.labListedOnline,
      reviewNotes: this.checklist.reviewNotes,
      peerReviewComplete: this.checklist.peerReviewComplete,
      peerReviewMember: this.checklist.peerReviewMember,
    })

    if(this.checklist.reviewDate != null && this.checklist.reviewDate.length > 0)
      this.checklistForm.patchValue({
        reviewDate: new Date(this.checklist.reviewDate)
      });

    if(this.checklist.requirementsMetDate != null && this.checklist.requirementsMetDate.length > 0)
      this.checklistForm.patchValue({
        requirementsMetDate: new Date(this.checklist.requirementsMetDate)
      });

    if(this.checklist.peerReviewDate != null && this.checklist.peerReviewDate.length > 0)
      this.checklistForm.patchValue({
        peerReviewDate: new Date(this.checklist.peerReviewDate)
      });
  }

  updateFacilitatorList():void {
    this.checklistForm.controls['applicantInfoComplete'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['attestationsAndSignedComplete'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['photoIdType'].setValidators([Validators.required]);
    this.checklistForm.controls['legalAgeComplete'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['backgroundCheckPassed'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['backgroundCheckDate'].setValidators([Validators.required]);
    this.checklistForm.controls['passedExam'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['trainingProgramCertificate'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['socialEquityPlanMeetsRequirements'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['reducedLicensingFeeDocumentation'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['reducedLicensingFeeDocumentationNA'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['socialSecurityIncomeBenefitCompleted'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['applicantsCurrentEligibilityStatement'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['foodStampBenefits'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['servedInArmedForces'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['workerPermitSubmitted'].setValidators([Validators.requiredTrue]);

    if(!this.renewal) {
      this.checklistForm.controls['photoIdComplete'].setValidators([Validators.requiredTrue]);
      this.checklistForm.controls['idNameMatchCompleted'].setValidators([Validators.requiredTrue]);
    }
    if(this.renewal) {
      this.checklistForm.controls['socialEquityPlanEvaluationMeetsRequirements'].setValidators([Validators.requiredTrue]);
    }

    this.checklistForm.patchValue({
      licenseName: this.license.applicants[0].legalFirstName + ' ' + this.license.applicants[0].legalLastName,
      licenseId: this.license.licenseId,
      teamMember: this.checklist.teamMember,
      reviewNotes: this.checklist.reviewNotes,

      applicantInfoComplete: this.checklist.applicantInfoComplete,
      attestationsAndSignedComplete: this.checklist.attestationsAndSignedComplete,
      photoIdComplete: this.checklist.photoIdComplete,
      photoIdType: this.checklist.photoIdType,
      idNameMatchCompleted: this.checklist.idNameMatchCompleted,
      legalAgeComplete: this.checklist.legalAgeComplete,
      backgroundCheckPassed: this.checklist.backgroundCheckPassed,
      passedExam: this.checklist.passedExam,
      trainingProgramCertificate: this.checklist.trainingProgramCertificate,
      socialEquityPlanMeetsRequirements: this.checklist.socialEquityPlanMeetsRequirements,
      socialEquityPlanEvaluationMeetsRequirements: this.checklist.socialEquityPlanEvaluationMeetsRequirements,
      reducedLicensingFeeDocumentation: this.checklist.reducedLicensingFeeDocumentation,
      reducedLicensingFeeDocumentationNA: this.checklist.reducedLicensingFeeDocumentationNA,
      socialSecurityIncomeBenefitCompleted: this.checklist.socialSecurityIncomeBenefitCompleted,
      applicantsCurrentEligibilityStatement: this.checklist.applicantsCurrentEligibilityStatement,
      foodStampBenefits: this.checklist.foodStampBenefits,
      servedInArmedForces: this.checklist.servedInArmedForces,
      submitForClosingNotes: this.checklist.submitForClosingNotes,
      submitForDenialNotes: this.checklist.submitForDenialNotes,
      workerPermitSubmitted: this.checklist.workerPermitSubmitted,
      peerReviewComplete: this.checklist.peerReviewComplete,
      peerReviewMember: this.checklist.peerReviewMember,
    })

    if(this.checklist.reviewDate != null && this.checklist.reviewDate.length > 0)
      this.checklistForm.patchValue({
        reviewDate: new Date(this.checklist.reviewDate)
      });

    if(this.checklist.requirementsMetDate != null && this.checklist.requirementsMetDate.length > 0)
      this.checklistForm.patchValue({
        requirementsMetDate: new Date(this.checklist.requirementsMetDate)
      });

    if(this.checklist.backgroundCheckDate != null && this.checklist.backgroundCheckDate.length > 0)
      this.checklistForm.patchValue({
        backgroundCheckDate: new Date(this.checklist.backgroundCheckDate)
      });

    if(this.checklist.peerReviewDate != null && this.checklist.peerReviewDate.length > 0)
      this.checklistForm.patchValue({
        peerReviewDate: new Date(this.checklist.peerReviewDate)
      });
  }

  servesAsPracticumValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const indicatedServesAsPracticumSite = control.value.indicatedServesAsPracticumSite;
      const indicatedServesAsPracticumSiteNA = control.value.indicatedServesAsPracticumSiteNA;
      if(indicatedServesAsPracticumSite || indicatedServesAsPracticumSiteNA || this.entityType != this.sharedService.serviceCenter)
        return null;
      if (!indicatedServesAsPracticumSite && !indicatedServesAsPracticumSiteNA) {
        return {indicatedServesAsPracticumRequired: true} ;
      }
    };
  }

  validateServesAsPracticum(): boolean {
    if (this.checklistForm.hasError('indicatedServesAsPracticumRequired')) {
      this.checklistForm.get('indicatedServesAsPracticumSite').setErrors(['required']);
      this.checklistForm.get('indicatedServesAsPracticumSiteNA').setErrors(['required']);
      return true;
    }
    this.checklistForm.get('indicatedServesAsPracticumSite').clearValidators();
    this.checklistForm.get('indicatedServesAsPracticumSite').updateValueAndValidity();
    this.checklistForm.get('indicatedServesAsPracticumSiteNA').clearValidators();
    this.checklistForm.get('indicatedServesAsPracticumSiteNA').updateValueAndValidity();
    return false;
  }

  validateSubmittingChecklist(): boolean {
    if (this.checklistForm.hasError('requirementsMetDateSubmittingRequired')) {
      this.checklistForm.get('requirementsMetDate').setErrors(['required']);
      return true;
    }
    this.checklistForm.get('requirementsMetDate').clearValidators();
    this.checklistForm.get('requirementsMetDate').updateValueAndValidity();
    return false;
  }

  submittingChecklistValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const requirementsMetDate = control.value.requirementsMetDate;

      if(requirementsMetDate != null && requirementsMetDate.length > 0)
        return null;
      if (requirementsMetDate == null || requirementsMetDate.length <= 0) {
        return {requirementsMetDateSubmittingRequired: true} ;
      }
    };
  }

  updateServiceCenterList():void {
    this.checklistForm.controls['applicantInfoComplete'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['attestationsAndSignedComplete'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['primaryContactIdentified'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['individualEntityApplicantInfoComplete'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['applicantInformationFormsReceived'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['approvedLandUseCompatibility'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['premisesPlanMeetsRequirements'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['socialEquityPlanMeetsRequirements'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['reducedLicensingFeeDocumentation'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['reducedLicensingFeeDocumentationNA'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['nonProfitEntity'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['socialSecurityIncomeBenefitCompleted'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['applicantsCurrentEligibilityStatement'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['foodStampBenefits'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['servedInArmedForces'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['notSubjectToHealthCareFacility'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['dateNotSubjectToHealthCareFacility'].setValidators([Validators.required]);
    this.checklistForm.controls['completedSchoolProximityAttestations'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['meetsSchoolProximityRequirements'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['gisLiasonConfirmed'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['completedPracticumSiteAttestations'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['indicatedServesAsPracticumSite'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['indicatedServesAsPracticumSiteNA'].setValidators([Validators.requiredTrue]);

    if(!this.renewal) {
      this.checklistForm.controls['photoIdComplete'].setValidators([Validators.requiredTrue]);
    }

    if(this.renewal) {
      this.checklistForm.controls['socialEquityPlanEvaluationMeetsRequirements'].setValidators([Validators.requiredTrue]);
    }


    this.checklistForm.patchValue({
      licenseName: this.license.entityName,
      licenseId: this.license.licenseId,
      teamMember: this.checklist.teamMember,
      reviewNotes: this.checklist.reviewNotes,

      applicantInfoComplete: this.checklist.applicantInfoComplete,
      attestationsAndSignedComplete: this.checklist.attestationsAndSignedComplete,
      primaryContactIdentified: this.checklist.primaryContactIdentified,
      individualEntityApplicantInfoComplete: this.checklist.individualEntityApplicantInfoComplete,
      applicantInformationFormsReceived: this.checklist.applicantInformationFormsReceived,
      photoIdComplete: this.checklist.photoIdComplete,
      approvedLandUseCompatibility: this.checklist.approvedLandUseCompatibility,
      premisesPlanMeetsRequirements: this.checklist.premisesPlanMeetsRequirements,
      socialEquityPlanMeetsRequirements: this.checklist.socialEquityPlanMeetsRequirements,
      socialEquityPlanEvaluationMeetsRequirements: this.checklist.socialEquityPlanEvaluationMeetsRequirements,
      reducedLicensingFeeDocumentation: this.checklist.reducedLicensingFeeDocumentation,
      reducedLicensingFeeDocumentationNA: this.checklist.reducedLicensingFeeDocumentationNA,
      nonProfitEntity: this.checklist.nonProfitEntity,
      socialSecurityIncomeBenefitCompleted: this.checklist.socialSecurityIncomeBenefitCompleted,
      applicantsCurrentEligibilityStatement: this.checklist.applicantsCurrentEligibilityStatement,
      foodStampBenefits: this.checklist.foodStampBenefits,
      servedInArmedForces: this.checklist.servedInArmedForces,
      notSubjectToHealthCareFacility: this.checklist.notSubjectToHealthCareFacility,
      completedSchoolProximityAttestations: this.checklist.completedSchoolProximityAttestations,
      meetsSchoolProximityRequirements: this.checklist.meetsSchoolProximityRequirements,
      gisLiasonConfirmed: this.checklist.gisLiasonConfirmed,
      completedPracticumSiteAttestations: this.checklist.completedPracticumSiteAttestations,
      indicatedServesAsPracticumSite: this.checklist.indicatedServesAsPracticumSite,
      indicatedServesAsPracticumSiteNA: this.checklist.indicatedServesAsPracticumSiteNA,
      peerReviewComplete: this.checklist.peerReviewComplete,
      peerReviewMember: this.checklist.peerReviewMember,
    })

    if(this.checklist.reviewDate != null && this.checklist.reviewDate.length > 0)
      this.checklistForm.patchValue({
        reviewDate: new Date(this.checklist.reviewDate)
      });

    if(this.checklist.requirementsMetDate != null && this.checklist.requirementsMetDate.length > 0)
      this.checklistForm.patchValue({
        requirementsMetDate: new Date(this.checklist.requirementsMetDate)
      });

    if(this.checklist.dateNotSubjectToHealthCareFacility != null && this.checklist.dateNotSubjectToHealthCareFacility.length > 0)
      this.checklistForm.patchValue({
        dateNotSubjectToHealthCareFacility: new Date(this.checklist.dateNotSubjectToHealthCareFacility)
      });

    if(this.checklist.peerReviewDate != null && this.checklist.peerReviewDate.length > 0)
      this.checklistForm.patchValue({
        peerReviewDate: new Date(this.checklist.peerReviewDate)
      });
  }

  updateTrainingProgramList():void {
    this.checklistForm.controls['applicantInfoComplete'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['responsiblePartyInformationComplete'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['registeredAgentInformationComplete'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['registeredState'].setValidators([Validators.required]);
    this.checklistForm.controls['directorInformationComplete'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['firstLeadInformationComplete'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['secondLeadInformationComplete'].setValidators([Validators.requiredTrue]);
    this.checklistForm.controls['instructorNames'].setValidators([Validators.required]);
    this.checklistForm.controls['responsiblePartyAttestationsComplete'].setValidators([Validators.requiredTrue]);


    this.checklistForm.patchValue({
      licenseName: this.license.programName,
      licenseId: this.license.programId,
      teamMember: this.checklist.teamMember,
      reviewNotes: this.checklist.reviewNotes,

      applicantInfoComplete: this.checklist.applicantInfoComplete,
      responsiblePartyInformationComplete: this.checklist.responsiblePartyInformationComplete,
      registeredAgentInformationComplete: this.checklist.registeredAgentInformationComplete,
      registeredState: this.checklist.registeredState,
      directorInformationComplete: this.checklist.directorInformationComplete,
      firstLeadInformationComplete: this.checklist.firstLeadInformationComplete,
      secondLeadInformationComplete: this.checklist.secondLeadInformationComplete,
      instructorNames: this.checklist.instructorNames,
      responsiblePartyAttestationsComplete: this.checklist.responsiblePartyAttestationsComplete,
      submitForClosingNotes: this.checklist.submitForClosingNotes,
      submitForDenialNotes: this.checklist.submitForDenialNotes,
      peerReviewComplete: this.checklist.peerReviewComplete,
      peerReviewMember: this.checklist.peerReviewMember,
    })

    if(this.checklist.reviewDate != null && this.checklist.reviewDate.length > 0)
      this.checklistForm.patchValue({
        reviewDate: new Date(this.checklist.reviewDate)
      });

    if(this.checklist.requirementsMetDate != null && this.checklist.requirementsMetDate.length > 0)
      this.checklistForm.patchValue({
        requirementsMetDate: new Date(this.checklist.requirementsMetDate)
      });

    if(this.checklist.peerReviewDate != null && this.checklist.peerReviewDate.length > 0)
      this.checklistForm.patchValue({
        peerReviewDate: new Date(this.checklist.peerReviewDate)
      });
  }

  save(): void {
    let form = this.checklistForm.value;
    this.checklist.licenseName = form.licenseName;
    this.checklist.licenseId = form.licenseId;
    this.checklist.teamMember = form.teamMember;
    this.checklist.reviewDate = form.reviewDate;
    this.checklist.demographicAndContactInfoComplete = form.demographicAndContactInfoComplete;
    this.checklist.attestationsAndSignedComplete = form.attestationsAndSignedComplete;
    this.checklist.photoIdComplete = form.photoIdComplete;
    this.checklist.photoIdType = form.photoIdType;
    this.checklist.idNameMatchCompleted = form.idNameMatchCompleted;
    this.checklist.legalAgeComplete = form.legalAgeComplete;
    this.checklist.backgroundCheckPassed = form.backgroundCheckPassed;
    this.checklist.backgroundCheckDate = form.backgroundCheckDate;
    this.checklist.reviewNotes = form.reviewNotes;
    this.checklist.requirementsMetDate = form.requirementsMetDate;
    this.checklist.applicantInfoComplete = form.applicantInfoComplete;
    this.checklist.primaryContactIdentified = form.primaryContactIdentified;
    this.checklist.individualEntityApplicantInfoComplete = form.individualEntityApplicantInfoComplete;
    this.checklist.applicantInformationFormsReceived = form.applicantInformationFormsReceived;
    this.checklist.manufacturerEndorsements = form.manufacturerEndorsements;
    this.checklist.approvedLandUseCompatibility = form.approvedLandUseCompatibility;
    this.checklist.propertyOwnerStatement = form.propertyOwnerStatement;
    this.checklist.propertyOwnerStatementNA = form.propertyOwnerStatementNA;
    this.checklist.premisesPlanMeetsRequirements = form.premisesPlanMeetsRequirements;
    this.checklist.socialEquityPlanMeetsRequirements = form.socialEquityPlanMeetsRequirements;
    this.checklist.socialEquityPlanEvaluationMeetsRequirements = form.socialEquityPlanEvaluationMeetsRequirements;
    this.checklist.reducedLicensingFeeDocumentation = form.reducedLicensingFeeDocumentation;
    this.checklist.reducedLicensingFeeDocumentationNA = form.reducedLicensingFeeDocumentationNA;
    this.checklist.nonProfitEntity = form.nonProfitEntity;
    this.checklist.socialSecurityIncomeBenefitCompleted = form.socialSecurityIncomeBenefitCompleted;
    this.checklist.applicantsCurrentEligibilityStatement = form.applicantsCurrentEligibilityStatement;
    this.checklist.foodStampBenefits = form.foodStampBenefits;
    this.checklist.servedInArmedForces = form.servedInArmedForces;
    this.checklist.orelapCertificateValid = form.orelapCertificateValid;
    this.checklist.labListedOnline = form.labListedOnline;
    this.checklist.notSubjectToHealthCareFacility = form.notSubjectToHealthCareFacility;
    this.checklist.dateNotSubjectToHealthCareFacility = form.dateNotSubjectToHealthCareFacility;
    this.checklist.completedSchoolProximityAttestations = form.completedSchoolProximityAttestations;
    this.checklist.meetsSchoolProximityRequirements = form.meetsSchoolProximityRequirements;
    this.checklist.gisLiasonConfirmed = form.gisLiasonConfirmed;
    this.checklist.completedPracticumSiteAttestations = form.completedPracticumSiteAttestations;
    this.checklist.indicatedServesAsPracticumSite = form.indicatedServesAsPracticumSite;
    this.checklist.indicatedServesAsPracticumSiteNA = form.indicatedServesAsPracticumSiteNA;
    this.checklist.passedExam = form.passedExam;
    this.checklist.trainingProgramCertificate = form.trainingProgramCertificate;
    this.checklist.submitForClosingNotes = form.submitForClosingNotes;
    this.checklist.submitForDenialNotes = form.submitForDenialNotes;
    this.checklist.responsiblePartyInformationComplete = form.responsiblePartyInformationComplete;
    this.checklist.registeredAgentInformationComplete = form.registeredAgentInformationComplete;
    this.checklist.directorInformationComplete = form.directorInformationComplete;
    this.checklist.firstLeadInformationComplete = form.firstLeadInformationComplete;
    this.checklist.secondLeadInformationComplete = form.secondLeadInformationComplete;
    this.checklist.responsiblePartyAttestationsComplete = form.responsiblePartyAttestationsComplete;
    this.checklist.registeredState = form.registeredState;
    this.checklist.instructorNames = form.instructorNames;
    this.checklist.workerPermitSubmitted = form.workerPermitSubmitted;
    this.checklist.peerReviewComplete = form.peerReviewComplete;
    this.checklist.peerReviewMember = form.peerReviewMember;
    this.checklist.peerReviewDate = form.peerReviewDate;

    this.licenseChecklistService.updateLicenseChecklist(this.license.id, this.entityType, this.checklist).subscribe(
      response => {
        this.checklist = response;
        this.updateForm();
        this.notifyParent.emit(this.checklist);
      },
      error => console.log('error', error)
      );
  }
}
