import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ToastrService } from 'ngx-toastr';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IComplaint } from 'app/models/complaints/complaint';
import { ICompliance } from 'app/models/compliance/compliance';
import { IRule } from 'app/models/compliance/rule';
import { IComplianceRule } from 'app/models/compliance/compliance-rule';
import { INote } from 'app/models/notes/note';
import { ComplianceService } from 'app/services/compliance/compliance.service';
import { SharedService } from 'app/services/core/shared.service';
import { IStaff } from 'app/models/user-info/staff';
import { ComplaintDialogComponent } from '../complaint/complaint.component';
import { IComplaintDialogData } from 'app/models/complaints/complaint-dialog-data';
import { ComplaintService } from 'app/services/complaints/complaint.service';
import { AuthService } from "app/auth/auth.service";
import { ICategory } from 'app/models/compliance/look-ups/category';
import { DialogEditNoteComponent } from '../notes/edit/edit-note-dialog';
import { ViewRuleDialogComponent } from '../manage-compliance/manage-rules/manage-rules.component';
import { IComplianceAction } from 'app/models/compliance/compliance-action';
import { IActionItem } from 'app/models/compliance/look-ups/action-item';
import { map, startWith } from 'rxjs/operators';
import { InvestigationComponent } from '../investigation/investigation.component';
import { CompliancePlanComponent } from '../compliance-plan/compliance-plan.component';
import { IRelatedCase } from 'app/models/compliance/relatedCase';
import { INotice } from 'app/models/compliance/notice';
import { ComplaintIdValidator } from '@shared/validators/complaint-id-validator';
import { IReferencedLicense } from 'app/models/compliance/referenced-license';
import { IDocument } from 'app/models/documents/document';
import { ComplianceAuditLogDialogComponent } from '../audit-log/compliance-audit-log/compliance-audit-log.component';


export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-compliance',
  templateUrl: './compliance.component.html',
  styleUrls: ['./compliance.component.scss'],
  animations: [
    trigger('slideLeft', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ]),
    trigger('slideRight', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(100%)'}))
      ])
    ])
  ]
})

export class ComplianceComponent implements OnInit {
  @ViewChild(InvestigationComponent) investigations: InvestigationComponent;
  @ViewChild(CompliancePlanComponent) compliancePlans: CompliancePlanComponent;
  @Input() compliance: ICompliance = {
    id: 0,
    caseId: '',
    entityId: 0,
    entityType: '',
    status: '',
    category: '',
    tier: '',
    dueDate: '',
    closedDate: '',
    type: '',
    sent: null,
    assignedTo: '',
    manager: '',
    outcome: '',
    agencyGenerated: false,
    locked: false,
    dateOfNonCompliance: '',
    dateOfCompliance: '',
    originalLicenseId: '',
    complianceRules: [],
    complaints: [],
    notes: [],
    complianceActions: [],
    investigations: [],
    compliancePlans: [],
    relatedCases: [],
    licenseId: '',
    notices: [],
    referencedLicenses: [],
    complianceDocuments: []
  }
  @Input() entityId: number = 0;
  @Input() entityReference: string = '';
  @Input() entityType: string = '';
  @Input() entityName: string = '';
  @Input() staff: IStaff[] = [];
  @Input() notes: INote[] = [];
  @Input() renewal: boolean = false;
  @Input() licenseId: string = '';

  public expired: boolean = false;
  public today: Date = new Date(Date.now());
  public loading: boolean = true;
  public editing: boolean = false;
  public viewingInvestigation: boolean = false;
  public viewingCompliancePlan: boolean = false;
  public managers: IStaff[] = [];
  public categories: ICategory[] = [];
  public filteredCategories: Observable<ICategory[]>;
  public complianceCases: ICompliance[] = [];
  public expiredCases: ICompliance[] = [];
  public complianceCase: ICompliance =
  {
    id: 0,
    caseId: '',
    entityId: 0,
    entityType: '',
    status: 'Submitted',
    category: '',
    tier: '',
    dueDate: '',
    closedDate: '',
    type: '',
    sent: null,
    assignedTo: '',
    manager: '',
    outcome: '',
    agencyGenerated: false,
    locked: false,
    dateOfNonCompliance: '',
    dateOfCompliance: '',
    originalLicenseId: '',
    complianceRules: [],
    complaints: [],
    notes: [],
    complianceActions: [],
    investigations: [],
    compliancePlans: [],
    relatedCases: [],
    licenseId: '',
    notices: [],
    referencedLicenses: [],
    complianceDocuments: []
  };
  public complaintReferences: IComplaint[] = [];
  public rules: IRule[] = [];
  public staffReference: {[id: string]: string} = {};

  public complianceForm = new UntypedFormGroup({
    status: new UntypedFormControl(""),
    category: new UntypedFormControl(""),
    tier: new UntypedFormControl(""),
    dueDate: new UntypedFormControl(""),
    type: new UntypedFormControl(""),
    sent: new UntypedFormControl(null),
    assignedTo: new UntypedFormControl(""),
    manager: new UntypedFormControl(""),
    outcome: new UntypedFormControl(""),
    agencyGenerated: new UntypedFormControl(false),
    dateOfNonCompliance: new UntypedFormControl(""),
    dateOfCompliance: new UntypedFormControl("")
  });
  public isAdmin: boolean = false;
  public complianceColumns: string[] = ['caseId', 'status', 'category', 'tier', 'assignedTo', 'manager', 'outcome'];
  public complianceSource = new MatTableDataSource<ICompliance>(this.complianceCases);
  @ViewChild('compliancePaginator', {static: false}) compliancePaginator: MatPaginator;
  @ViewChild('complianceSort', {static: false}) complianceSort: MatSort;

  public expiredComplianceColumns: string[] = ['caseId', 'status', 'category', 'tier', 'assignedTo', 'manager', 'outcome'];
  public expiredComplianceSource = new MatTableDataSource<ICompliance>(this.expiredCases);
  @ViewChild('expiredCompliancePaginator', {static: false}) expiredCompliancePaginator: MatPaginator;
  @ViewChild('expiredComplianceSort', {static: false}) expiredComplianceSort: MatSort;

  public complaintColumns: string[] = ['dateCreated', 'status', 'complaintText', 'actions'];
  public complaintSource = new MatTableDataSource<IComplaint>(this.complianceCase.complaints);
  @ViewChild('complaintPaginator', {static: false}) complaintPaginator: MatPaginator;
  @ViewChild('complaintSort', {static: false}) complaintSort: MatSort;

  public ruleRefColumns: string[] = ['statute', 'summary', 'notes', 'resolvedDate', 'actions'];
  public ruleRefSource = new MatTableDataSource<IComplianceRule>(this.complianceCase.complianceRules);
  @ViewChild('ruleRefPaginator', {static: false}) ruleRefPaginator: MatPaginator;
  @ViewChild('ruleRefSort', {static: false}) ruleRefSort: MatSort;

  public complianceActionColumns: string[] = ['action', 'dateCreated', 'startDate', 'endDate', 'timeSpan'];
  public complianceActionSource = new MatTableDataSource<IComplianceAction>(this.complianceCase.complianceActions);
  @ViewChild('complianceActionPaginator', {static: false}) complianceActionPaginator: MatPaginator;
  @ViewChild('complianceActionSort', {static: false}) complianceActionSort: MatSort;

  public relatedColumns: string[] = ['caseId', 'licenseId', 'status', 'category', 'tier', 'actions'];
  public relatedSource = new MatTableDataSource<IRelatedCase>(this.complianceCase.relatedCases);
  @ViewChild('relatedPaginator', {static: false}) relatedPaginator: MatPaginator;
  @ViewChild('relatedSort', {static: false}) relatedSort: MatSort;

  public associatedColumns: string[] = ['licenseId', 'actions'];
  public associatedSource = new MatTableDataSource<IReferencedLicense>(this.complianceCase.referencedLicenses);
  @ViewChild('associatedPaginator', {static: false}) associatedPaginator: MatPaginator;
  @ViewChild('associatedSort', {static: false}) associatedSort: MatSort;

  public complianceDocumentColumns: string[] = ['name', 'dateCreated', 'actions'];
  public complianceDocumentSource = new MatTableDataSource<IDocument>(this.complianceCase.complianceDocuments);
  @ViewChild('complianceDocumentPaginator', {static: false}) complianceDocumentPaginator: MatPaginator;
  @ViewChild('complianceDocumentSort', {static: false}) complianceDocumentSort: MatSort;

  public noticeColumns: string[] = ['type', 'dueDate', 'sent', 'actions'];
  public noticeSource = new MatTableDataSource<INotice>(this.complianceCase.notices);
  @ViewChild('noticePaginator', {static: false}) noticePaginator: MatPaginator;
  @ViewChild('noticeSort', {static: false}) noticeSort: MatSort;

  public notesColumns: string[] = ['text'];
  public notesSource = new MatTableDataSource<INote>(this.complianceCase.notes);

  constructor(public sharedService: SharedService,
              public complianceService: ComplianceService,
              private complaintService: ComplaintService,
              public authService: AuthService,
              private dialog: MatDialog,
              public toastr: ToastrService) { }

  ngOnInit(): void {
    if(this.compliance.id > 0){
      this.complianceCase = this.compliance;
      this.updateComplaintTable();
      this.updateComplianceDocumentTable();
    }
    else{
      this.complianceCase = this.getEmptyCompliance();
    }
    if(this.renewal)
      this.complianceService.getExpiredComplianceCases(this.entityId, this.licenseId, this.entityType, this.authService.isAdmin).subscribe(
        response => this.expiredCases = response,
        error => console.log('error', error),
        () => {
          this.updateExpiredComplianceTable();
        }
      );

    this.getManagers();
    this.getRules();
    this.getComplianceCases();
    this.getComplaints();
    this.setupStaffReference();
    this.getCategories();
    this.checkAdmin();
  }

  checkAdmin(): void {
    if(this.authService.isAdmin)
      this.isAdmin = true;
    if(this.entityType == this.sharedService.trainingProgram)
      if(this.authService.isTrainingAdmin)
        this.isAdmin = true;
    if(this.entityType == this.sharedService.facilitator)
      if(this.authService.isFacilitatorAdmin)
        this.isAdmin = true;
    if(this.entityType == this.sharedService.serviceCenter)
      if(this.authService.isServiceCenterAdmin)
        this.isAdmin = true;
    if(this.entityType == this.sharedService.workerPermit)
      if(this.authService.isWorkerPermitAdmin)
        this.isAdmin = true;
    if(this.entityType == this.sharedService.manufacturer)
      if(this.authService.isManufacturerAdmin)
        this.isAdmin = true;
    if(this.entityType == this.sharedService.testingLab)
      if(this.authService.isTestingLabAdmin)
        this.isAdmin = true;
    }

  private getEmptyCompliance(): ICompliance {
    let compliance: ICompliance = {
      id: 0,
      caseId: '',
      entityId: this.entityId,
      entityType: this.entityType,
      status: 'Submitted',
      category: '',
      tier: '',
      dueDate: '',
      closedDate: '',
      type: '',
      sent: null,
      assignedTo: '',
      manager: '',
      outcome: '',
      agencyGenerated: false,
      locked: false,
      dateOfNonCompliance: '',
      dateOfCompliance: '',
      originalLicenseId: '',
      complianceRules: [],
      complaints: [],
      notes: [],
      complianceActions: [],
      investigations: [],
      compliancePlans: [],
      relatedCases: [],
      licenseId: '',
      notices: [],
      referencedLicenses: [],
      complianceDocuments: []
    }
    return compliance;
  }

  private getManagers(): void {
    this.complianceService.getAdmins().subscribe(
      response => this.managers = response,
      error => console.log('error', error)
    )
  }

  private getRules(): void {
    this.complianceService.getRules(this.entityType).subscribe(
      response => this.rules = response,
      error => console.log('error', error)
    );
  }

  private getComplianceCases(): void {
    this.complianceService.getComplianceCases(this.entityId, this.entityType, this.authService.isAdmin).subscribe(
      response => this.complianceCases = response,
      error => console.log('error', error),
      () => {
        this.loading = false;
        this.updateComplianceTable();
      }
    );
  }

  openChangeLog() {
    this.dialog.open(ComplianceAuditLogDialogComponent, {
      data: { entityId: this.complianceCase.id.toString()},
      minWidth: !this.sharedService.mobile? '700px' : '300px',
      maxWidth: !this.sharedService.mobile? '800px' : '300px',
      maxHeight: !this.sharedService.mobile? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
  }

  private updateComplianceTable(): void {
    this.complianceSource.data = this.complianceCases;
    this.complianceSource.paginator = this.compliancePaginator;
    this.complianceSource.sort = this.complianceSort;
  }

  private updateExpiredComplianceTable(): void {
    this.expiredComplianceSource.data = this.expiredCases;
    this.expiredComplianceSource.paginator = this.expiredCompliancePaginator;
    this.expiredComplianceSource.sort = this.expiredComplianceSort;
  }

  private updateRelatedTable(): void {
    this.relatedSource.data = this.complianceCase.relatedCases;
    this.relatedSource.paginator = this.relatedPaginator;
    this.relatedSource.sort = this.relatedSort;

    this.relatedSource.sortingDataAccessor = (item: IRelatedCase, property: string) => {
      switch (property) {
        case 'caseId': return item.childCase.caseId;
        case 'licenseId': return item.childCase.licenseId;
        case 'status': return item.childCase.status;
        case 'category': return item.childCase.category;
        case 'tier': return item.childCase.tier;
        default: return item[property];
      }
    };
  }

  private updateAssociatedTable(): void {
    this.associatedSource.data = this.complianceCase.referencedLicenses;
    this.associatedSource.paginator = this.associatedPaginator;
    this.associatedSource.sort = this.associatedSort;

    this.associatedSource.sortingDataAccessor = (item: IReferencedLicense, property: string) => {
      switch (property) {
        case 'licenseId': return item.licenseId;
        default: return item[property];
      }
    };
  }

  private updateNoticeTable(): void {
    this.noticeSource.data = this.complianceCase.notices;
    this.noticeSource.paginator = this.noticePaginator;
    this.noticeSource.sort = this.noticeSort;
  }

  public noticeCompleted(): boolean {
    if (this.complianceCase.dueDate != null && this.complianceCase.dueDate.length > 0 && this.complianceCase.type != '' && this.complianceCase.sent != null)
      return true;
    return false;
  }

  archiveNotice(): void {
    this.sharedService.openConfirm('Archive this notice?');
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          let notice: INotice = {
            id: 0,
            dueDate: this.complianceCase.dueDate,
            type: this.complianceCase.type,
            sent: this.complianceCase.sent
          };

          this.complianceService.createNotice(notice, this.complianceCase.id).subscribe({
            next: response => {
              if(response != null)
              {
                this.complianceCase.notices.push(response);
                this.complianceCase.dueDate = '';
                this.complianceCase.type = '';
                this.complianceCase.sent = null;
                this.complianceCases.find(cc => cc.id == this.complianceCase.id).notices.push(response);
                this.updateComplianceTable();
                this.updateNoticeTable();
                this.complianceForm.patchValue({
                  dueDate: '',
                  sent: null,
                  type: ''
                });
                this.toastr.success('Notice Archived');
              }
              if(response == null)
                this.toastr.error('Error Archiving Notice');
            },
            error: e => {
              this.toastr.error('Error Archiving Notice');
            }
          });
        }
      }
    );
  }

  public deleteNotice(noticeId: number): void {
    this.sharedService.openConfirm('Delete this notice?');
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.complianceService.deleteNotice(noticeId).subscribe({
            next: response => {
              if(response)
              {
                this.complianceCase.notices = this.complianceCase.notices.filter(notice => notice.id !== noticeId);
                this.complianceCases.find(cc => cc.id == this.complianceCase.id).notices = this.complianceCase.notices.filter(notice => notice.id !== noticeId);
                this.updateComplianceTable();
                this.updateNoticeTable();
                this.toastr.success('Notice Deleted');
              }
              if(response == null)
                this.toastr.error('Error Deleting Notice');
            },
            error: e => {
              this.toastr.error('Error Deleting Notice');
            }
          });
        }
      }
    );
  }

  public removeRelatedCase(relatedCase: IRelatedCase): void {
    this.sharedService.openConfirm("Remove related case?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
         if(confirmed){
          this.complianceService.removeRelatedCase(this.complianceCase.id, relatedCase.childCaseId).subscribe({
            next: response => {
              if(response) {
                this.complianceCase.relatedCases = this.complianceCase.relatedCases.filter(rc => rc.childCaseId !== relatedCase.childCaseId);
                this.toastr.success("Related case removed");
                this.updateRelatedTable();
              }
            },
            error: e => {
              this.toastr.error("Error removing related case");
              console.log('error', e)
            }
          });
         }
      });
  }

  private getComplaints(): void {
    this.complaintService.getComplaints(this.entityReference).subscribe(
      response => this.complaintReferences = response,
      error => console.log('error', error)
    );
  }

  private setupStaffReference(): void {
    this.staff.forEach(staff => {
      this.staffReference[staff.id] = staff.name;
    });
  }

  private getCategories(): void {
    this.complianceService.getLookUps(false, this.sharedService.category).subscribe(
      response => this.categories = response,
      error => console.log('error', error),
      () => {
        this.filteredCategories = this.complianceForm.get("category").valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value || ''))
        );
      }
    );
  }

  private _filter(value: string): IActionItem[] {
    const filterValue = value.toLowerCase();
    return this.categories.filter(item => item.value.toLowerCase().includes(filterValue));
  }

  public addComplianceCase(): void {
    this.sharedService.openConfirm('Open a new Case?');
    this.sharedService.confirmed().subscribe(confirmed => {
      if(confirmed){
        this.complianceService.createComplianceCase(this.complianceCase).subscribe(
          response => this.complianceCase = response,
          error => console.log('error', error),
          () => {
            this.complianceCases.push(this.complianceCase);
            this.updateComplianceForm();
            this.updateRuleTable();
            this.updateComplaintTable();
            this.updateComplianceDocumentTable();
            this.updateRelatedTable();
            this.updateAssociatedTable();
            this.updateActionsTable();
            this.updateNoticeTable();
            this.notesSource.data = this.complianceCase.notes;
          }
        );
      }
    });
  }

  public viewComplianceCase(compliance: ICompliance, expired: boolean): void {
      this.expired = expired;
      this.complianceCase = compliance;
      if (compliance.referencedLicenses.length > 0) {
        this.complianceCase.referencedLicenses.push({id: 0, caseId: compliance.id, licenseId: compliance.originalLicenseId});
      }
      setTimeout(() => {
        this.updateComplianceForm();
        this.updateRuleTable();
        this.updateComplaintTable();
        this.updateComplianceDocumentTable();
        this.updateRelatedTable();
        this.updateAssociatedTable();
        this.updateActionsTable();
        this.updateNoticeTable();
        this.notesSource.data = compliance.notes;
      }, 50);
  }

  public viewRelatedCase(compliance: ICompliance): void {
      this.complianceService.getComplianceCase(compliance.id).subscribe(
        response => {
          this.complianceCase = response;
          this.updateComplianceForm();
          this.updateRuleTable();
          this.updateComplaintTable();
          this.updateComplianceDocumentTable();
          this.updateRelatedTable();
          this.updateActionsTable();
          this.updateNoticeTable();
          this.notesSource.data = this.complianceCase.notes;
          this.toastr.success("Related Case Loaded");
        },
        error => console.log('error', error)
      );
  }

  private updateComplianceForm(): void {
    this.complianceForm.patchValue({
      status: this.complianceCase.status,
      category: this.complianceCase.category,
      tier: this.complianceCase.tier,
      type: this.complianceCase.type,
      sent: this.complianceCase.sent,
      assignedTo: this.complianceCase.assignedTo,
      manager: this.complianceCase.manager,
      outcome: this.complianceCase.outcome,
      agencyGenerated: this.complianceCase.agencyGenerated
    });
    if (this.complianceCase.dateOfNonCompliance != null && this.complianceCase.dateOfNonCompliance.length > 0)
    {
      this.complianceForm.get('dateOfNonCompliance').patchValue(new Date(this.complianceCase.dateOfNonCompliance));
    }
    if (this.complianceCase.dateOfCompliance != null && this.complianceCase.dateOfCompliance.length > 0)
    {
      this.complianceForm.get('dateOfCompliance').patchValue(new Date(this.complianceCase.dateOfCompliance));
    }
    if (this.complianceCase.dueDate != null && this.complianceCase.dueDate.length > 0)
    {
      this.complianceForm.get('dueDate').patchValue(new Date(this.complianceCase.dueDate));
    }
  }

  private updateRuleTable(): void {
      this.ruleRefSource.data = this.complianceCase.complianceRules;
      this.ruleRefSource.paginator = this.ruleRefPaginator;
      this.ruleRefSource.sort = this.ruleRefSort;
  }

  private updateActionsTable(): void {
    this.complianceActionSource.data = this.complianceCase.complianceActions;
    this.complianceActionSource.sort = this.complianceActionSort;
    this.complianceActionSource.paginator = this.complianceActionPaginator;
  }

  public viewRuleDetails(rule: IRule): void {
    this.dialog.open(ViewRuleDialogComponent, {
      data: rule,
      minWidth: !this.sharedService.mobile? '700px' : '300px',
      maxWidth: !this.sharedService.mobile? '' : '300px',
      maxHeight: !this.sharedService.mobile? '' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
  }

  private updateComplaintTable(): void {
    this.complaintSource.data = this.complianceCase.complaints;
    this.complaintSource.sort = this.complaintSort;
    this.complaintSource.paginator = this.complaintPaginator;
  }

  private updateComplianceDocumentTable(): void {
    this.complianceDocumentSource.data = this.complianceCase.complianceDocuments;
    this.complianceDocumentSource.sort = this.complianceDocumentSort;
    this.complianceDocumentSource.paginator = this.complianceDocumentPaginator;
  }

  deleteComplianceDocument(id: number, name: string) {
    this.sharedService.openConfirm("Delete " + name + "?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.complianceService.deleteComplianceDocument(id).subscribe(
            () => {
              this.complianceCase.complianceDocuments = this.complianceCase.complianceDocuments.filter(item => item.id !== id);
              this.updateComplianceDocumentTable();
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  downloadComplianceDocument(fileId: number, fileName: string): void {
    this.complianceService.downloadComplianceDocument(fileId).subscribe(
      (response) => this.saveFile(fileName, response),
      (error) => console.log("error", error)
    );
  }

  saveFile(fileName: string, blob: Blob) {
    let file = URL.createObjectURL(blob);
    var fileDownload = document.createElement("a");
    fileDownload.href = file;
    fileDownload.download = fileName;
    fileDownload.click();
  }

  uploadComplianceDocument(event: Event): void {
    let dirtyFile = (event.target as HTMLInputElement).files[0];
    let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, ''));
    let upload: IDocument = {
      id: 0,
      name: file.name,
      comments: "",
      extension: "",
      dateCreated: "",
      dateLastUpdated: "",
      createdBy: "",
      lastUpdatedBy: "",
      parentId: this.complianceCase.id,
      pendingUpdate: false,
      deprecated: false,
      types: [],
      adminOnly: false
    }

    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("document", JSON.stringify(upload));
    this.complianceService.uploadComplianceDocument(formData).subscribe(
      response => this.complianceCase.complianceDocuments.push(response),
      error => {
        (event.target as HTMLInputElement).value = '';
        console.log('error', error);
      },
      () => {
        (event.target as HTMLInputElement).value = '';
        this.updateComplianceDocumentTable();
      });
  }


  public editCompliance(): void {
    this.investigations.editing = !this.editing;
    this.compliancePlans.editing = !this.editing;
    this.editing = !this.editing;
    this.updateComplianceTable();
  }

  public updateRuleReference(rule: IComplianceRule): void {
    const dialogRef = this.dialog.open(ResolveRuleDialogComponent,
      {
        data: rule,
        minWidth: !this.sharedService.mobile ? '500px' : '300px',
        minHeight: '150px',
        maxWidth: !this.sharedService.mobile ? '' : '300px',
        maxHeight: !this.sharedService.mobile ? '' : '500px',
        panelClass: this.sharedService.darkMode ? "theme-dark" : ""
      });

    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        rule = response;
        this.complianceService.updateCompliance(this.complianceCase).subscribe(
          response => this.complianceCase = response,
          error => console.log('error', error),
          () => {
            this.updateRuleTable();
          }
        );
      }
    });
  }

  public removeComplianceRule(rule: IComplianceRule): void {
    this.sharedService.openConfirm("Remove this rule reference?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.complianceService.removeComplianceRule(rule.id).subscribe(
            () => {
              this.complianceCase.complianceRules = this.complianceCase.complianceRules.filter(cr => cr.id !== rule.id);
              this.updateRuleTable();
            },
            error => console.log('error', error)
          );
        }
      }
    )
  }

  public updateCompliance(close: boolean): void {
    let form = this.complianceForm.value;
    this.complianceCase.status = form.status;
    this.complianceCase.category = form.category,
    this.complianceCase.tier = form.tier,
    this.complianceCase.dueDate = form.dueDate,
    this.complianceCase.type = form.type,
    this.complianceCase.sent = form.sent,
    this.complianceCase.assignedTo = form.assignedTo,
    this.complianceCase.manager = form.manager,
    this.complianceCase.outcome = form.outcome,
    this.complianceCase.agencyGenerated = form.agencyGenerated,
    this.complianceCase.dateOfNonCompliance = form.dateOfNonCompliance,
    this.complianceCase.dateOfCompliance = form.dateOfCompliance,
    this.complianceService.updateCompliance(this.complianceCase).subscribe(
      response => this.complianceCase = response,
      error => console.log('error', error),
      () => {
        this.updateRuleTable();
        if(close){
          let index = this.complianceCases.findIndex(cc => cc.id == this.complianceCase.id);
          this.complianceCases[index] = this.complianceCase;
          this.viewComplianceCases();
        }
      }
    );
  }

  public unlockCompliance(): void {
    this.sharedService.openConfirm("Unlock this case?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.complianceService.lockCompliance(this.complianceCase.id, false).subscribe(
            () => this.complianceCase.locked = false,
            error => console.log('error', error)
          )
        }
      }
    )
  }

  public lockCompliance(): void {
    this.sharedService.openConfirm("Lock this case? If you are not a manager you will lose access to this case.");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.complianceService.lockCompliance(this.complianceCase.id, true).subscribe(
            () => {
              if(!this.authService.isAdmin){
                this.complianceCase = this.getEmptyCompliance();
                this.complianceCases = this.complianceCases.filter(cc => cc.id !== this.complianceCase.id);
                this.updateComplianceTable();
              }
              else{
                this.complianceCase.locked = true;
              }
            },
            error => console.log('error', error)
          )
        }
      }
    )
  }

  public addComplianceRules(): void {
    let currentRules: number[] = this.complianceCase.complianceRules.map((rule: IComplianceRule) => { return rule.rule.id });
    let newRules: IRule[] = this.rules.filter(rule => !currentRules.includes(rule.id));
    const dialogRef = this.dialog.open(AddRuleReferenceDialogComponent, {
      data: {
        rules: newRules,
        complianceCaseId: this.complianceCase.id
      },
      minWidth: !this.sharedService.mobile ? '700px' : '300px',
      maxWidth: !this.sharedService.mobile ? '' : '300px',
      maxHeight: !this.sharedService.mobile ? '' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        response.forEach((rule: IRule) => {
          let complianceRule: IComplianceRule = {
            id: 0,
            notes: '',
            resolvedDate: '',
            complianceId: this.complianceCase.id,
            rule: rule
          }
          this.complianceCase.complianceRules.push(complianceRule);
        });
        this.complianceService.updateCompliance(this.complianceCase).subscribe(
          response => this.complianceCase = response,
          error => console.log('error', error),
          () => {
            this.updateRuleTable();
          }
        );
      }
    });
  }

  public viewComplianceCases(): void {
    this.expired = false;
    this.editing = false;
    this.complianceCase = this.getEmptyCompliance();
    this.updateComplianceTable();
    this.updateRuleTable();
    this.updateComplianceForm();
  }

  public filterTable(event: Event, table: string): void {
    let value = (event.target as HTMLInputElement).value;
    let filter = value.trim().toLocaleLowerCase();
    switch(table){
      case 'compliance':
        this.complianceSource.filter = filter;
        break;
      case 'expiredCompliance':
        this.expiredComplianceSource.filter = filter;
        break;
      case 'complaint':
        this.complaintSource.filter = filter;
        break;
      case 'document':
        this.complianceDocumentSource.filter = filter;
        break;
      case 'rule':
        this.ruleRefSource.filter = filter;
        break;
      case 'related':
        this.relatedSource.filter = filter;
        break;
      case 'associated':
        this.associatedSource.filter = filter;
        break;
      case 'notes':
        this.notesSource.filter = filter;
      case 'action':
        this.complianceActionSource.filter = filter;
      case 'notices':
        this.noticeSource.filter = filter;
      default:
        break;
    }
  }

  public addComplaintReferences(): void {
    let currentComplaintIds: number[] = this.complianceCase.complaints.map(c => { return c.id });
    let newComplaints: IComplaint[] = this.complaintReferences.filter(cr => !currentComplaintIds.includes(cr.id));

    const dialogRef = this.dialog.open(AddComplaintReferenceDialogComponent, {
      data: newComplaints,
      minWidth: !this.sharedService.mobile? '700px' : '300px',
      maxWidth: !this.sharedService.mobile? '' : '300px',
      maxHeight: !this.sharedService.mobile? '' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        response.forEach((complaint: IComplaint) => {
          this.complianceCase.complaints.push(complaint);
        });
        this.complianceService.updateCompliance(this.complianceCase).subscribe(
          response => this.complianceCase = response,
          error => console.log('error', error),
          () => {
            this.updateComplaintTable();
          }
        );
      }
    });
  }

  public addNewComplaint(): void{
    let complaint: IComplaint = {
      id: 0,
      entityType: '',
      status: 'Submitted',
      name: '',
      contactEmail: '',
      contactNumber: '',
      preferredContact: '',
      identityConcern: '',
      parentId: this.entityReference,
      parentName: this.entityName,
      complaintText: '',
      previousReporting: '',
      signature: '',
      dateCreated: '',
      parentLicenseName: '',
      assignedTo: ''
    }

    let dialogData: IComplaintDialogData = {
      type: this.complianceCase.entityType,
      complaint: complaint,
      disableParentSelection: true
    }

    const dialogRef = this.dialog.open(ComplaintDialogComponent,
    {
      data: dialogData,
      minWidth: !this.sharedService.mobile? '400px' : '300px',
      maxWidth: !this.sharedService.mobile? '700px' : '300px',
      maxHeight: !this.sharedService.mobile? '' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        this.complaintReferences.push(response);
        this.complianceCase.complaints.push(response);
        this.complianceService.updateCompliance(this.complianceCase).subscribe(
          response => this.complianceCase = response,
          error => console.log('error', error),
          () => {
            this.updateComplaintTable();
            this.toastr.success("Added Complaint!");
          }
        );
      }
    });
  }

  public removeComplaint(complaint: IComplaint): void {
    this.sharedService.openConfirm("Remove the reference to this complaint?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if (confirmed) {
          this.complianceCase.complaints = this.complianceCase.complaints.filter(cc => cc.id !== complaint.id);
          this.complianceService.updateCompliance(this.complianceCase).subscribe(
            response => this.complianceCase = response,
            error => console.log('error', error),
            () => {
              this.updateComplaintTable();
              complaint.status = 'Submitted';
              this.complaintService.saveComplaint(complaint).subscribe(
                () => { },
                error => console.log('error', error)
              );
            }
          );
        }
      }
    );
  }

  public addNoteReference(): void {
    let currentNoteIds: number[] = this.complianceCase.notes.map(note => {return note.id});
    let newNotes: INote[] = this.notes.filter(note => !currentNoteIds.includes(note.id));

    const dialogRef = this.dialog.open(AddNoteReferenceDialogComponent, {
      data: newNotes,
      minWidth: !this.sharedService.mobile? '700px' : '300px',
      maxWidth: !this.sharedService.mobile? '' : '300px',
      maxHeight: !this.sharedService.mobile? '' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        response.forEach((note: INote) => {
          this.complianceCase.notes.push(note);
        });
        this.complianceService.updateCompliance(this.complianceCase).subscribe(
          response => this.complianceCase = response,
          error => console.log('error', error),
          () => {
            this.notesSource.data = this.complianceCase.notes;
          }
        );
      }
    });
  }

  public removeNoteReference(noteId: number): void {
    this.sharedService.openConfirm("Remove the reference to this note?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.complianceCase.notes = this.complianceCase.notes.filter(note => note.id !== noteId);
          this.complianceService.updateCompliance(this.complianceCase).subscribe(
            response => this.complianceCase = response,
            error => console.log('error', error),
            () => {
              this.notesSource.data = this.complianceCase.notes;
            }
          );
        }
      }
    )
  }

  public addNewNote(): void {
    let newNote: INote = {
      id: 0,
      archived: false,
      createdBy: '',
      dateCreated: '',
      dateLastUpdated: '',
      entityId: this.entityId.toString(),
      entityType: this.entityType,
      generic: false,
      lastUpdatedBy: '',
      text: ''
    }

    const dialogRef = this.dialog.open(DialogEditNoteComponent, {
      data: newNote,
      minWidth: !this.sharedService.mobile ? '700px' : '300px',
      maxWidth: !this.sharedService.mobile ? '800px' : '300px',
      maxHeight: !this.sharedService.mobile ? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        this.complianceCase.notes.push(response);
        this.complianceService.updateCompliance(this.complianceCase).subscribe(
          response => this.complianceCase = response,
          error => console.log('error', error),
          () => {
            this.notesSource.data = this.complianceCase.notes;
          }
        );
      }
    });
  }

  public editNote(note: INote): void{
    const dialogRef = this.dialog.open(DialogEditNoteComponent, {
      data: note,
      minWidth: !this.sharedService.mobile ? '700px' : '300px',
      maxWidth: !this.sharedService.mobile ? '800px' : '300px',
      maxHeight: !this.sharedService.mobile ? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        note = response;
      }
    });
  }

  public addActionItem(): void {
    let actionItem: IComplianceAction = {
      id: 0,
      dateCreated: '',
      startDate: '',
      endDate: '',
      timeSpan: 0,
      action: ''
    }

    this.editActionItem(actionItem);
  }

  public editActionItem(item: IComplianceAction): void {
    const dialogRef = this.dialog.open(AddComplianceActionDialogComponent, {
      data: item,
      minWidth: !this.sharedService.mobile ? '700px' : '300px',
      maxWidth: !this.sharedService.mobile ? '800px' : '300px',
      maxHeight: !this.sharedService.mobile ? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        this.complianceCase.complianceActions.push(response);
        this.complianceService.updateCompliance(this.complianceCase).subscribe(
          response => this.complianceCase = response,
          error => console.log('error', error),
          () => {
            this.updateActionsTable();
          }
        );
      }
    });
  }

  viewInvestigation(viewing: boolean): void {
    this.viewingInvestigation = viewing;
  }
  viewCompliancePlan(viewing: boolean): void {
    this.viewingCompliancePlan = viewing;
  }

  public addRelatedRule(): void {
    const dialogRef = this.dialog.open(AddRelatedRuleDialogComponent, {
      data: this.complianceCase,
      minWidth: !this.sharedService.mobile ? '700px' : '300px',
      maxWidth: !this.sharedService.mobile ? '800px' : '300px',
      maxHeight: !this.sharedService.mobile ? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.componentInstance.relatedCase.subscribe((result: ICompliance) => {
      let relatedCase: IRelatedCase = {
        id: 0,
        childCase: result,
        childCaseId: result.id,
        parentCaseId: 0,
        parentCase: null
      }
      this.complianceCase.relatedCases.push(relatedCase);
      this.updateRelatedTable();
    });
  }

  public addAssociatedLicense(): void {
    const dialogRef = this.dialog.open(AddAssociatedLicenseDialogComponent, {
      data: this.complianceCase,
      minWidth: !this.sharedService.mobile ? '500px' : '300px',
      maxWidth: !this.sharedService.mobile ? '500px' : '300px',
      maxHeight: !this.sharedService.mobile ? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });

    dialogRef.componentInstance.associatedLicense.subscribe((result: IReferencedLicense) => {
      let associatedLicense: IReferencedLicense = {
        id: result.id,
        caseId: result.caseId,
        licenseId: result.licenseId,
      }
      this.complianceCase.referencedLicenses.push(associatedLicense);
      this.updateAssociatedTable();
    });
  }

  public removeAssociatedLicense(associatedLicense: IReferencedLicense): void {
    this.sharedService.openConfirm("Remove Associated License?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
         if(confirmed){
          this.complianceService.unassociateLicense(associatedLicense.id).subscribe({
            next: response => {
              if(response) {
                this.complianceCase.referencedLicenses = this.complianceCase.referencedLicenses.filter(al => al.id !== associatedLicense.id);
                this.toastr.success("Associated License removed");
                this.updateAssociatedTable();
              }
            },
            error: e => {
              this.toastr.error("Error Removing Associated License");
              console.log('error', e)
            }
          });
         }
      });
  }
}

@Component({
  selector: 'add-rule-reference-dialog',
  styleUrls: ['./compliance.component.scss'],
  templateUrl: 'dialog-add-rule-reference.html'
})

export class AddRuleReferenceDialogComponent implements OnInit {
  public selectedRules: IRule[] = [];
  public ruleRefColumns: string[] = ['statute', 'summary', 'actions'];
  public ruleRefSource = new MatTableDataSource<IRule>(this.data.rules);
  public addingRule: boolean = false;
  public customRule: IRule = {
    id: 0,
    general: false,
    userCreated: false,
    trainingPrograms: false,
    facilitators: false,
    manufacturers: false,
    serviceCenters: false,
    testingLabs: false,
    workerPermits: false,
    subSection: '',
    segmentNumber: 0,
    segment: '',
    summary: '',
    application: '',
    questionServiceCenter: '',
    questionManufacturer: '',
    questionLaboratory: '',
    answer: '',
    guidanceServiceCenter: '',
    guidanceManufacturer: '',
    guidanceLaboratory: '',
    nonCompliant: '',
    statute: '',
    monitorMetric: false,
    notes: '',
    archived: false,
    historicNonCompliances: [],
    investigationItems: []
  };
  @ViewChild('ruleRefPaginator', {static: false}) ruleRefPaginator: MatPaginator;
  @ViewChild('ruleRefSort', {static: false}) ruleRefSort: MatSort;

  constructor(public dialogRef: MatDialogRef<AddRuleReferenceDialogComponent>,
              public sharedService: SharedService,
              public complianceService: ComplianceService,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.ruleRefSource.data = this.data.rules;
      this.ruleRefSource.sort = this.ruleRefSort;
      this.ruleRefSource.paginator = this.ruleRefPaginator;
    }, 30);
  }

  filterTable(event: Event): void{
    let value = (event.target as HTMLInputElement).value;
    let filter = value.trim().toLocaleLowerCase();
    this.ruleRefSource.filter = filter;
  }

  selectRule(rule: IRule) : void {
    if(!this.selectedRules.some(sr => sr.id === rule.id)){
      this.selectedRules.push(rule);
    }
  }

  removeRule(rule: IRule): void {
    this.selectedRules = this.selectedRules.filter(sr => sr !== rule);
  }

  addRules(): void {
    this.sharedService.openConfirm('Add selected rules?');
    this.sharedService.confirmed().subscribe(confirmed => {
      if(confirmed){
        this.dialogRef.close(this.selectedRules);
      }
    });
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }

  addRule(){
    this.sharedService.openConfirm("Add a manually entered rule?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.addingRule = true;
        }
      }
    );
  }

  saveNewRule(): void {
    this.complianceService.addRule(this.data.complianceCaseId, this.customRule).subscribe({
      next: response => {
        this.selectedRules.push(response);
        this.cancelNewRule();
      },
      error: e => {
        console.log('error', e)
      }
    });
  }

  cancelNewRule(): void {
    this.addingRule = false;
    this.customRule = {
      id: 0,
      general: false,
      userCreated: false,
      trainingPrograms: false,
      facilitators: false,
      manufacturers: false,
      serviceCenters: false,
      testingLabs: false,
      workerPermits: false,
      subSection: '',
      segmentNumber: 0,
      segment: '',
      summary: '',
      application: '',
      questionServiceCenter: '',
      questionManufacturer: '',
      questionLaboratory: '',
      answer: '',
      guidanceServiceCenter: '',
      guidanceManufacturer: '',
      guidanceLaboratory: '',
      nonCompliant: '',
      statute: '',
      monitorMetric: false,
      notes: '',
      archived: false,
      historicNonCompliances: [],
      investigationItems: []
    };
  }
}

@Component({
  selector: 'resolve-rule-dialog',
  templateUrl: 'dialog-resolve-rule.html',
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }]
})

export class ResolveRuleDialogComponent implements OnInit {
  public today: Date = new Date(Date.now());
  public dateForm = new UntypedFormGroup({
    notes: new UntypedFormControl(''),
    resolvedDate: new UntypedFormControl('')
  });

  public ruleForm = new UntypedFormGroup({
    general: new UntypedFormControl(false),
    trainingPrograms: new UntypedFormControl(false),
    facilitators: new UntypedFormControl(false),
    manufacturers: new UntypedFormControl(false),
    serviceCenters: new UntypedFormControl(false),
    testingLabs: new UntypedFormControl(false),
    workerPermits: new UntypedFormControl(false),
    subSection: new UntypedFormControl(''),
    segmentNumber: new UntypedFormControl(0),
    segment: new UntypedFormControl(''),
    summary: new UntypedFormControl(''),
    application: new UntypedFormControl(''),
    questionServiceCenter: new UntypedFormControl(''),
    questionManufacturer: new UntypedFormControl(''),
    questionLaboratory: new UntypedFormControl(''),
    answer: new UntypedFormControl(''),
    guidanceServiceCenter: new UntypedFormControl(''),
    guidanceManufacturer: new UntypedFormControl(''),
    guidanceLaboratory: new UntypedFormControl(''),
    nonCompliant: new UntypedFormControl(''),
    statute: new UntypedFormControl(''),
    monitorMetric: new UntypedFormControl(false)
  });

  constructor(public dialogRef: MatDialogRef<ResolveRuleDialogComponent>,
              public sharedService: SharedService,
              @Inject(MAT_DIALOG_DATA) public complianceRule: IComplianceRule) { }

  ngOnInit() : void {
    this.dateForm.patchValue({
      notes: this.complianceRule.notes,
      resolvedDate: this.complianceRule.resolvedDate !== null? new Date(this.complianceRule.resolvedDate) : null
    });
    if(this.complianceRule.rule.userCreated){
      this.ruleForm.patchValue({
        general: this.complianceRule.rule.general,
        trainingPrograms: this.complianceRule.rule.trainingPrograms,
        facilitators: this.complianceRule.rule.facilitators,
        manufacturers: this.complianceRule.rule.manufacturers,
        serviceCenters: this.complianceRule.rule.serviceCenters,
        testingLabs: this.complianceRule.rule.testingLabs,
        workerPermits: this.complianceRule.rule.workerPermits,
        subSection: this.complianceRule.rule.subSection,
        segmentNumber: this.complianceRule.rule.segmentNumber,
        segment: this.complianceRule.rule.segment,
        summary: this.complianceRule.rule.summary,
        application: this.complianceRule.rule.application,
        questionServiceCenter: this.complianceRule.rule.questionServiceCenter,
        questionManufacturer: this.complianceRule.rule.questionManufacturer,
        questionLaboratory: this.complianceRule.rule.questionLaboratory,
        answer: this.complianceRule.rule.answer,
        guidanceServiceCenter: this.complianceRule.rule.guidanceServiceCenter,
        guidanceManufacturer: this.complianceRule.rule.guidanceManufacturer,
        guidanceLaboratory: this.complianceRule.rule.guidanceLaboratory,
        nonCompliant: this.complianceRule.rule.nonCompliant,
        statute: this.complianceRule.rule.statute,
        monitorMetric: this.complianceRule.rule.monitorMetric
      });
    }
  }

  updateReference(): void {
    let form = this.dateForm.value;
    let ruleForm = this.ruleForm.value;
    this.complianceRule.notes = form.notes;
    this.complianceRule.resolvedDate = form.resolvedDate;
    if(this.complianceRule.rule.userCreated){
      this.complianceRule.rule.general = ruleForm.general,
      this.complianceRule.rule.trainingPrograms = ruleForm.trainingPrograms,
      this.complianceRule.rule.facilitators = ruleForm.facilitators,
      this.complianceRule.rule.manufacturers = ruleForm.manufacturers,
      this.complianceRule.rule.serviceCenters = ruleForm.serviceCenters,
      this.complianceRule.rule.testingLabs = ruleForm.testingLabs,
      this.complianceRule.rule.workerPermits = ruleForm.workerPermits,
      this.complianceRule.rule.subSection = ruleForm.subSection,
      this.complianceRule.rule.segmentNumber = ruleForm.segmentNumber,
      this.complianceRule.rule.segment = ruleForm.segment,
      this.complianceRule.rule.summary = ruleForm.summary,
      this.complianceRule.rule.application = ruleForm.application,
      this.complianceRule.rule.questionServiceCenter = ruleForm.questionServiceCenter,
      this.complianceRule.rule.questionManufacturer = ruleForm.questionManufacturer,
      this.complianceRule.rule.questionLaboratory = ruleForm.questionLaboratory,
      this.complianceRule.rule.answer = ruleForm.answer,
      this.complianceRule.rule.guidanceServiceCenter = ruleForm.guidanceServiceCenter,
      this.complianceRule.rule.guidanceManufacturer = ruleForm.guidanceManufacturer,
      this.complianceRule.rule.guidanceLaboratory = ruleForm.guidanceLaboratory,
      this.complianceRule.rule.nonCompliant = ruleForm.nonCompliant,
      this.complianceRule.rule.statute = ruleForm.statute,
      this.complianceRule.rule.monitorMetric = ruleForm.monitorMetric
    }
    this.dialogRef.close(this.complianceRule);
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }
}

@Component({
  selector: 'add-complaint-reference-dialog',
  styleUrls: ['./compliance.component.scss'],
  templateUrl: 'dialog-add-complaint-reference.html'
})

export class AddComplaintReferenceDialogComponent {
  public selectedComplaints: IComplaint[] = [];

  public complaintRefColumns: string[] = ['dateCreated', 'status', 'complaintText', 'actions'];
  public complaintRefSource = new MatTableDataSource<IComplaint>(this.complaints);
  @ViewChild('complaintRefPaginator', {static: false}) complaintRefPaginator: MatPaginator;
  @ViewChild('complaintRefSort', {static: false}) complaintRefSort: MatSort;

  constructor(public dialogRef: MatDialogRef<AddComplaintReferenceDialogComponent>,
              public sharedService: SharedService,
              public complaintService: ComplaintService,
              @Inject(MAT_DIALOG_DATA) public complaints: IComplaint[]) { }

  ngAfterViewInit(): void {
    this.complaintRefSource.data = this.complaints;
    this.complaintRefSource.sort = this.complaintRefSort;
    this.complaintRefSource.paginator = this.complaintRefPaginator;
  }

  addComplaint(complaint: IComplaint): void {
    if(!this.selectedComplaints.some(sc => sc.id === complaint.id)){
      this.selectedComplaints.push(complaint);
    }
  }

  deleteComplaint(complaint: IComplaint): void {
    this.selectedComplaints = this.selectedComplaints.filter(c => c !== complaint);
  }

  private updateComplaints(): Observable<IComplaint[]> {
    let updates = this.selectedComplaints.map((complaint: IComplaint) => {
      return this.complaintService.saveComplaint(complaint);
    });
    return forkJoin(updates);
  }

  addComplaints(): void {
    this.updateComplaints().subscribe(
      () => this.dialogRef.close(this.selectedComplaints),
      error => console.log('error', error)
    );
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }

}

@Component({
  selector: 'add-note-reference',
  templateUrl: 'dialog-add-note-reference.html'
})

export class AddNoteReferenceDialogComponent implements OnInit {
  public selectedNotes: INote[] = [];

  public notesColumns: string[] = ['text'];
  public notesSource = new MatTableDataSource<INote>(this.notes);

  constructor(public dialogRef: MatDialogRef<AddNoteReferenceDialogComponent>,
    public sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public notes: INote[]) { }

  ngOnInit() {
    this.notesSource.data = this.notes;
  }

  selectNote(note: INote): void {
    this.selectedNotes.push(note);
  }

  removeNote(noteId: number): void {
    this.selectedNotes = this.selectedNotes.filter(note => note.id !== noteId);
  }

  addNotes(): void {
    this.dialogRef.close(this.selectedNotes);
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }
}


@Component({
  selector: 'add-compliance-action-dialog',
  templateUrl: 'dialog-add-compliance-action.html'
})

export class AddComplianceActionDialogComponent implements OnInit {
  public actionItems: IActionItem[] = [];
  public filteredActions: Observable<IActionItem[]>;
  public today: Date = new Date(Date.now());
  public actionForm = new UntypedFormGroup({
    startDate: new UntypedFormControl('', Validators.required),
    endDate: new UntypedFormControl(''),
    action: new UntypedFormControl('', Validators.required)
  });
  constructor(public dialogRef: MatDialogRef<AddComplianceActionDialogComponent>,
    public sharedService: SharedService,
    public complianceService: ComplianceService,
    @Inject(MAT_DIALOG_DATA) public action: IComplianceAction) { }

  ngOnInit()
  {
    this.complianceService.getLookUps(false, this.sharedService.actionItem).subscribe(
      response => this.actionItems = response,
      error => console.log('error', error),
      () => {
        this.filteredActions = this.actionForm.get("action").valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value || ''))
        );
      }
    );
    if(this.action.id > 0){
      this.actionForm.patchValue({
        startDate: this.action.startDate,
        endDate: this.action.endDate,
        action: this.action.action
      });
    }
  }

  private _filter(value: string): IActionItem[] {
    const filterValue = value.toLowerCase();
    return this.actionItems.filter(item => item.value.toLowerCase().includes(filterValue));
  }

  addAction(): void {
    let formValues = this.actionForm.value;
    this.action.action = formValues.action;
    this.action.startDate = formValues.startDate;
    this.action.endDate = formValues.endDate;
    this.dialogRef.close(this.action);
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }
}

@Component({
  selector: 'add-related-rule-dialog',
  templateUrl: 'dialog-add-related-rule.html'
})

export class AddRelatedRuleDialogComponent implements OnInit {
  public cases: ICompliance[] = [];
  public complianceColumns: string[] = ['caseId', 'licenseId', 'status', 'category', 'tier', 'actions'];
  public complianceSource = new MatTableDataSource<ICompliance>(this.cases);
  @ViewChild('compliancePaginator', {static: false}) compliancePaginator: MatPaginator;
  @ViewChild('complianceSort', {static: false}) complianceSort: MatSort;
  @Output() relatedCase = new EventEmitter<ICompliance>();

  constructor(public dialogRef: MatDialogRef<AddRelatedRuleDialogComponent>,
    public sharedService: SharedService,
    public complianceService: ComplianceService,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public currentCase: ICompliance) { }

  ngOnInit() {
    this.complianceService.getAllComplianceCases().subscribe(
      response => this.cases = response,
      error => console.log('error', error),
      () => {
        this.cases = this.cases.filter(c => c.id !== this.currentCase.id && !this.currentCase.relatedCases.some(rc => rc.childCaseId === c.id));
        this.updateCaseTable();
      }
    );
  }

  updateCaseTable(): void {
    this.complianceSource.data = this.cases;
    this.complianceSource.paginator = this.compliancePaginator;
    this.complianceSource.sort = this.complianceSort;
  }

  public filterTable(event: Event): void {
    let value = (event.target as HTMLInputElement).value;
    let filter = value.trim().toLocaleLowerCase();
    this.complianceSource.filter = filter;
    }

  relateCase(compliance: ICompliance): void {
    this.sharedService.openConfirm('Relate Case?');
    this.sharedService.confirmed().subscribe(confirmed => {
      if(confirmed){
        this.complianceService.relatedCase(this.currentCase.id, compliance.id).subscribe(
          response => {
            if(response) {
              this.toastr.success('Case Related');
              this.relatedCase.emit(compliance);
              this.cases = this.cases.filter(c => c.id !== compliance.id);
              this.updateCaseTable();
            }
            else
              this.toastr.error('Could not relate case');
          },
          error => console.log('error', error),
        );
      }
    });
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }
}

@Component({
  selector: 'add-associated-license-dialog',
  templateUrl: 'dialog-add-associated-license.html'
})

export class AddAssociatedLicenseDialogComponent {
  public association: IReferencedLicense = {
    id: 0,
    licenseId: '',
    caseId: this.currentCase.id
  };

  public associateForm = new FormGroup({
    licenseId: new FormControl("", {
      asyncValidators: [this.complaintIdValidator.validate.bind(this.complaintIdValidator)],
      updateOn: 'blur'
    }),
  });
  @Output() associatedLicense = new EventEmitter<IReferencedLicense>();

  constructor(public dialogRef: MatDialogRef<AddRelatedRuleDialogComponent>,
    public sharedService: SharedService,
    public complianceService: ComplianceService,
    public toastr: ToastrService,
    private complaintIdValidator: ComplaintIdValidator,
    @Inject(MAT_DIALOG_DATA) public currentCase: ICompliance) { }

  public associateLicense(): void {
    this.sharedService.openConfirm('Associate License?');
    this.sharedService.confirmed().subscribe(confirmed => {
         if(confirmed){
           this.complianceService.associateLicense(this.currentCase.id, this.associateForm.get('licenseId').value).subscribe(
             response => {
               if(response) {
                 this.toastr.success('License Associated');
                 this.associatedLicense.emit(response);
                 this.associateForm.get('licenseId').setValue('');
               }
               else
                 this.toastr.error('Could not associate license');
             },
             error => console.log('error', error),
           );
         }
    });
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }
}
