import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IServiceCenterData } from "app/models/303/serviceCenterData";
import { SB303Service } from "app/services/303/303.service";
import { SharedService } from "app/services/core/shared.service";

@Component({
  selector: 'app-dialog-view-service-center-data',
  templateUrl: './dialog-view-service-center-data.component.html'
})

export class DialogViewServiceCenterDataComponent implements OnInit {

  public loading: boolean = false;
  public serviceCenterData: IServiceCenterData = {
    id: 0,
    submitted: false,
    open: false,
    submittedDate: "",
    licenseId: "",
    entityName: "",
    clientsServed: null,
    individualAdministrationSessions: null,
    groupAdministrationSessions: null,
    deniedTotal: null,
    deniedLithium: null,
    deniedHarm: null,
    deniedPsychosis: null,
    deniedOther: null,
    behavioralReactions: null,
    severeBehavioralReactions: null,
    medicalReactions: null,
    severeMedicalReactions: null,
    averageClientUses: null,
    averageClientsGroupAdministration: null,
    averageDose: null,
    postSessionReactions: null,
    reportingStartDate: "",
    reportingEndDate: ""
  };

  public clientForm = new FormGroup({
    clientsServed: new FormControl(null, [Validators.required, Validators.min(0)]),
    individualAdministrationSessions: new FormControl(null, [Validators.required, Validators.min(0)]),
    groupAdministrationSessions: new FormControl(null, [Validators.required, Validators.min(0)])
  });

  public deniedForm = new FormGroup({
    deniedTotal: new FormControl(null, [Validators.required, Validators.min(0)]),
    deniedLithium: new FormControl(null, [Validators.required, Validators.min(0)]),
    deniedHarm: new FormControl(null, [Validators.required, Validators.min(0)]),
    deniedPsychosis: new FormControl(null, [Validators.required, Validators.min(0)]),
    deniedOther: new FormControl(null, [Validators.required, Validators.min(0)])
  });

  public reactionForm = new FormGroup({
    behavioralReactions: new FormControl(null, [Validators.required, Validators.min(0)]),
    severeBehavioralReactions: new FormControl(null, [Validators.required, Validators.min(0)]),
    medicalReactions: new FormControl(null, [Validators.required, Validators.min(0)]),
    severeMedicalReactions: new FormControl(null, [Validators.required, Validators.min(0)])
  });

  public averagesForm = new FormGroup({
    averageClientUses: new FormControl(null, [Validators.required, Validators.min(0)]),
    averageClientsGroupAdministration: new FormControl(null, [Validators.required, Validators.min(0)]),
    averageDose: new FormControl(null, [Validators.required, Validators.min(0)])
  });

  public postSessionReactionsForm = new FormGroup({
    postSessionReactions: new FormControl(null, [Validators.required, Validators.min(0)]),
  });

  public currentQuestion: number = 0;
  public questions = ['1.', '2.', '3.', '4.', '5.'];

  constructor(public dialogRef: MatDialogRef<DialogViewServiceCenterDataComponent>,
    public sb303Service: SB303Service,
    public sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: number) { }

  ngOnInit(): void {
    this.loading = true;
    this.sb303Service.getServiceCenterData(this.data).subscribe({
      next: response => this.serviceCenterData = response,
      error: e => console.log('error', e),
      complete: () =>
      {
        this.updateForm();
        this.loading = false;
      }
    });
  }

  updateForm(){
    this.clientForm.patchValue({
      clientsServed: this.serviceCenterData.clientsServed,
      individualAdministrationSessions: this.serviceCenterData.individualAdministrationSessions,
      groupAdministrationSessions: this.serviceCenterData.groupAdministrationSessions
    });

    this.deniedForm.patchValue({
      deniedTotal: this.serviceCenterData.deniedTotal,
      deniedLithium: this.serviceCenterData.deniedLithium,
      deniedHarm: this.serviceCenterData.deniedHarm,
      deniedPsychosis: this.serviceCenterData.deniedPsychosis,
      deniedOther: this.serviceCenterData.deniedOther
    });

    this.reactionForm.patchValue({
      behavioralReactions: this.serviceCenterData.behavioralReactions,
      severeBehavioralReactions: this.serviceCenterData.severeBehavioralReactions,
      medicalReactions: this.serviceCenterData.medicalReactions,
      severeMedicalReactions: this.serviceCenterData.severeMedicalReactions
    });

    this.averagesForm.patchValue({
      averageClientUses: this.serviceCenterData.averageClientUses,
      averageClientsGroupAdministration: this.serviceCenterData.averageClientsGroupAdministration,
      averageDose: this.serviceCenterData.averageDose
    });

    this.postSessionReactionsForm.patchValue({
      postSessionReactions: this.serviceCenterData.postSessionReactions
    });

      this.disableForms();
  }

  disableForms(): void {
    this.clientForm.disable();
    this.deniedForm.disable();
    this.reactionForm.disable();
    this.averagesForm.disable();
    this.postSessionReactionsForm.disable();
  }

  changeQuestion(index: number) {
    this.currentQuestion = index;
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }
}
