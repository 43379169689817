import { Component } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { SB303Service } from "app/services/303/303.service";
import { SharedService } from "app/services/core/shared.service";
import { ToastrService } from "ngx-toastr";

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-dialog-create-new-report',
  templateUrl: './dialog-create-new-report.component.html'
})

export class DialogCreateNewReportComponent {

  public createReportForm = new FormGroup({
    licenseId: new FormControl('', Validators.required),
    from: new FormControl('', Validators.required),
    to: new FormControl('', Validators.required)
  });

  constructor(public dialogRef: MatDialogRef<DialogCreateNewReportComponent>,
    public sb303Service: SB303Service,
    public sharedService: SharedService,
    public toastr: ToastrService) { }

  createReport(): void {
    let form = this.createReportForm.value;

    this.sb303Service.createReport(form.licenseId, new Date(form.from).toDateString(), new Date(form.to).toDateString()).subscribe({
      next: response => {
        if (response) {
          this.toastr.success('Reporting period created successfully');
          this.dialogRef.close('cancel');
        }
        if (!response)
          this.toastr.error('The report could not be created. Please check the license Id and the dates.');
      },
      error: e => console.log('error', e)
    });
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }
}
