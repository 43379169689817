<mat-card fxLayoutGap="8px">
  <div fxLayout="row" fxLayoutGap="8px">
    <mat-card-title>
      Applicants
    </mat-card-title>
    <span fxFlex></span>
    <div [hidden]="editing" fxLayoutGap="8px">
    <button mat-flat-button color="primary" (click)="Save()" *ngIf="!saving">
      Save
    </button>
    <button mat-flat-button color="warn" (click)="close()">
      Close
    </button>
    </div>
    <div [hidden]="!editing" fxLayoutGap="8px">
      <button mat-flat-button color="primary" (click)="Update()">
        Update
      </button>
      <button mat-flat-button color="warn" (click)="Cancel()">
        Cancel
      </button>
    </div>

  </div>

  <div fxLayout="column" fxLayoutGap="8px">
    <mat-card-content [hidden]="!editing" fxLayoutGap="8px">
      <span>Please complete this table for each individual applicant. Use the following for Type of ID:</span>
      <span>Passport; DL; ID; Mil ID; Tribe ID; Other</span>
      <div fxLayout="row" fxLayout.lt-md="column">
        <form [formGroup]="applicantForm"  fxFlex="100" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">

          <mat-form-field fxFlex="25">
            <input matInput placeholder="Applicant Name" formControlName="applicantName">
          </mat-form-field>

          <mat-form-field fxFlex="25">
            <mat-select formControlName="typeOfId" placeholder="Identification Type">
              <mat-option  class="text-wrap" value='Passport'>Passport</mat-option>
              <mat-option  class="text-wrap" value='DL'>DL</mat-option>
              <mat-option  class="text-wrap" value='ID'>ID</mat-option>
              <mat-option  class="text-wrap" value='Mil ID'>Mil ID</mat-option>
              <mat-option  class="text-wrap" value='Tribe ID'>Tribe ID</mat-option>
              <mat-option  class="text-wrap" value='Other'>Other</mat-option>
            </mat-select>
          </mat-form-field>

          <div fxLayout="column" fxLayoutGap="8px" fxFlex="25">
            <mat-checkbox formControlName="nameMatchId">
              <span class="text-wrap" [ngStyle]="{'color': applicantForm.get('nameMatchId').touched && !applicantForm.get('nameMatchId').value? 'red': 'black'}">Name/DOB Match ID</span>
            </mat-checkbox>
            <mat-checkbox formControlName="legalAgeComplete">
              <span class="text-wrap" [ngStyle]="{'color': applicantForm.get('legalAgeComplete').touched && !applicantForm.get('legalAgeComplete').value? 'red': 'black'}">Age 21+</span>
            </mat-checkbox>
          </div>

          <mat-form-field fxFlex="25">
            <mat-label>Date Passed BCU</mat-label>
            <input placeholder="mm/dd/yyyy" formControlName="backgroundCheckDate" matInput [matDatepicker]="backgroundCheckDate"
              [max]="today" />
            <mat-datepicker-toggle matSuffix [for]="backgroundCheckDate"></mat-datepicker-toggle>
            <mat-datepicker #backgroundCheckDate>
              <mat-datepicker-actions>
                <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                <button mat-button matDatepickerCancel>Cancel</button>
              </mat-datepicker-actions>
            </mat-datepicker>
             <mat-error *ngIf="applicantForm.get('backgroundCheckDate').value > today">
               Please choose an earlier date.
             </mat-error>
          </mat-form-field>
        </form>
      </div>
    </mat-card-content>

    <mat-card-content [hidden]="editing" fxLayoutGap="8px">
        <mat-table [dataSource]="applicantSource" matSort aria-describedby="applicants" #applicantSort="matSort"
        class="mat-elevation-z8 m-b-0">

        <ng-container matColumnDef="applicantName">
          <mat-header-cell *matHeaderCellDef>Applicant Name</mat-header-cell>
          <mat-cell *matCellDef="let applicant">
            <span class="mobile-label">Applicant Name</span>
            {{applicant.applicantName}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="typeOfId">
          <mat-header-cell *matHeaderCellDef>Type of ID</mat-header-cell>
          <mat-cell *matCellDef="let applicant">
            <span class="mobile-label">Type of ID</span>
            {{applicant.typeOfId}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="nameMatchId">
          <mat-header-cell *matHeaderCellDef>Name Matches ID</mat-header-cell>
          <mat-cell *matCellDef="let applicant">
            <span class="mobile-label">Name Matches ID</span>
            <span *ngIf="applicant.nameMatchId">Name Matches ID</span>
            <span *ngIf="!applicant.nameMatchId">Does Not Match</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="legalAgeComplete">
          <mat-header-cell *matHeaderCellDef>Verified 21+</mat-header-cell>
          <mat-cell *matCellDef="let applicant">
            <span class="mobile-label">Verified 21+</span>
            <span *ngIf="applicant.legalAgeComplete">Verified</span>
            <span *ngIf="!applicant.legalAgeComplete">Not Verified</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="backgroundCheckDate">
          <mat-header-cell *matHeaderCellDef>Background Check Completed On</mat-header-cell>
          <mat-cell *matCellDef="let applicant">
            <span class="mobile-label">Background Check Completed On</span>
            {{applicant.backgroundCheckDate | date}}
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let applicant">
            <button type="button" mat-icon-button class="m-8"
              (click)="Edit(applicant)" color="Primary"
              aria-label="Edit Applicant">
              <mat-icon>edit</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="applicantColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: applicantColumns"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[25, 50, 100]" #applicantPaginator="matPaginator">
      </mat-paginator>
  </mat-card-content>
</div>
</mat-card>
