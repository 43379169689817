<div *ngIf="!loading">
  <div [@slideLeft] *ngIf="complianceCase.id <= 0" fxLayout="column" class="m-t-16">
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutGap="24px" fxLayoutAlign="start center">
        <span>Cases</span>
        <mat-form-field fxFlex="20" fxFlex.lt-md="100">
          <input matInput (keyup)="filterTable($event, 'compliance')" placeholder="Filter" />
        </mat-form-field>
        <span fxFlex></span>
        <button type="button" mat-icon-button color="primary" matTooltip="Open New Case"
                matTooltipShowDelay=800 aria-label="Open New Case" *ngIf="isAdmin" (click)="addComplianceCase()">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <mat-table [dataSource]="complianceSource" matSort #complianceSort="matSort" aria-describedby="Cases" class="mat-elevation-z8">
        <ng-container matColumnDef="caseId">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Case Id</mat-header-cell>
          <mat-cell *matCellDef="let compliance">
            <span class="mobile-label">Case Id</span>
            {{ compliance.caseId }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
          <mat-cell *matCellDef="let compliance">
            <span class="mobile-label">Status</span>
            {{ compliance.status }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="category">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Category</mat-header-cell>
          <mat-cell *matCellDef="let compliance">
            <span class="mobile-label">Category</span>
            {{ compliance.category }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tier">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Tier</mat-header-cell>
          <mat-cell *matCellDef="let compliance">
            <span class="mobile-label">Tier</span>
            {{ compliance.tier }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="assignedTo">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To</mat-header-cell>
          <mat-cell *matCellDef="let compliance">
            <span class="mobile-label">Assigned To</span>
            {{staffReference[compliance.assignedTo]}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="manager">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Manager</mat-header-cell>
          <mat-cell *matCellDef="let compliance">
            <span class="mobile-label">Manager</span>
            {{staffReference[compliance.manager]}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="outcome">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Outcome</mat-header-cell>
          <mat-cell *matCellDef="let compliance">
            <span class="mobile-label">Outcome</span>
            {{ compliance.outcome }}
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="complianceColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: complianceColumns" (click)="viewComplianceCase(row, false)"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" #compliancePaginator="matPaginator">
      </mat-paginator>

      <div [hidden]="!renewal">
        <div fxLayout="row" fxLayoutGap="24px" fxLayoutAlign="start center">
          <span>Previous Cases</span>
          <mat-form-field fxFlex="20" fxFlex.lt-md="100">
            <input matInput (keyup)="filterTable($event, 'expiredCompliance')" placeholder="Filter" />
          </mat-form-field>
          <span fxFlex></span>
        </div>

        <mat-table [dataSource]="expiredComplianceSource" matSort #expiredComplianceSort="matSort" aria-describedby="Cases" class="mat-elevation-z8">
          <ng-container matColumnDef="caseId">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Case Id</mat-header-cell>
            <mat-cell *matCellDef="let compliance">
              <span class="mobile-label">Case Id</span>
              {{ compliance.caseId }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
            <mat-cell *matCellDef="let compliance">
              <span class="mobile-label">Status</span>
              {{ compliance.status }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="category">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Category</mat-header-cell>
            <mat-cell *matCellDef="let compliance">
              <span class="mobile-label">Category</span>
              {{ compliance.category }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="tier">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Tier</mat-header-cell>
            <mat-cell *matCellDef="let compliance">
              <span class="mobile-label">Tier</span>
              {{ compliance.tier }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="assignedTo">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To</mat-header-cell>
            <mat-cell *matCellDef="let compliance">
              <span class="mobile-label">Assigned To</span>
              {{staffReference[compliance.assignedTo]}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="manager">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Manager</mat-header-cell>
            <mat-cell *matCellDef="let compliance">
              <span class="mobile-label">Manager</span>
              {{staffReference[compliance.manager]}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="outcome">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Outcome</mat-header-cell>
            <mat-cell *matCellDef="let compliance">
              <span class="mobile-label">Outcome</span>
              {{ compliance.outcome }}
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="expiredComplianceColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: expiredComplianceColumns" (click)="viewComplianceCase(row,true)"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" #expiredCompliancePaginator="matPaginator">
        </mat-paginator>
      </div>
    </div>
  </div>

  <div [@slideRight] *ngIf="complianceCase.id > 0"  [hidden]="viewingInvestigation || viewingCompliancePlan" fxLayout="column" class="m-t-16" fxLayoutGap="12px">
    <div fxLayout="column" fxFlex="100">
      <div fxLayout="row" fxFlex="100">
        <span fxFlex></span>
        <button mat-button type="button" color="warn" (click)="viewComplianceCases()">Close</button>
        <button *ngIf="editing && !expired" mat-button type="button" color="primary" (click)="updateCompliance(false)">Save</button>
        <button *ngIf="editing && !expired" mat-button type="button" color="primary" (click)="updateCompliance(true)">Save & Close</button>
        <button *ngIf="editing && authService.isAdmin && !expired" mat-button type="button" color="warn"
          (click)="complianceCase.locked? unlockCompliance() : lockCompliance()">{{complianceCase.locked? 'Unlock' : 'Lock'}}</button>
        <button *ngIf="!expired" type="button" mat-icon-button [color]="editing? 'primary':'warn'"
              [matTooltip]="editing? 'Stop Editing' : 'Edit Case'" matTooltipShowDelay=800
              aria-label="Edit Case" (click)="editCompliance()">
          <mat-icon>{{editing? 'edit' : 'edit_off'}}</mat-icon>
        </button>
      </div>
      <div fxLayout="row"  fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutAlign="start start" fxLayoutAlign.lt-md="start center">
        <div fxLayout="column" fxFlex="25">
          <span style="font-size:xx-large">Case</span>
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
            <span class="f-s-16">Case ID:</span>
            <span>{{complianceCase.caseId}}</span>
            <button mat-icon-button (click)="sharedService.copyToClipboard(complianceCase.caseId)" color="primary" matTooltip="Copy to Clipboard"
              matTooltipShowDelay=800 aria-label="Copy to Clipboard">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
          <span style="font-size:x-large"><strong>{{entityName}}</strong></span>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" *ngIf="!editing" fxFlex="100">
          <div fxLayout="column" fxLayoutGap="8px">
            <div fxLayout="row" fxLayoutGap="12px">
              <span><strong>Status</strong></span>
              <span>{{complianceCase.status}}</span>
            </div>
            <div fxLayout="row" fxLayoutGap="12px">
              <span><strong>Category</strong></span>
              <span>{{complianceCase.category}}</span>
            </div>
            <div fxLayout="row" fxLayoutGap="12px">
              <span><strong>Tier</strong></span>
              <span>{{complianceCase.tier}}</span>
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="8px">
            <div fxLayout="row" fxLayoutGap="12px">
              <span><strong>Date of Non-Compliance</strong></span>
              <span>{{complianceCase.dateOfNonCompliance | date}}</span>
            </div>
            <div fxLayout="row" fxLayoutGap="12px">
              <span><strong>Date of Compliance</strong></span>
              <span>{{complianceCase.dateOfCompliance | date}}</span>
            </div>
            <div fxLayout="row" fxLayoutGap="12px">
              <span><strong>Notice Type</strong></span>
              <span>{{complianceCase.type}}</span>
            </div>
            <div fxLayout="row" fxLayoutGap="12px">
              <span><strong>Closed Date</strong></span>
              <span>{{complianceCase.closedDate | date}}</span>
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="8px">
            <div fxLayout="row" fxLayoutGap="12px">
              <span><strong>Assigned To</strong></span>
              <span>{{staffReference[complianceCase.assignedTo]}}</span>
            </div>
            <div fxLayout="row" fxLayoutGap="12px">
              <span><strong>Manager</strong></span>
              <span>{{staffReference[complianceCase.manager]}}</span>
            </div>
            <div fxLayout="row" fxLayoutGap="12px">
              <span><strong>Notice Due Date</strong></span>
              <span>{{complianceCase.dueDate | date}}</span>
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="8px">
            <div fxLayout="row" fxLayoutGap="12px">
              <span><strong>Outcome</strong></span>
              <span>{{complianceCase.outcome}}</span>
            </div>
            <div fxLayout="row" fxLayoutGap="12px">
              <span *ngIf="complianceCase.agencyGenerated">Agency Generated</span>
              <span *ngIf="!complianceCase.agencyGenerated"><strong>NOT</strong> Agency Generated</span>
            </div>
            <div fxLayout="row" fxLayoutGap="12px">
              <span *ngIf="complianceCase.sent == true">Notice Sent</span>
              <span *ngIf="complianceCase.sent == false">Pending Notice</span>
            </div>
          </div>
        </div>

        <div *ngIf="editing">
          <form [formGroup]="complianceForm" (ngSubmit)="updateCompliance(false)">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
              <div fxLayout="column">
                <mat-form-field>
                  <mat-label>Status</mat-label>
                  <mat-select formControlName="status">
                    <mat-option value="Submitted">Submitted</mat-option>
                    <mat-option value="Open">Open</mat-option>
                    <mat-option value="Closed" *ngIf="isAdmin">Closed</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Category</mat-label>
                  <input type="text"
                         placeholder="Select"
                         aria-label="Category"
                         matInput
                         formControlName="category"
                         [matAutocomplete]="category">
                  <mat-autocomplete #category="matAutocomplete">
                    <mat-option *ngFor="let category of filteredCategories | async" [value]="category.value" >
                      {{category.value}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Tier</mat-label>
                  <mat-select formControlName="tier">
                    <mat-option value="Tier1">Tier 1</mat-option>
                    <mat-option value="Tier2">Tier 2</mat-option>
                    <mat-option value="Tier3">Tier 3</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxLayout="column">
                <mat-form-field>
                  <mat-label>Date of Non-Compliance</mat-label>
                  <input placeholder="mm/dd/yyyy"
                         formControlName="dateOfNonCompliance"
                         matInput
                         [matDatepicker]="datepickerNonCompliance"
                         [max]="today" />
                  <mat-datepicker-toggle matSuffix [for]="datepickerNonCompliance"></mat-datepicker-toggle>
                  <mat-datepicker #datepickerNonCompliance>
                    <mat-datepicker-actions>
                      <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                      <button mat-button matDatepickerCancel>Cancel</button>
                    </mat-datepicker-actions>
                  </mat-datepicker>
                  <mat-error *ngIf="complianceForm.get('dateOfNonCompliance').value > today">
                    Please choose an earlier date.
                  </mat-error>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Date of Compliance</mat-label>
                  <input placeholder="mm/dd/yyyy"
                         formControlName="dateOfCompliance"
                         matInput
                         [matDatepicker]="datepickerCompliance"
                         [max]="today" />
                  <mat-datepicker-toggle matSuffix [for]="datepickerCompliance"></mat-datepicker-toggle>
                  <mat-datepicker #datepickerCompliance>
                    <mat-datepicker-actions>
                      <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                      <button mat-button matDatepickerCancel>Cancel</button>
                    </mat-datepicker-actions>
                  </mat-datepicker>
                  <mat-error *ngIf="complianceForm.get('dateOfCompliance').value > today">
                    Please choose an earlier date.
                  </mat-error>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Notice Type</mat-label>
                  <mat-select formControlName="type">
                    <mat-option value="">-- None --</mat-option>
                    <mat-option value="Proposed License Denial Notice">Proposed License Denial Notice</mat-option>
                    <mat-option value="Proposed License Revocation Notice">Proposed License Revocation Notice</mat-option>
                    <mat-option value="Proposed License Civil Penalty Notice">Proposed License Civil Penalty Notice</mat-option>
                    <mat-option value="Default Final Order">Default Final Order</mat-option>
                    <mat-option value="Emergency Suspension">Emergency Suspension</mat-option>
                    <mat-option value="Settlement">Settlement</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Closed Date</mat-label>
                  <input placeholder="mm/dd/yyyy"
                         [value]="complianceCase.closedDate"
                         matInput
                         readonly="true"/>
                </mat-form-field>
              </div>
              <div fxLayout="column">
                <mat-form-field>
                  <mat-label>Assigned To</mat-label>
                  <mat-select formControlName="assignedTo">
                    <mat-option [value]="">-- None --</mat-option>
                    <mat-option *ngFor="let user of staff" [value]="user.id">{{user.name}}</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Manager</mat-label>
                  <mat-select formControlName="manager">
                    <mat-option [value]="">-- None --</mat-option>
                    <mat-option *ngFor="let user of managers" [value]="user.id">{{user.name}}</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Notice Due Date</mat-label>
                  <input placeholder="mm/dd/yyyy"
                         formControlName="dueDate"
                         matInput
                         [matDatepicker]="datepickerDueDate" />
                  <mat-datepicker-toggle matSuffix [for]="datepickerDueDate"></mat-datepicker-toggle>
                  <mat-datepicker #datepickerDueDate>
                    <mat-datepicker-actions>
                      <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                      <button mat-button matDatepickerCancel>Cancel</button>
                    </mat-datepicker-actions>
                  </mat-datepicker>
                </mat-form-field>
              </div>
              <div fxLayout="column" fxLayoutGap="24px">
                <mat-form-field>
                  <mat-label>Outcome</mat-label>
                  <mat-select formControlName="outcome">
                    <mat-option value="Closed">Closed</mat-option>
                    <mat-option value="Education">Education</mat-option>
                    <mat-option value="Administrative Action">Administrative Action</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-checkbox formControlName="agencyGenerated">
                  Agency Generated
                </mat-checkbox>

                <div fxLayout="row" fxLayoutGap="12px">
                  <div fxLayout="column">
                    <mat-radio-group formControlName="sent" class="radio-group">
                      <mat-radio-button class="radio-button" [value]="false">Pending Notice</mat-radio-button>
                      <mat-radio-button class="radio-button" [value]="true">Notice Sent</mat-radio-button>
                    </mat-radio-group>
                  </div>

                  <div fxLayout="column">
                    <button type="button" mat-raised-button color="warn" *ngIf="noticeCompleted()" (click)="archiveNotice()">Archive Notice</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div fxLayout="column">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutAlign="start center">
        <span><strong>Archived Notices</strong></span>
        <mat-form-field fxFlex="20" fxFlex.lt-md="100">
          <input matInput (keyup)="filterTable($event, 'notices')" placeholder="Filter" />
        </mat-form-field>
      </div>

      <mat-table [dataSource]="noticeSource" matSort #noticeSort="matSort" aria-describedby="Notice" class="mat-elevation-z8">
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Notice Type</mat-header-cell>
          <mat-cell *matCellDef="let notice">
            <span class="mobile-label">Notice Type</span>
            {{ notice.type }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dueDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Due Date</mat-header-cell>
          <mat-cell *matCellDef="let notice">
            <span class="mobile-label">Due Date</span>
            {{ notice.dueDate | date}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sent">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
          <mat-cell *matCellDef="let notice">
            <span class="mobile-label">Status</span>
            <span *ngIf="notice.sent == true">Notice Sent</span>
            <span *ngIf="notice.sent == false">Pending Notice</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell *matCellDef="let notice">
            <button *ngIf="editing && authService.isAdmin" type="button" mat-button color="warn"
              matTooltip="Delete Notice" matTooltipShowDelay=800 aria-label="Delete Notice" (click)="deleteNotice(notice.id)">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="noticeColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: noticeColumns">
        </mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" #noticePaginator="matPaginator">
      </mat-paginator>
    </div>

    <div fxLayout="column">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutAlign="start center">
        <span><strong>Associated Complaints</strong></span>
        <mat-form-field fxFlex="20" fxFlex.lt-md="100">
          <input matInput (keyup)="filterTable($event, 'complaint')" placeholder="Filter" />
        </mat-form-field>
        <span fxFlex></span>

        <button *ngIf="editing && isAdmin" type="button" mat-button color="primary"
          matTooltip="Reference Complaint" matTooltipShowDelay=800 aria-label="Reference Complaint" (click)="addComplaintReferences()">
          Reference Complaint
        </button>

        <button *ngIf="editing" type="button" mat-button color="primary"
          matTooltip="Add New Complaint" matTooltipShowDelay=800 aria-label="Add New Complaint" (click)="addNewComplaint()">
          Add Complaint
        </button>
      </div>

      <mat-table [dataSource]="complaintSource" matSort #complaintSort="matSort" aria-describedby="Complaints" class="mat-elevation-z8">
        <ng-container matColumnDef="dateCreated">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Date Received</mat-header-cell>
          <mat-cell *matCellDef="let complaint">
            <span class="mobile-label">Date Received</span>
            {{ complaint.dateCreated }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
          <mat-cell *matCellDef="let complaint">
            <span class="mobile-label">Status</span>
            {{ complaint.status }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="complaintText">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Complaint</mat-header-cell>
          <mat-cell *matCellDef="let complaint">
            <span class="mobile-label">Complaint</span>
            <textarea matInput rows="1" [value]="complaint.complaintText" disabled></textarea>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell *matCellDef="let complaint">
            <button *ngIf="editing && authService.isAdmin" type="button" mat-button color="warn"
            matTooltip="Remove Reference" matTooltipShowDelay=800 aria-label="Remove Reference" (click)="removeComplaint(complaint)">
            Remove
          </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="complaintColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: complaintColumns">
        </mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" #complaintPaginator="matPaginator">
      </mat-paginator>
    </div>

    <div fxLayout="column">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutAlign="start center">
        <span><strong>Selected Rules and Statute References</strong></span>
        <mat-form-field fxFlex="20" fxFlex.lt-md="100">
          <input matInput (keyup)="filterTable($event, 'rule')" placeholder="Filter" />
        </mat-form-field>
        <span fxFlex></span>
        <span *ngIf="editing">Click to Edit</span>
        <span *ngIf="!editing">Click to View Rule and/or statute Details</span>
        <button *ngIf="editing" type="button" mat-icon-button color="primary" (click)="addComplianceRules()"
          matTooltip="Add Rule Reference" matTooltipShowDelay=800 aria-label="Add Rule Reference">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <mat-table [dataSource]="ruleRefSource" aria-describedby="Rule References" class="mat-elevation-z8">
        <ng-container matColumnDef="statute">
          <mat-header-cell *matHeaderCellDef>Rule</mat-header-cell>
          <mat-cell (click)="editing? updateRuleReference(rule) : viewRuleDetails(rule.rule)" *matCellDef="let rule">
            <span class="mobile-label">Rule</span>
            {{ rule.rule.statute }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="summary">
          <mat-header-cell *matHeaderCellDef>Summary</mat-header-cell>
          <mat-cell (click)="editing? updateRuleReference(rule) : viewRuleDetails(rule.rule)" *matCellDef="let rule">
            <span class="mobile-label">Summary</span>
            {{ rule.rule.summary }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="notes">
          <mat-header-cell *matHeaderCellDef>Notes</mat-header-cell>
          <mat-cell (click)="editing? updateRuleReference(rule) : viewRuleDetails(rule.rule)" *matCellDef="let rule">
            <span class="mobile-label">Notes</span>
            {{ rule.notes }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="resolvedDate">
          <mat-header-cell *matHeaderCellDef>Resolved Date</mat-header-cell>
          <mat-cell (click)="editing? updateRuleReference(rule) : viewRuleDetails(rule.rule)" *matCellDef="let rule">
            <span class="mobile-label">Resolved</span>
            <span>{{ rule.resolvedDate | date }}</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let rule">
            <button *ngIf="editing && authService.isAdmin" type="button" mat-button color="warn" (click)="removeComplianceRule(rule)">Remove</button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="ruleRefColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: ruleRefColumns"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" #ruleRefPaginator="matPaginator"></mat-paginator>
    </div>

    <div fxLayout="column">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutAlign="start center">
        <span><strong>Action Items</strong></span>
        <mat-form-field fxFlex="20" fxFlex.lt-md="100">
          <input matInput (keyup)="filterTable($event, 'action')" placeholder="Filter" />
        </mat-form-field>
        <span fxFlex></span>
        <span *ngIf="editing">Click to Edit</span>
        <button *ngIf="editing" type="button" mat-icon-button color="primary" (click)="addActionItem()"
          matTooltip="Add Action Item" matTooltipShowDelay=800 aria-label="Add Action Item">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <mat-table [dataSource]="complianceActionSource" aria-describedby="Compliance Actions" class="mat-elevation-z8">
        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
          <mat-cell *matCellDef="let compliance">
            <span class="mobile-label">Action</span>
            <span>{{ compliance.action }}</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="dateCreated">
          <mat-header-cell *matHeaderCellDef>Date Recorded</mat-header-cell>
          <mat-cell *matCellDef="let compliance">
            <span class="mobile-label">Date Recorded</span>
            {{ compliance.dateCreated | date }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="startDate">
          <mat-header-cell *matHeaderCellDef>Start Date</mat-header-cell>
          <mat-cell *matCellDef="let compliance">
            <span class="mobile-label">Start Date</span>
            {{ compliance.startDate | date }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="endDate">
          <mat-header-cell *matHeaderCellDef>End Date</mat-header-cell>
          <mat-cell *matCellDef="let compliance">
            <span class="mobile-label">End Date</span>
            {{ compliance.endDate | date }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="timeSpan">
          <mat-header-cell *matHeaderCellDef>Time Elapsed</mat-header-cell>
          <mat-cell *matCellDef="let compliance">
            <span class="mobile-label">Time Elapsed</span>
            {{ compliance.timeSpan  + ' hours'}}
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="complianceActionColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: complianceActionColumns" (click)="editing? editActionItem(row) : ''"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" #complianceActionPaginator="matPaginator"></mat-paginator>
    </div>

   <div fxLayout="column">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutAlign="start center">
        <span><strong>Related Cases</strong></span>
        <mat-form-field fxFlex="20" fxFlex.lt-md="100">
          <input matInput (keyup)="filterTable($event, 'related')" placeholder="Filter" />
        </mat-form-field>
        <span fxFlex></span>
        <span *ngIf="editing">Click to Add Related Case</span>
        <button *ngIf="editing" type="button" mat-icon-button color="primary" (click)="addRelatedRule()"
          matTooltip="Add Related Case" matTooltipShowDelay=800 aria-label="Add Related Case">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <mat-table [dataSource]="relatedSource" matSort #relatedSort="matSort" aria-describedby="Related Cases" class="mat-elevation-z8">
        <ng-container matColumnDef="caseId">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Case Id</mat-header-cell>
          <mat-cell *matCellDef="let related" (click)="viewRelatedCase(related.childCase)">
            <span class="mobile-label">Case Id</span>
            <span class="text-indigo-A700" style="cursor: pointer">{{related.childCase.caseId}}</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="licenseId">
          <mat-header-cell *matHeaderCellDef mat-sort-header>License Id</mat-header-cell>
          <mat-cell *matCellDef="let related">
            <span class="mobile-label">License Id</span>
              {{related.childCase.licenseId}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
          <mat-cell *matCellDef="let related">
            <span class="mobile-label">Status</span>
              {{related.childCase.status}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="category">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Category</mat-header-cell>
          <mat-cell *matCellDef="let related">
            <span class="mobile-label">Category</span>
              {{related.childCase.category}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tier">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Tier</mat-header-cell>
          <mat-cell *matCellDef="let related">
            <span class="mobile-label">Tier</span>
              {{related.childCase.tier}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell *matCellDef="let related">
            <button  type="button" mat-icon-button class="m-8" *ngIf="editing"
              (click)="removeRelatedCase(related)" color="warn" aria-label="Delete">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="relatedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: relatedColumns"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" #relatedPaginator="matPaginator"></mat-paginator>
    </div>

    <div fxLayout="column">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutAlign="start center">
        <span><strong>Associated Licenses</strong></span>
        <mat-form-field fxFlex="20" fxFlex.lt-md="100">
          <input matInput (keyup)="filterTable($event, 'associated')" placeholder="Filter" />
        </mat-form-field>
        <span fxFlex></span>
        <span *ngIf="editing">Click to Associate a License</span>
        <button *ngIf="editing" type="button" mat-icon-button color="primary" (click)="addAssociatedLicense()"
          matTooltip="Associate License" matTooltipShowDelay=800 aria-label="Associate License">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <mat-table [dataSource]="associatedSource" matSort #associatedSort="matSort" aria-describedby="Associated Licenses" class="mat-elevation-z8">
        <ng-container matColumnDef="licenseId">
          <mat-header-cell *matHeaderCellDef mat-sort-header>License Id</mat-header-cell>
          <mat-cell *matCellDef="let associatedLicense">
            <span class="mobile-label">License Id</span>
              {{associatedLicense.licenseId}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell *matCellDef="let associatedLicense">
            <button  type="button" mat-icon-button class="m-8" *ngIf="editing"
              (click)="removeAssociatedLicense(associatedLicense)" color="warn" aria-label="Delete">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="associatedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: associatedColumns" [ngStyle]="{'background-color': row.licenseId === complianceCase.originalLicenseId ? '#D1FFBD' : ''}"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" #associatedPaginator="matPaginator"></mat-paginator>
    </div>
  </div>

  <div [@slideRight] *ngIf="complianceCase.id > 0 && !viewingCompliancePlan" fxLayout="column" class="m-t-16" fxLayoutGap="8px">
    <app-investigation *ngIf="complianceCase.id > 0"
      [@slideInOut]
      [caseId]="complianceCase.id"
      [compliance] = "complianceCase"
      [editing]="editing"
      [staff] = "staff"
      [staffReference] = "staffReference"
      [entityType] = "entityType"
      fxFlex.lt-md="100"
      (notifyParent)="viewInvestigation($event)">
    </app-investigation>
  </div>

  <div [@slideRight] *ngIf="complianceCase.id > 0 && !viewingInvestigation" fxLayout="column" class="m-t-16" fxLayoutGap="8px">
    <app-compliancePlan *ngIf="complianceCase.id > 0"
      [@slideInOut]
      [caseId]="complianceCase.id"
      [compliance] = "complianceCase"
      [editing]="editing"
      [staff] = "staff"
      [staffReference] = "staffReference"
      fxFlex.lt-md="100"
      (notifyParent)="viewCompliancePlan($event)">
    </app-compliancePlan>
  </div>

  <div [@slideRight] *ngIf="complianceCase.id > 0 && !viewingInvestigation && !viewingCompliancePlan" fxLayout="column" class="m-t-16" fxLayoutGap="8px">
    <div fxLayout="column">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutAlign="start center">
        <span><strong>Documents</strong></span>
        <mat-form-field fxFlex="20" fxFlex.lt-md="100">
          <input matInput (keyup)="filterTable($event, 'document')" placeholder="Filter" />
        </mat-form-field>
        <span fxFlex></span>

        <input type="file" class="file-input"  #fileUploadComplianceDocument (change)="uploadComplianceDocument($event)"/>
        <div fxLayout="row" fxLayoutGap="8px" *ngIf="editing">
          <div class="file-upload">
            <button mat-raised-button color="primary" class="upload-btn" (click)="fileUploadComplianceDocument.click()">
              Upload
              <mat-icon>attach_file</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <mat-table [dataSource]="complianceDocumentSource" aria-describedby="notes" class="mat-elevation-z8">

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
          <mat-cell *matCellDef="let document">
            <span class="text-indigo-A700" style="cursor: pointer; overflow: hidden; text-overflow: ellipsis;" [matTooltip]="document.name" matTooltipShowDelay=500
              (click)="downloadComplianceDocument(document.id, document.name)">{{document.name}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dateCreated">
          <mat-header-cell *matHeaderCellDef>Date Created</mat-header-cell>
          <mat-cell *matCellDef="let document">
            <span class="mobile-label">Date Created</span>
            <span>{{ document.dateCreated | date }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let document" (click)="$event.stopPropagation()">
            <div fxLayout="row" *ngIf="editing">
              <button type="button" mat-icon-button class="m-8" (click)="deleteComplianceDocument(document.id, document.name)"
                color="warn" aria-label="Delete">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="complianceDocumentColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: complianceDocumentColumns"></mat-row>
      </mat-table>
    </div>
  </div>

  <div [@slideRight] *ngIf="complianceCase.id > 0 && !viewingInvestigation && !viewingCompliancePlan" fxLayout="column" class="m-t-16" fxLayoutGap="8px">
    <div fxLayout="column">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px" fxLayoutAlign="start center">
        <span><strong>Notes</strong></span>
        <mat-form-field fxFlex="20" fxFlex.lt-md="100">
          <input matInput (keyup)="filterTable($event, 'notes')" placeholder="Filter" />
        </mat-form-field>
        <span fxFlex></span>

        <button *ngIf="editing" type="button" mat-button color="primary"
          matTooltip="Reference Note" matTooltipShowDelay=800 aria-label="Reference Note" (click)="addNoteReference()">
          Reference A Note
        </button>

        <button *ngIf="editing" type="button" mat-button color="primary"
          matTooltip="Add Note" matTooltipShowDelay=800 aria-label="Add Note" (click)="addNewNote()">
          Add Note
        </button>
      </div>

      <mat-table [dataSource]="notesSource" aria-describedby="notes" class="mat-elevation-z8">
        <ng-container matColumnDef="text">
          <mat-header-cell *matHeaderCellDef>Note</mat-header-cell>
          <mat-cell *matCellDef="let note">
            <span class="mobile-label">Note</span>
            <div fxLayout="row" fxLayoutGap="16px" fxFlex="100">
              <textarea (click)="editing? editNote(note) : ''" fxFlex="65" rows="2" matInput [value]="note.text"></textarea>
              <div fxLayout="column" (click)="editing? editNote(note) : ''">
                <span>Last Updated: {{note.dateLastUpdated}}</span>
                <span>Updated By: {{note.lastUpdatedBy}}</span>
              </div>
              <button *ngIf="note.generic && editing" mat-button color="warn" aria-label="Delete Note"
                      (click)="removeNoteReference(note.id)">
                Remove Reference
              </button>
              <span *ngIf="!editing">
                {{note.generic? "Reference" : "Compliance Specific"}}
              </span>
            </div>

          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="notesColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: notesColumns"></mat-row>
      </mat-table>
    </div>
  </div>

  <div [@slideRight] *ngIf="complianceCase.id > 0 && !viewingInvestigation && !viewingCompliancePlan" fxLayout="column" class="m-t-16" fxLayoutGap="8px">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutAlign.lt-md="start center">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" style="cursor: pointer;">
        <span (click)="openChangeLog()">
          <mat-icon class="icon-20">history_edu</mat-icon> Change Log
        </span>
      </div>

    </div>
  </div>
</div>

<div *ngIf="loading">
  <div [ngClass]="{'loading': true, 'loading-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>
</div>
