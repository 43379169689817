import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ExcelExportComponent } from "@progress/kendo-angular-excel-export";
import { ISB303 } from "app/models/303/sb303";
@Component({
  selector: 'app-dialog-download-client-data',
  templateUrl: './dialog-download-client-data.component.html',
  styleUrls: ['./dialog-download-client-data.component.scss']
})
export class DialogDownloadClientDataComponent implements OnInit {
  @ViewChild('excelExport', { static: false }) excelExport: ExcelExportComponent;

  public gridData: any[] = [];

  constructor(public dialogRef: MatDialogRef<DialogDownloadClientDataComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ISB303[]) { }

  ngOnInit(): void {
    this.setUpData();
  }

  setUpData(): void {
    this.gridData = [
      { title: 'Racial or ethnic identities', value: '' },
      { title: 'Central American', value: this.data[0].clientData.racialIdentity.centralAmerican },
      { title: 'Mexican', value: this.data[0].clientData.racialIdentity.mexican },
      { title: 'South American', value: this.data[0].clientData.racialIdentity.southAmerican },
      { title: 'Other Hispanic or Latino/a/x', value: this.data[0].clientData.racialIdentity.otherHispanic },
      { title: 'CHamoru (Chamorro)', value: this.data[0].clientData.racialIdentity.chamoru },
      { title: 'Marshallese', value: this.data[0].clientData.racialIdentity.marshallese },
      { title: 'Communities of the Micronesian Region', value: this.data[0].clientData.racialIdentity.micronesian },
      { title: 'Native Hawaiian', value: this.data[0].clientData.racialIdentity.nativeHawaiian },
      { title: 'Samoan', value: this.data[0].clientData.racialIdentity.samoan },
      { title: 'Other Pacific Islander', value: this.data[0].clientData.racialIdentity.otherPacificIslander },
      { title: 'Eastern European', value: this.data[0].clientData.racialIdentity.easternEuropean },
      { title: 'Slavic', value: this.data[0].clientData.racialIdentity.slavic },
      { title: 'Western European', value: this.data[0].clientData.racialIdentity.westernEuropean },
      { title: 'Other White', value: this.data[0].clientData.racialIdentity.otherWhite },
      { title: 'American Indian', value: this.data[0].clientData.racialIdentity.americanIndian },
      { title: 'Alaska Native', value: this.data[0].clientData.racialIdentity.alaskaNative },
      { title: 'Canadian Innuit, Metis, or First Nation', value: this.data[0].clientData.racialIdentity.canadianInnuitMetisFirstNation },
      { title: 'Indigenous Mexican, Central American or South American', value: this.data[0].clientData.racialIdentity.indigenousMexicanCentralAmericanSouthAmerican },
      { title: 'African American', value: this.data[0].clientData.racialIdentity.africanAmerican },
      { title: 'Afro-Caribbean', value: this.data[0].clientData.racialIdentity.afroCaribbean },
      { title: 'Ethiopian', value: this.data[0].clientData.racialIdentity.ethiopian },
      { title: 'Somali', value: this.data[0].clientData.racialIdentity.somali },
      { title: 'Other African(Black)', value: this.data[0].clientData.racialIdentity.otherAfrican },
      { title: 'Other Black', value: this.data[0].clientData.racialIdentity.otherBlack },
      { title: 'Middle Eastern', value: this.data[0].clientData.racialIdentity.middleEastern },
      { title: 'North African', value: this.data[0].clientData.racialIdentity.northAfrican },
      { title: 'Asian Indian', value: this.data[0].clientData.racialIdentity.asianIndian },
      { title: 'Cambodian', value: this.data[0].clientData.racialIdentity.cambodian },
      { title: 'Chinese', value: this.data[0].clientData.racialIdentity.chinese },
      { title: 'Communities of Myanmar', value: this.data[0].clientData.racialIdentity.myanmar },
      { title: 'Filipino/a', value: this.data[0].clientData.racialIdentity.filipino },
      { title: 'Hmong', value: this.data[0].clientData.racialIdentity.hmong },
      { title: 'Japanese', value: this.data[0].clientData.racialIdentity.japanese },
      { title: 'Korean', value: this.data[0].clientData.racialIdentity.korean },
      { title: 'Laotian', value: this.data[0].clientData.racialIdentity.laotian },
      { title: 'South Asian', value: this.data[0].clientData.racialIdentity.southAsian },
      { title: 'Vietnamese', value: this.data[0].clientData.racialIdentity.vietnamese },
      { title: 'Other Asian', value: this.data[0].clientData.racialIdentity.otherAsian },
      { title: 'Other (not listed)', value: this.data[0].clientData.racialIdentity.otherTotal },
      { title: 'Don\'t Know', value: this.data[0].clientData.racialIdentity.dontKnowTotal },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.racialIdentity.refusedTotal },

      { title: '', value: '' },
      { title: 'Primary racial or ethnic identity, a biracial or multiracial identity, or other.', value: '' },
      { title: 'Central American', value: this.data[0].clientData.primaryRacialIdentity.centralAmerican },
      { title: 'Mexican', value: this.data[0].clientData.primaryRacialIdentity.mexican },
      { title: 'South American', value: this.data[0].clientData.primaryRacialIdentity.southAmerican },
      { title: 'Other Hispanic or Latino/a/x', value: this.data[0].clientData.primaryRacialIdentity.otherHispanic },
      { title: 'CHamoru (Chamorro)', value: this.data[0].clientData.primaryRacialIdentity.chamoru },
      { title: 'Marshallese', value: this.data[0].clientData.primaryRacialIdentity.marshallese },
      { title: 'Communities of the Micronesian Region', value: this.data[0].clientData.primaryRacialIdentity.micronesian },
      { title: 'Native Hawaiian', value: this.data[0].clientData.primaryRacialIdentity.nativeHawaiian },
      { title: 'Samoan', value: this.data[0].clientData.primaryRacialIdentity.samoan },
      { title: 'Other Pacific Islander', value: this.data[0].clientData.primaryRacialIdentity.otherPacificIslander },
      { title: 'Eastern European', value: this.data[0].clientData.primaryRacialIdentity.easternEuropean },
      { title: 'Slavic', value: this.data[0].clientData.primaryRacialIdentity.slavic },
      { title: 'Western European', value: this.data[0].clientData.primaryRacialIdentity.westernEuropean },
      { title: 'Other White', value: this.data[0].clientData.primaryRacialIdentity.otherWhite },
      { title: 'American Indian', value: this.data[0].clientData.primaryRacialIdentity.americanIndian },
      { title: 'Alaska Native', value: this.data[0].clientData.primaryRacialIdentity.alaskaNative },
      { title: 'Canadian Innuit, Metis, or First Nation', value: this.data[0].clientData.primaryRacialIdentity.canadianInnuitMetisFirstNation },
      { title: 'Indigenous Mexican, Central American or South American', value: this.data[0].clientData.primaryRacialIdentity.indigenousMexicanCentralAmericanSouthAmerican },
      { title: 'African American', value: this.data[0].clientData.primaryRacialIdentity.africanAmerican },
      { title: 'Afro-Caribbean', value: this.data[0].clientData.primaryRacialIdentity.afroCaribbean },
      { title: 'Ethiopian', value: this.data[0].clientData.primaryRacialIdentity.ethiopian },
      { title: 'Somali', value: this.data[0].clientData.primaryRacialIdentity.somali },
      { title: 'Other African(Black)', value: this.data[0].clientData.primaryRacialIdentity.otherAfrican },
      { title: 'Other Black', value: this.data[0].clientData.primaryRacialIdentity.otherBlack },
      { title: 'Middle Eastern', value: this.data[0].clientData.primaryRacialIdentity.middleEastern },
      { title: 'North African', value: this.data[0].clientData.primaryRacialIdentity.northAfrican },
      { title: 'Asian Indian', value: this.data[0].clientData.primaryRacialIdentity.asianIndian },
      { title: 'Cambodian', value: this.data[0].clientData.primaryRacialIdentity.cambodian },
      { title: 'Chinese', value: this.data[0].clientData.primaryRacialIdentity.chinese },
      { title: 'Communities of Myanmar', value: this.data[0].clientData.primaryRacialIdentity.myanmar },
      { title: 'Filipino/a', value: this.data[0].clientData.primaryRacialIdentity.filipino },
      { title: 'Hmong', value: this.data[0].clientData.primaryRacialIdentity.hmong },
      { title: 'Japanese', value: this.data[0].clientData.primaryRacialIdentity.japanese },
      { title: 'Korean', value: this.data[0].clientData.primaryRacialIdentity.korean },
      { title: 'Laotian', value: this.data[0].clientData.primaryRacialIdentity.laotian },
      { title: 'South Asian', value: this.data[0].clientData.primaryRacialIdentity.southAsian },
      { title: 'Vietnamese', value: this.data[0].clientData.primaryRacialIdentity.vietnamese },
      { title: 'Other Asian', value: this.data[0].clientData.primaryRacialIdentity.otherAsian },
      { title: 'Do not have just one primary racial or ethnic identity', value: this.data[0].clientData.primaryRacialIdentity.multipleIdentities },
      { title: 'No, identify as Biracial or Multiracial', value: this.data[0].clientData.primaryRacialIdentity.biracialMultiRacial },
      { title: 'N/A. Only checked one category above', value: this.data[0].clientData.primaryRacialIdentity.na },
      { title: 'Don\'t Know', value: this.data[0].clientData.primaryRacialIdentity.dontKnowTotal },

      { title: '', value: '' },
      { title: 'Preferred spoken languages', value: '' },
      { title: 'Arabic', value: this.data[0].clientData.spokenLanguage.arabic },
      { title: 'Bosnian', value: this.data[0].clientData.spokenLanguage.bosnian },
      { title: 'Burmese', value: this.data[0].clientData.spokenLanguage.burmese },
      { title: 'Cambodian', value: this.data[0].clientData.spokenLanguage.cambodian },
      { title: 'Chuukese', value: this.data[0].clientData.spokenLanguage.chuukese },
      { title: 'English', value: this.data[0].clientData.spokenLanguage.english },
      { title: 'Farsi', value: this.data[0].clientData.spokenLanguage.farsi },
      { title: 'French', value: this.data[0].clientData.spokenLanguage.french },
      { title: 'German', value: this.data[0].clientData.spokenLanguage.german },
      { title: 'Japanese', value: this.data[0].clientData.spokenLanguage.japanese },
      { title: 'Korean', value: this.data[0].clientData.spokenLanguage.korean },
      { title: 'Lao', value: this.data[0].clientData.spokenLanguage.lao },
      { title: 'Marshallese', value: this.data[0].clientData.spokenLanguage.marshallese },
      { title: 'Oromo (Cushite)', value: this.data[0].clientData.spokenLanguage.oromo },
      { title: 'Pohnpeian', value: this.data[0].clientData.spokenLanguage.pohnpeian },
      { title: 'Romanian', value: this.data[0].clientData.spokenLanguage.romanian },
      { title: 'Russian', value: this.data[0].clientData.spokenLanguage.russian },
      { title: 'Simplified Chinese', value: this.data[0].clientData.spokenLanguage.simplifiedChinese },
      { title: 'Somali', value: this.data[0].clientData.spokenLanguage.somali },
      { title: 'Spanish', value: this.data[0].clientData.spokenLanguage.spanish },
      { title: 'Thai', value: this.data[0].clientData.spokenLanguage.thai },
      { title: 'Traditional Chinese', value: this.data[0].clientData.spokenLanguage.traditionalChinese },
      { title: 'Ukrainian', value: this.data[0].clientData.spokenLanguage.ukrainian },
      { title: 'Vietnamese', value: this.data[0].clientData.spokenLanguage.vietnamese },
      { title: 'Other', value: this.data[0].clientData.spokenLanguage.other },

      { title: '', value: '' },
      { title: 'Preferred written languages', value: '' },
      { title: 'Arabic', value: this.data[0].clientData.writtenLanguage.arabic },
      { title: 'Bosnian', value: this.data[0].clientData.writtenLanguage.bosnian },
      { title: 'Burmese', value: this.data[0].clientData.writtenLanguage.burmese },
      { title: 'Cambodian', value: this.data[0].clientData.writtenLanguage.cambodian },
      { title: 'Chuukese', value: this.data[0].clientData.writtenLanguage.chuukese },
      { title: 'English', value: this.data[0].clientData.writtenLanguage.english },
      { title: 'Farsi', value: this.data[0].clientData.writtenLanguage.farsi },
      { title: 'French', value: this.data[0].clientData.writtenLanguage.french },
      { title: 'German', value: this.data[0].clientData.writtenLanguage.german },
      { title: 'Japanese', value: this.data[0].clientData.writtenLanguage.japanese },
      { title: 'Korean', value: this.data[0].clientData.writtenLanguage.korean },
      { title: 'Lao', value: this.data[0].clientData.writtenLanguage.lao },
      { title: 'Marshallese', value: this.data[0].clientData.writtenLanguage.marshallese },
      { title: 'Oromo (Cushite)', value: this.data[0].clientData.writtenLanguage.oromo },
      { title: 'Pohnpeian', value: this.data[0].clientData.writtenLanguage.pohnpeian },
      { title: 'Romanian', value: this.data[0].clientData.writtenLanguage.romanian },
      { title: 'Russian', value: this.data[0].clientData.writtenLanguage.russian },
      { title: 'Simplified Chinese', value: this.data[0].clientData.writtenLanguage.simplifiedChinese },
      { title: 'Somali', value: this.data[0].clientData.writtenLanguage.somali },
      { title: 'Spanish', value: this.data[0].clientData.writtenLanguage.spanish },
      { title: 'Thai', value: this.data[0].clientData.writtenLanguage.thai },
      { title: 'Traditional Chinese', value: this.data[0].clientData.writtenLanguage.traditionalChinese },
      { title: 'Ukrainian', value: this.data[0].clientData.writtenLanguage.ukrainian },
      { title: 'Vietnamese', value: this.data[0].clientData.writtenLanguage.vietnamese },
      { title: 'Other', value: this.data[0].clientData.writtenLanguage.other },

      { title: '', value: '' },
      { title: 'Clients who are deaf or who have serious difficulty hearing', value: '' },
      { title: 'Yes', value: this.data[0].clientData.disabilityStatus.deaf.yes },
      { title: 'Under 21 Years Old', value: this.data[0].clientData.disabilityStatus.deaf.under21 },
      { title: '21-24 Years Old', value: this.data[0].clientData.disabilityStatus.deaf.age21_24 },
      { title: '25-29 Years Old', value: this.data[0].clientData.disabilityStatus.deaf.age25_29 },
      { title: '30-34 Years Old', value: this.data[0].clientData.disabilityStatus.deaf.age30_34 },
      { title: '35-39 Years Old', value: this.data[0].clientData.disabilityStatus.deaf.age35_39 },
      { title: '40-44 Years Old', value: this.data[0].clientData.disabilityStatus.deaf.age40_44 },
      { title: '45-49 Years Old', value: this.data[0].clientData.disabilityStatus.deaf.age45_49 },
      { title: '50-54 Years Old', value: this.data[0].clientData.disabilityStatus.deaf.age50_54 },
      { title: '55-59 Years Old', value: this.data[0].clientData.disabilityStatus.deaf.age55_59 },
      { title: '60-64 Years Old', value: this.data[0].clientData.disabilityStatus.deaf.age60_64 },
      { title: '65-69 Years Old', value: this.data[0].clientData.disabilityStatus.deaf.age65_69 },
      { title: '70-74 Years Old', value: this.data[0].clientData.disabilityStatus.deaf.age70_74 },
      { title: '75-79 Years Old', value: this.data[0].clientData.disabilityStatus.deaf.age75_79 },
      { title: '80-84 Years Old', value: this.data[0].clientData.disabilityStatus.deaf.age80_84 },
      { title: '≥85 Years Old', value: this.data[0].clientData.disabilityStatus.deaf.age85Older },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.disabilityStatus.deaf.noAgeAnswer },
      { title: 'No, they are not deaf or do not have serious difficulty hearing', value: this.data[0].clientData.disabilityStatus.deaf.no },
      { title: 'Don\'t Know', value: this.data[0].clientData.disabilityStatus.deaf.dontKnow },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.disabilityStatus.deaf.noAnswer },

      { title: '', value: '' },
      { title: 'Clients who are blind or have serious difficulty seeing', value: '' },
      { title: 'Yes', value: this.data[0].clientData.disabilityStatus.blind.yes },
      { title: 'Under 21 Years Old', value: this.data[0].clientData.disabilityStatus.blind.under21 },
      { title: '21-24 Years Old', value: this.data[0].clientData.disabilityStatus.blind.age21_24 },
      { title: '25-29 Years Old', value: this.data[0].clientData.disabilityStatus.blind.age25_29 },
      { title: '30-34 Years Old', value: this.data[0].clientData.disabilityStatus.blind.age30_34 },
      { title: '35-39 Years Old', value: this.data[0].clientData.disabilityStatus.blind.age35_39 },
      { title: '40-44 Years Old', value: this.data[0].clientData.disabilityStatus.blind.age40_44 },
      { title: '45-49 Years Old', value: this.data[0].clientData.disabilityStatus.blind.age45_49 },
      { title: '50-54 Years Old', value: this.data[0].clientData.disabilityStatus.blind.age50_54 },
      { title: '55-59 Years Old', value: this.data[0].clientData.disabilityStatus.blind.age55_59 },
      { title: '60-64 Years Old', value: this.data[0].clientData.disabilityStatus.blind.age60_64 },
      { title: '65-69 Years Old', value: this.data[0].clientData.disabilityStatus.blind.age65_69 },
      { title: '70-74 Years Old', value: this.data[0].clientData.disabilityStatus.blind.age70_74 },
      { title: '75-79 Years Old', value: this.data[0].clientData.disabilityStatus.blind.age75_79 },
      { title: '80-84 Years Old', value: this.data[0].clientData.disabilityStatus.blind.age80_84 },
      { title: '≥85 Years Old', value: this.data[0].clientData.disabilityStatus.blind.age85Older },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.disabilityStatus.blind.noAgeAnswer },
      { title: 'No, they are not blind or do not have serious difficulty seeing', value: this.data[0].clientData.disabilityStatus.blind.no },
      { title: 'Don\'t Know', value: this.data[0].clientData.disabilityStatus.blind.dontKnow },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.disabilityStatus.blind.noAnswer },

      { title: '', value: '' },
      { title: 'Clients who have serious difficulty walking or climbing stairs', value: '' },
      { title: 'Yes', value: this.data[0].clientData.disabilityStatus.difficultyWalking.yes },
      { title: 'Under 21 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyWalking.under21 },
      { title: '21-24 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyWalking.age21_24 },
      { title: '25-29 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyWalking.age25_29 },
      { title: '30-34 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyWalking.age30_34 },
      { title: '35-39 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyWalking.age35_39 },
      { title: '40-44 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyWalking.age40_44 },
      { title: '45-49 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyWalking.age45_49 },
      { title: '50-54 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyWalking.age50_54 },
      { title: '55-59 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyWalking.age55_59 },
      { title: '60-64 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyWalking.age60_64 },
      { title: '65-69 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyWalking.age65_69 },
      { title: '70-74 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyWalking.age70_74 },
      { title: '75-79 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyWalking.age75_79 },
      { title: '80-84 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyWalking.age80_84 },
      { title: '≥85 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyWalking.age85Older },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.disabilityStatus.difficultyWalking.noAgeAnswer },
      { title: 'No, they do not have serious difficulty walking or climbing stairs', value: this.data[0].clientData.disabilityStatus.difficultyWalking.no },
      { title: 'Don\'t Know', value: this.data[0].clientData.disabilityStatus.difficultyWalking.dontKnow },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.disabilityStatus.difficultyWalking.noAnswer },

      { title: '', value: '' },
      { title: 'Clients who have serious difficulty concentrating, remembering or making decisions', value: '' },
      { title: 'Yes', value: this.data[0].clientData.disabilityStatus.difficultyConcentrating.yes },
      { title: 'Under 21 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyConcentrating.under21 },
      { title: '21-24 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyConcentrating.age21_24 },
      { title: '25-29 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyConcentrating.age25_29 },
      { title: '30-34 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyConcentrating.age30_34 },
      { title: '35-39 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyConcentrating.age35_39 },
      { title: '40-44 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyConcentrating.age40_44 },
      { title: '45-49 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyConcentrating.age45_49 },
      { title: '50-54 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyConcentrating.age50_54 },
      { title: '55-59 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyConcentrating.age55_59 },
      { title: '60-64 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyConcentrating.age60_64 },
      { title: '65-69 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyConcentrating.age65_69 },
      { title: '70-74 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyConcentrating.age70_74 },
      { title: '75-79 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyConcentrating.age75_79 },
      { title: '80-84 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyConcentrating.age80_84 },
      { title: '≥85 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyConcentrating.age85Older },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.disabilityStatus.difficultyConcentrating.noAgeAnswer },
      { title: 'No, they do not have serious difficulty concentrating, remembering or making decisions', value: this.data[0].clientData.disabilityStatus.difficultyConcentrating.no },
      { title: 'Don\'t Know', value: this.data[0].clientData.disabilityStatus.difficultyConcentrating.dontKnow },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.disabilityStatus.difficultyConcentrating.noAnswer },

      { title: '', value: '' },
      { title: 'Clients who have difficulty dressing or bathing', value: '' },
      { title: 'Yes', value: this.data[0].clientData.disabilityStatus.difficultyDressing.yes },
      { title: 'Under 21 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyDressing.under21 },
      { title: '21-24 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyDressing.age21_24 },
      { title: '25-29 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyDressing.age25_29 },
      { title: '30-34 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyDressing.age30_34 },
      { title: '35-39 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyDressing.age35_39 },
      { title: '40-44 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyDressing.age40_44 },
      { title: '45-49 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyDressing.age45_49 },
      { title: '50-54 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyDressing.age50_54 },
      { title: '55-59 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyDressing.age55_59 },
      { title: '60-64 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyDressing.age60_64 },
      { title: '65-69 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyDressing.age65_69 },
      { title: '70-74 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyDressing.age70_74 },
      { title: '75-79 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyDressing.age75_79 },
      { title: '80-84 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyDressing.age80_84 },
      { title: '≥85 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyDressing.age85Older },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.disabilityStatus.difficultyDressing.noAgeAnswer },
      { title: 'No, they do not have difficulty dressing or bathing', value: this.data[0].clientData.disabilityStatus.difficultyDressing.no },
      { title: 'Don\'t Know', value: this.data[0].clientData.disabilityStatus.difficultyDressing.dontKnow },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.disabilityStatus.difficultyDressing.noAnswer },

      { title: '', value: '' },
      { title: 'Clients who have serious difficulty learning how to do things most people their age can learn', value: '' },
      { title: 'Yes', value: this.data[0].clientData.disabilityStatus.difficultyLearning.yes },
      { title: 'Under 21 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyLearning.under21 },
      { title: '21-24 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyLearning.age21_24 },
      { title: '25-29 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyLearning.age25_29 },
      { title: '30-34 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyLearning.age30_34 },
      { title: '35-39 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyLearning.age35_39 },
      { title: '40-44 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyLearning.age40_44 },
      { title: '45-49 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyLearning.age45_49 },
      { title: '50-54 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyLearning.age50_54 },
      { title: '55-59 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyLearning.age55_59 },
      { title: '60-64 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyLearning.age60_64 },
      { title: '65-69 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyLearning.age65_69 },
      { title: '70-74 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyLearning.age70_74 },
      { title: '75-79 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyLearning.age75_79 },
      { title: '80-84 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyLearning.age80_84 },
      { title: '≥85 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyLearning.age85Older },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.disabilityStatus.difficultyLearning.noAgeAnswer },
      { title: 'No, they do not have serious difficulty learning how to do things most people their age can learn', value: this.data[0].clientData.disabilityStatus.difficultyLearning.no },
      { title: 'Don\'t Know', value: this.data[0].clientData.disabilityStatus.difficultyLearning.dontKnow },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.disabilityStatus.difficultyLearning.noAnswer },

      { title: '', value: '' },
      { title: 'Clients who have serious difficulty communicating', value: '' },
      { title: 'Yes', value: this.data[0].clientData.disabilityStatus.difficultyCommunicating.yes },
      { title: 'Under 21 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyCommunicating.under21 },
      { title: '21-24 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyCommunicating.age21_24 },
      { title: '25-29 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyCommunicating.age25_29 },
      { title: '30-34 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyCommunicating.age30_34 },
      { title: '35-39 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyCommunicating.age35_39 },
      { title: '40-44 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyCommunicating.age40_44 },
      { title: '45-49 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyCommunicating.age45_49 },
      { title: '50-54 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyCommunicating.age50_54 },
      { title: '55-59 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyCommunicating.age55_59 },
      { title: '60-64 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyCommunicating.age60_64 },
      { title: '65-69 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyCommunicating.age65_69 },
      { title: '70-74 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyCommunicating.age70_74 },
      { title: '75-79 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyCommunicating.age75_79 },
      { title: '80-84 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyCommunicating.age80_84 },
      { title: '≥85 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyCommunicating.age85Older },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.disabilityStatus.difficultyCommunicating.noAgeAnswer },
      { title: 'No, they do not have serious difficulty communicating', value: this.data[0].clientData.disabilityStatus.difficultyCommunicating.no },
      { title: 'Don\'t Know', value: this.data[0].clientData.disabilityStatus.difficultyCommunicating.dontKnow },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.disabilityStatus.difficultyCommunicating.noAnswer },
      { title: 'Don\'t know what this question is asking', value: this.data[0].clientData.disabilityStatus.difficultyCommunicating.dontUnderstand },


      { title: '', value: '' },
      { title: 'Clients who have difficulty doing errands alone', value: '' },
      { title: 'Yes', value: this.data[0].clientData.disabilityStatus.difficultyErrands.yes },
      { title: 'Under 21 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyErrands.under21 },
      { title: '21-24 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyErrands.age21_24 },
      { title: '25-29 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyErrands.age25_29 },
      { title: '30-34 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyErrands.age30_34 },
      { title: '35-39 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyErrands.age35_39 },
      { title: '40-44 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyErrands.age40_44 },
      { title: '45-49 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyErrands.age45_49 },
      { title: '50-54 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyErrands.age50_54 },
      { title: '55-59 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyErrands.age55_59 },
      { title: '60-64 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyErrands.age60_64 },
      { title: '65-69 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyErrands.age65_69 },
      { title: '70-74 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyErrands.age70_74 },
      { title: '75-79 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyErrands.age75_79 },
      { title: '80-84 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyErrands.age80_84 },
      { title: '≥85 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyErrands.age85Older },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.disabilityStatus.difficultyErrands.noAgeAnswer },
      { title: 'No, they do not have difficulty doing errands alone', value: this.data[0].clientData.disabilityStatus.difficultyErrands.no },
      { title: 'Don\'t Know', value: this.data[0].clientData.disabilityStatus.difficultyErrands.dontKnow },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.disabilityStatus.difficultyErrands.noAnswer },

      { title: '', value: '' },
      { title: 'Clients who have serious difficulty with the following: mood, intense feelings, controlling their behavior, or experiencing delusions or hallucinations', value: '' },
      { title: 'Yes', value: this.data[0].clientData.disabilityStatus.difficultyMoods.yes },
      { title: 'Under 21 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyMoods.under21 },
      { title: '21-24 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyMoods.age21_24 },
      { title: '25-29 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyMoods.age25_29 },
      { title: '30-34 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyMoods.age30_34 },
      { title: '35-39 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyMoods.age35_39 },
      { title: '40-44 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyMoods.age40_44 },
      { title: '45-49 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyMoods.age45_49 },
      { title: '50-54 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyMoods.age50_54 },
      { title: '55-59 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyMoods.age55_59 },
      { title: '60-64 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyMoods.age60_64 },
      { title: '65-69 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyMoods.age65_69 },
      { title: '70-74 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyMoods.age70_74 },
      { title: '75-79 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyMoods.age75_79 },
      { title: '80-84 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyMoods.age80_84 },
      { title: '≥85 Years Old', value: this.data[0].clientData.disabilityStatus.difficultyMoods.age85Older },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.disabilityStatus.difficultyMoods.noAgeAnswer },
      { title: 'No, they do not have serious difficulty with mood, intense feelings, controlling their behavior, or experiencing delusions or hallucinations', value: this.data[0].clientData.disabilityStatus.difficultyMoods.no },
      { title: 'Don\'t Know', value: this.data[0].clientData.disabilityStatus.difficultyMoods.dontKnow },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.disabilityStatus.difficultyMoods.noAnswer },
      { title: 'Don\'t know what this question is asking', value: this.data[0].clientData.disabilityStatus.difficultyMoods.dontUnderstand },

      { title: '', value: '' },
      { title: 'Gender Identity', value: '' },
      { title: 'Girl or Woman', value: this.data[0].clientData.genderIdentity.woman },
      { title: 'Boy or Man', value: this.data[0].clientData.genderIdentity.man },
      { title: 'NonBinary', value: this.data[0].clientData.genderIdentity.nonBinary },
      { title: 'Agender/No gender', value: this.data[0].clientData.genderIdentity.agender },
      { title: 'Bigender', value: this.data[0].clientData.genderIdentity.bigender },
      { title: 'Demiboy', value: this.data[0].clientData.genderIdentity.demiboy },
      { title: 'Demigirl', value: this.data[0].clientData.genderIdentity.demigirl },
      { title: 'Genderfluid', value: this.data[0].clientData.genderIdentity.genderfluid },
      { title: 'Genderqueer', value: this.data[0].clientData.genderIdentity.genderqueer },
      { title: 'Questioning/Exploring', value: this.data[0].clientData.genderIdentity.questioning },
      { title: 'Not Listed', value: this.data[0].clientData.genderIdentity.notListed },
      { title: 'I have a gender identity not listed here that is specific to my ethnicity', value: this.data[0].clientData.genderIdentity.specificToEthnicity },
      { title: 'Don\'t Know', value: this.data[0].clientData.genderIdentity.dontKnow },
      { title: 'Don\'t know what this question is asking', value: this.data[0].clientData.genderIdentity.dontUnderstand },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.genderIdentity.noAnswer },
      { title: 'Number of clients who indicated that they are transgender:', value: '' },
      { title: 'Yes', value: this.data[0].clientData.genderIdentity.yesTransgender },
      { title: 'No', value: this.data[0].clientData.genderIdentity.noTransgender },
      { title: 'Questioning/Exploring', value: this.data[0].clientData.genderIdentity.questioningTransgender },
      { title: 'Don\'t Know', value: this.data[0].clientData.genderIdentity.dontKnowTransgender },
      { title: 'Don\'t know what this question is asking', value: this.data[0].clientData.genderIdentity.dontUnderstandTransgender },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.genderIdentity.noAnswerTransgender },

      { title: '', value: '' },
      { title: 'Clients who indicated their sex', value: '' },
      { title: 'Female', value: this.data[0].clientData.gender.female },
      { title: 'Male', value: this.data[0].clientData.gender.male },
      { title: 'Intersex', value: this.data[0].clientData.gender.intersex },
      { title: 'Don\'t Know', value: this.data[0].clientData.gender.dontKnow },
      { title: 'Don\'t know what this question is asking', value: this.data[0].clientData.gender.dontUnderstand },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.gender.noAnswer },

      { title: '', value: '' },
      { title: 'Clients who indicated their sexual orientation', value: '' },
      { title: 'Same-gender Loving', value: this.data[0].clientData.sexualOrientation.sameGenderLoving },
      { title: 'Lesbian', value: this.data[0].clientData.sexualOrientation.lesbian },
      { title: 'Gay', value: this.data[0].clientData.sexualOrientation.gay },
      { title: 'Bisexual', value: this.data[0].clientData.sexualOrientation.bisexual },
      { title: 'Pansexual', value: this.data[0].clientData.sexualOrientation.pansexual },
      { title: 'Straight or heterosexual', value: this.data[0].clientData.sexualOrientation.straight },
      { title: 'Asexual Spectrum', value: this.data[0].clientData.sexualOrientation.asexual },
      { title: 'Queer', value: this.data[0].clientData.sexualOrientation.queer },
      { title: 'Questioning/Exploring', value: this.data[0].clientData.sexualOrientation.questioning },
      { title: 'Not Listed', value: this.data[0].clientData.sexualOrientation.notListed },
      { title: 'Don\'t Know', value: this.data[0].clientData.sexualOrientation.dontKnow },
      { title: 'Don\'t know what this question is asking', value: this.data[0].clientData.sexualOrientation.dontUnderstand },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.sexualOrientation.noAnswer },

      { title: '', value: '' },
      { title: 'Client annual income', value: '' },
      { title: '$0 - $11,000', value: this.data[0].clientData.income.income0_11000 },
      { title: '$11,001 - $44,725', value: this.data[0].clientData.income.income11001_44725 },
      { title: '$44,726 - $95,375', value: this.data[0].clientData.income.income44726_95375 },
      { title: '$95,376 - $182,100', value: this.data[0].clientData.income.income95376_182100 },
      { title: '$182,101 - $231,250', value: this.data[0].clientData.income.income182101_231250 },
      { title: '$231,251 - $578,125', value: this.data[0].clientData.income.income231251_578125 },
      { title: '$578,126+', value: this.data[0].clientData.income.income578126More },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.income.noAnswer },


      { title: '', value: '' },
      { title: 'Number of clients by age group', value: '' },
      { title: 'Under 21 Years Old', value: this.data[0].clientData.under21 },
      { title: '21-24 Years Old', value: this.data[0].clientData.age21_24 },
      { title: '25-29 Years Old', value: this.data[0].clientData.age25_29 },
      { title: '30-34 Years Old', value: this.data[0].clientData.age30_34 },
      { title: '35-39 Years Old', value: this.data[0].clientData.age35_39 },
      { title: '40-44 Years Old', value: this.data[0].clientData.age40_44 },
      { title: '45-49 Years Old', value: this.data[0].clientData.age45_49 },
      { title: '50-54 Years Old', value: this.data[0].clientData.age50_54 },
      { title: '55-59 Years Old', value: this.data[0].clientData.age55_59 },
      { title: '60-64 Years Old', value: this.data[0].clientData.age60_64 },
      { title: '65-69 Years Old', value: this.data[0].clientData.age65_69 },
      { title: '70-74 Years Old', value: this.data[0].clientData.age70_74 },
      { title: '75-79 Years Old', value: this.data[0].clientData.age75_79 },
      { title: '80-84 Years Old', value: this.data[0].clientData.age80_84 },
      { title: '≥85 Years Old', value: this.data[0].clientData.age85Older },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.noAgeAnswer },

      { title: '', value: '' },
      { title: 'Number of clients by county of residence', value: '' },
      { title: 'Baker', value: this.data[0].clientData.county.baker },
      { title: 'Benton', value: this.data[0].clientData.county.benton },
      { title: 'Clackamas', value: this.data[0].clientData.county.clackamas },
      { title: 'Clatsop', value: this.data[0].clientData.county.clatsop },
      { title: 'Columbia', value: this.data[0].clientData.county.columbia },
      { title: 'Coos', value: this.data[0].clientData.county.coos },
      { title: 'Crook', value: this.data[0].clientData.county.crook },
      { title: 'Curry', value: this.data[0].clientData.county.curry },
      { title: 'Deschutes', value: this.data[0].clientData.county.deschutes },
      { title: 'Douglas', value: this.data[0].clientData.county.douglas },
      { title: 'Gilliam', value: this.data[0].clientData.county.gilliam },
      { title: 'Grant', value: this.data[0].clientData.county.grant },
      { title: 'Harney', value: this.data[0].clientData.county.harney },
      { title: 'Hood River', value: this.data[0].clientData.county.hoodRiver },
      { title: 'Jackson', value: this.data[0].clientData.county.jackson },
      { title: 'Jefferson', value: this.data[0].clientData.county.jefferson },
      { title: 'Josephine', value: this.data[0].clientData.county.josephine },
      { title: 'Klamath', value: this.data[0].clientData.county.klamath },
      { title: 'Lake', value: this.data[0].clientData.county.lake },
      { title: 'Lane', value: this.data[0].clientData.county.lane },
      { title: 'Lincoln', value: this.data[0].clientData.county.lincoln },
      { title: 'Linn', value: this.data[0].clientData.county.linn },
      { title: 'Malheur', value: this.data[0].clientData.county.malheur },
      { title: 'Marion', value: this.data[0].clientData.county.marion },
      { title: 'Morrow', value: this.data[0].clientData.county.morrow },
      { title: 'Multnomah', value: this.data[0].clientData.county.multnomah },
      { title: 'Polk', value: this.data[0].clientData.county.polk },
      { title: 'Sherman', value: this.data[0].clientData.county.sherman },
      { title: 'Tillamook', value: this.data[0].clientData.county.tillamook },
      { title: 'Umatilla', value: this.data[0].clientData.county.umatilla },
      { title: 'Union', value: this.data[0].clientData.county.union },
      { title: 'Wallowa', value: this.data[0].clientData.county.wallowa },
      { title: 'Wasco', value: this.data[0].clientData.county.wasco },
      { title: 'Washington', value: this.data[0].clientData.county.washington },
      { title: 'Wheeler', value: this.data[0].clientData.county.wheeler },
      { title: 'Yamhill', value: this.data[0].clientData.county.yamhill },
      { title: 'Other location within the United States of America', value: this.data[0].clientData.county.otherInsideUS },
      { title: 'Other location outside the United States of America', value: this.data[0].clientData.county.outsideUS },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.county.noAnswer },

      { title: '', value: '' },
      { title: 'Clients\' reasons for which they requested psilocybin services', value: '' },
      { title: 'Addiction or tobacco, alcohol or substance use', value: this.data[0].clientData.visitType.addiction },
      { title: 'Anxiety', value: this.data[0].clientData.visitType.anxiety },
      { title: 'Depression', value: this.data[0].clientData.visitType.depression },
      { title: 'Mental Health Diagnosis', value: this.data[0].clientData.visitType.mentalHealthDiagnosis },
      { title: 'Undiagnosed Mental Health', value: this.data[0].clientData.visitType.undiagnosedMentalHealth },
      { title: 'End-of-life psychological distress', value: this.data[0].clientData.visitType.endOfLife },
      { title: 'Chronic Pain', value: this.data[0].clientData.visitType.chronicPain },
      { title: 'Brain Injury', value: this.data[0].clientData.visitType.brainInjury },
      { title: 'General well-being', value: this.data[0].clientData.visitType.generalWellness },
      { title: 'Access to culturally responsive care', value: this.data[0].clientData.visitType.responsiveCare },
      { title: 'Spirituality or religious reasons', value: this.data[0].clientData.visitType.spirituality },
      { title: 'Racial or ethnicity based trauma', value: this.data[0].clientData.visitType.racialTrauma },
      { title: 'Gender or sexuality based trauma', value: this.data[0].clientData.visitType.genderTrauma },
      { title: 'Trauma related to domestic violence or sexual assault', value: this.data[0].clientData.visitType.domesticViolenceTrauma },
      { title: 'Trauma related to combat or military service', value: this.data[0].clientData.visitType.combatTrauma },
      { title: 'Trauma related to colonization, relocation or displacement', value: this.data[0].clientData.visitType.colonizationTrauma },
      { title: 'Post Traumatic Stress Disorder (PTSD)', value: this.data[0].clientData.visitType.ptsd },
      { title: 'Other Trauma', value: this.data[0].clientData.visitType.otherTrauma },
      { title: 'Other Reasons', value: this.data[0].clientData.visitType.otherReasons },
      { title: 'Don\'t Know', value: this.data[0].clientData.visitType.dontKnow },
      { title: 'Don\'t want to answer', value: this.data[0].clientData.visitType.noAnswer },
    ];

  }


  ngAfterViewInit(): void {
    setTimeout(() => {
      this.excelExport.save();
      setTimeout(() => {
        this.dialogRef.close();
      }, 2000);
    }, 1000);
  }

}
