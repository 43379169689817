<mat-card>
  <mat-card-title>
    Compliance Tracking
  </mat-card-title>
  <mat-tab-group animationDuration="150ms" >
    <mat-tab label="Site Inspections" [hidden]="loadingSiteInspections">
      <div fxLayout="row" fxLayoutGap="12px">
        <div fxLayout="row" fxLayoutGap="12px">
          <mat-form-field>
            <input matInput (keyup)="filterSiteInspections($event, 'licenseName')" placeholder="License Name" />
          </mat-form-field>

          <mat-form-field>
            <input matInput (keyup)="filterSiteInspections($event, 'entityId')" placeholder="License ID" />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select (selectionChange)="filterSiteInspectionsStatus($event, 'status')" placeholder="Select Status">
              <mat-option value=""></mat-option>
              <mat-option value="New">New</mat-option>
              <mat-option value="Working">Working</mat-option>
              <mat-option value="Complete">Complete</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <mat-table [dataSource]="siteInspectionSource" matSort aria-describedby="Site Inspections" #siteInspectionSort="matSort" class="mat-elevation-z8 m-b-0">

        <ng-container matColumnDef="licenseName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>License</mat-header-cell>
          <mat-cell *matCellDef="let inspection" [routerLink]="['/' + inspection.entityType + '/assigned', inspection.licenseId]">
            <span class="mobile-label">License</span>
            <div fxLayout="row" fxLayoutGap="4px">
              <span>{{inspection.licenseName}}</span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="entityId">
          <mat-header-cell *matHeaderCellDef mat-sort-header>License Id</mat-header-cell>
          <mat-cell *matCellDef="let inspection" [routerLink]="['/' + inspection.entityType + '/assigned', inspection.licenseId]">
            <span class="mobile-label">License Id</span>
            <div fxLayout="row" fxLayoutGap="4px">
              <span>{{inspection.entityId}}</span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="caseId">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Site Inspection Number</mat-header-cell>
          <mat-cell *matCellDef="let inspection" [routerLink]="['/' + inspection.entityType + '/assigned', inspection.licenseId]">
            <span class="mobile-label">Site Inspection Number</span>
            <div fxLayout="row" fxLayoutGap="4px">
              <span>{{inspection.caseId}}</span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
          <mat-cell *matCellDef="let inspection" [routerLink]="['/' + inspection.entityType + '/assigned', inspection.licenseId]">
            <span class="mobile-label">Status</span>
            <div fxLayout="row" fxLayoutGap="4px">
              <span>{{inspection.status}}</span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
          <mat-cell *matCellDef="let inspection" [routerLink]="['/' + inspection.entityType + '/assigned', inspection.licenseId]">
            <span class="mobile-label">Type</span>
            <div fxLayout="row" fxLayoutGap="4px">
              <span>{{inspection.type}}</span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="visitDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Visit Date</mat-header-cell>
          <mat-cell *matCellDef="let inspection" [routerLink]="['/' + inspection.entityType + '/assigned', inspection.licenseId]">
            <span class="mobile-label">Visit Date</span>
            <div fxLayout="row" fxLayoutGap="4px">
              <span>{{ inspection.visitDate | date }}</span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="followupAge">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Days Since Followup</mat-header-cell>
          <mat-cell *matCellDef="let inspection">
            <span *ngIf="inspection.followupAge != ''">{{ inspection.followupAge }} days</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="assignedTo">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To</mat-header-cell>
          <mat-cell *matCellDef="let inspection">
            <span class="mobile-label">Assigned To</span>
            <mat-form-field style="margin-top: -1em; margin-bottom: -1.25em" *ngIf="authService.isAdmin">
               <mat-select [(value)]="inspection.assignedTo" (selectionChange)="assignSiteInspection($event, inspection.id)">
                <mat-option>-- None --</mat-option>
                <mat-option *ngFor="let user of staff" [value]="user.id">{{user.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <span *ngIf="!authService.isAdmin">{{staffReference[inspection.assignedTo]}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="manager">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Manager</mat-header-cell>
          <mat-cell *matCellDef="let inspection">
            <span class="mobile-label">Manager</span>
            <mat-form-field style="margin-top: -1em; margin-bottom: -1.25em" *ngIf="authService.isAdmin">
              <mat-select [(value)]="inspection.manager" (selectionChange)="assignSiteInspectionManager($event, inspection.id)">
               <mat-option>-- None --</mat-option>
               <mat-option *ngFor="let user of managers" [value]="user.id">{{user.name}}</mat-option>
             </mat-select>
           </mat-form-field>
           <span *ngIf="!authService.isAdmin">{{managerReference[inspection.manager]}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="locked">
          <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell *matCellDef="let inspection">
            <div fxLayout="row" fxLayoutGap="4px">
              <mat-icon *ngIf="inspection.locked" color="warn">lock</mat-icon>
              <mat-icon *ngIf="!inspection.locked">lock_open</mat-icon>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="managerFlag">
          <mat-header-cell *matHeaderCellDef mat-sort-header="">
            Alerts
          </mat-header-cell>
          <mat-cell *matCellDef="let inspection">
            <div fxLayout="row" fxLayoutGap="12px">
              <mat-icon style="color:red" *ngIf="inspection.managerFlag == 'red' && authService.isAdmin">flag</mat-icon>
              <mat-icon style="color:blue" *ngIf="inspection.managerFlag == 'blue'">flag</mat-icon>
              <mat-icon style="color:rgb(229, 238, 65)" *ngIf="inspection.managerFlag == 'yellow'">flag</mat-icon>
              <mat-icon *ngIf="inspection.managerFlag == 'blue' || inspection.managerFlag == 'yellow' || authService.isAdmin" (click)="openManagerNotesInspection(inspection)" >more_horiz</mat-icon>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="siteInspectionColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: siteInspectionColumns" fxLayoutGap="12px"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[25, 50, 100]" #siteInspectionPaginator="matPaginator">
      </mat-paginator>
      <div *ngIf="loadingSiteInspections">
        <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
          <h1>Loading</h1>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Compliance Cases" [hidden]="loadingCases" *ngIf="authService.isAdmin">
      <div fxLayout="row" fxLayoutGap="12px">
        <div fxLayout="row" fxLayoutGap="12px">
          <mat-form-field>
            <input matInput (keyup)="filterCases($event, 'licenseName')" placeholder="License Name" />
          </mat-form-field>

          <mat-form-field>
            <input matInput (keyup)="filterCases($event, 'entityId')" placeholder="License ID" />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select (selectionChange)="filterCasesStatus($event, 'status')" placeholder="Select Status">
              <mat-option value=""></mat-option>
              <mat-option value="Submitted">Submitted</mat-option>
              <mat-option value="Open">Open</mat-option>
              <mat-option value="Closed">Closed</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutAlign="center center">
          <button mat-flat-button type="button" color="primary" (click)="toggleClosedCases()">
            {{ showClosedCases ? 'Hide Closed Cases' : 'Show Closed Cases' }}
          </button>
        </div>
      </div>

      <mat-table [dataSource]="caseSource" matSort aria-describedby="Cases" #caseSort="matSort" class="mat-elevation-z8 m-b-0">

        <ng-container matColumnDef="licenseName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>License</mat-header-cell>
          <mat-cell *matCellDef="let case" [routerLink]="['/' + case.entityType + '/assigned', case.licenseId]">
            <span class="mobile-label">License</span>
            <div fxLayout="row" fxLayoutGap="4px">
              <span>{{case.licenseName}}</span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="entityId">
          <mat-header-cell *matHeaderCellDef mat-sort-header>License Id</mat-header-cell>
          <mat-cell *matCellDef="let case" [routerLink]="['/' + case.entityType + '/assigned', case.licenseId]">
            <span class="mobile-label">License Id</span>
            <div fxLayout="row" fxLayoutGap="4px">
              <span>{{case.entityId}}</span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="caseId">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Case Number</mat-header-cell>
          <mat-cell *matCellDef="let case" [routerLink]="['/' + case.entityType + '/assigned', case.licenseId]">
            <span class="mobile-label">Case Number</span>
            <div fxLayout="row" fxLayoutGap="4px">
              <span>{{case.caseId}}</span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dateOfNonCompliance">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Date of Non-Compliance</mat-header-cell>
          <mat-cell *matCellDef="let case" [routerLink]="['/' + case.entityType + '/assigned', case.licenseId]">
            <span class="mobile-label">Date Of Non-Compliance</span>
            <div fxLayout="row" fxLayoutGap="4px">
              <span>{{ case.dateOfNonCompliance | date }}</span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
          <mat-cell *matCellDef="let case" [routerLink]="['/' + case.entityType + '/assigned', case.licenseId]">
            <span class="mobile-label">Status</span>
            <div fxLayout="row" fxLayoutGap="4px">
              <span>{{case.status}}</span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="category">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Category</mat-header-cell>
          <mat-cell *matCellDef="let case" [routerLink]="['/' + case.entityType + '/assigned', case.licenseId]">
            <span class="mobile-label">Category</span>
            <div fxLayout="row" fxLayoutGap="4px">
              <span>{{case.category}}</span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tier">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Tier</mat-header-cell>
          <mat-cell *matCellDef="let case" [routerLink]="['/' + case.entityType + '/assigned', case.licenseId]">
            <span class="mobile-label">Tier</span>
            <div fxLayout="row" fxLayoutGap="4px">
              <span>{{case.tier}}</span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Notice Type</mat-header-cell>
          <mat-cell *matCellDef="let case" [routerLink]="['/' + case.entityType + '/assigned', case.licenseId]">
            <span class="mobile-label">Type</span>
            <div fxLayout="row" fxLayoutGap="4px">
              <span>{{case.type}}</span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dueDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Notice Due Date</mat-header-cell>
          <mat-cell *matCellDef="let case" [routerLink]="['/' + case.entityType + '/assigned', case.licenseId]">
            <span class="mobile-label">Notice Due Date</span>
            <div fxLayout="row" fxLayoutGap="4px">
              <span>{{case.dueDate | date}}</span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="outcome">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Outcome</mat-header-cell>
          <mat-cell *matCellDef="let case" [routerLink]="['/' + case.entityType + '/assigned', case.licenseId]">
            <span class="mobile-label">Outcome</span>
            <div fxLayout="row" fxLayoutGap="4px">
              <span>{{case.outcome}}</span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dateOfCompliance">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Date of Compliance</mat-header-cell>
          <mat-cell *matCellDef="let case" [routerLink]="['/' + case.entityType + '/assigned', case.licenseId]">
            <span class="mobile-label">Date of Compliance</span>
            <div fxLayout="row" fxLayoutGap="4px">
              <span>{{ case.dateOfCompliance | date }}</span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="assignedTo">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To</mat-header-cell>
          <mat-cell *matCellDef="let case">
            <span class="mobile-label">Assigned To</span>
            <mat-form-field style="margin-top: -1em; margin-bottom: -1.25em" *ngIf="authService.isAdmin">
               <mat-select [(value)]="case.assignedTo" (selectionChange)="assignCase($event, case.id)">
                <mat-option>-- None --</mat-option>
                <mat-option *ngFor="let user of staff" [value]="user.id">{{user.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <span *ngIf="!authService.isAdmin">{{staffReference[case.assignedTo]}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="manager">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Manager</mat-header-cell>
          <mat-cell *matCellDef="let case">
            <span class="mobile-label">Manager</span>
            <mat-form-field style="margin-top: -1em; margin-bottom: -1.25em" *ngIf="authService.isAdmin">
              <mat-select [(value)]="case.manager" (selectionChange)="assignCaseManager($event, case.id)">
               <mat-option>-- None --</mat-option>
               <mat-option *ngFor="let user of managers" [value]="user.id">{{user.name}}</mat-option>
             </mat-select>
           </mat-form-field>
           <span *ngIf="!authService.isAdmin">{{managerReference[case.manager]}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="agencyGenerated">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Agency Generated</mat-header-cell>
          <mat-cell *matCellDef="let case">
            <span class="mobile-label">Agency Generated</span>
            <div style="display: flex; justify-content: center; align-items: center; width: 100%;">
              <mat-checkbox [checked]="case.agencyGenerated" disabled></mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="locked">
          <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell *matCellDef="let case">
            <div fxLayout="row" fxLayoutGap="4px">
              <mat-icon *ngIf="case.locked" color="warn">lock</mat-icon>
              <mat-icon *ngIf="!case.locked">lock_open</mat-icon>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="managerFlag">
          <mat-header-cell *matHeaderCellDef mat-sort-header="">
            Alerts
          </mat-header-cell>
          <mat-cell *matCellDef="let case">
            <div fxLayout="row" fxLayoutGap="12px">
              <mat-icon style="color:red" *ngIf="case.managerFlag == 'red' && authService.isAdmin">flag</mat-icon>
              <mat-icon style="color:blue" *ngIf="case.managerFlag == 'blue'">flag</mat-icon>
              <mat-icon style="color:rgb(229, 238, 65)" *ngIf="case.managerFlag == 'yellow'">flag</mat-icon>
              <mat-icon *ngIf="case.managerFlag == 'blue' || case.managerFlag == 'yellow' || authService.isAdmin" (click)="openManagerNotesCases(case)" >more_horiz</mat-icon>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="caseColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: caseColumns" fxLayoutGap="12px"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[25, 50, 100]" #casePaginator="matPaginator">
      </mat-paginator>

      <div *ngIf="loadingCases">
        <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
          <h1>Loading</h1>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Complaints" [hidden]="loadingComplaints" *ngIf="authService.isAdmin">

      <div fxLayout="row" fxLayoutGap="12px">
        <mat-form-field>
          <input matInput (keyup)="filterComplaints($event, 'parentLicenseName')" placeholder="License Name" />
        </mat-form-field>

        <mat-form-field>
          <input matInput (keyup)="filterComplaints($event, 'parentId')" placeholder="License ID" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Status</mat-label>
          <mat-select (selectionChange)="filterComplaintsStatus($event, 'status')" placeholder="Select Status">
            <mat-option value=""></mat-option>
            <mat-option value="Associated">Associated</mat-option>
            <mat-option value="New">New</mat-option>
            <mat-option value="Working">Working</mat-option>
            <mat-option value="Closed">Closed</mat-option>
            <mat-option value="Submitted">Submitted</mat-option>
            <mat-option value="Under Review">Under Review</mat-option>
            <mat-option value="Ready for Compliance Manager Review">Ready for Compliance Manager Review</mat-option>
            <mat-option value="Under Investigation">Under Investigation</mat-option>
            <mat-option value="Complaint Closed">Complaint Closed</mat-option>
            <mat-option value="Investigation Closed">Investigation Closed</mat-option>
            <mat-option value="Notice Issued">Notice Issued</mat-option>
            <mat-option value="Hearing Requested">Hearing Requested</mat-option>
            <mat-option value="Proposed Order Issued">Proposed Order Issued</mat-option>
            <mat-option value="Exceptions Submitted">Exceptions Submitted</mat-option>
            <mat-option value="Final Order Issued">Final Order Issued</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-table [dataSource]="complaintSource" matSort #complaintSort="matSort" aria-describedby="complaints"
      class="mat-elevation-z8">
      <ng-container matColumnDef="dateCreated">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Date Entered</mat-header-cell>
        <mat-cell *matCellDef="let complaint">
          <span class="mobile-label">Date Entered</span>
          {{ complaint.dateCreated | date }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let complaint">
          <span class="mobile-label">Name</span>
          {{ complaint.name }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="parentLicenseName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>License Name</mat-header-cell>
        <mat-cell *matCellDef="let complaint">
          <span class="mobile-label">License Name</span>
          {{ complaint.parentLicenseName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="parentId">
        <mat-header-cell *matHeaderCellDef mat-sort-header>License Id</mat-header-cell>
        <mat-cell *matCellDef="let complaint">
          <span class="mobile-label">License Id</span>
          <span>{{ complaint.parentId }}</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
        <mat-cell *matCellDef="let complaint">
          <span class="mobile-label">Status</span>
          <span>{{ complaint.status }}</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="complaintText">
        <mat-header-cell *matHeaderCellDef>Complaint</mat-header-cell>
        <mat-cell *matCellDef="let complaint">
          <span class="mobile-label">Complaint</span>
          <span style="white-space: pre-wrap">{{complaint.complaintText}}</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="contactEmail">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
        <mat-cell *matCellDef="let complaint">
          <span class="mobile-label">Email</span>
          <span [ngStyle]="{'max-width': sharedService.isMobile? '150px': '150px'}"
            style="word-wrap: break-word; white-space: normal">
            <div fxLayout="column">
              <span>{{ complaint.contactEmail }}</span>
              <span>{{ complaint.preferredContact === 'Email'? 'Preferred' : '' }}</span>
            </div>
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="contactNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Phone</mat-header-cell>
        <mat-cell *matCellDef="let complaint">
          <span class="mobile-label">Phone</span>
          <div fxLayout="column">
            <span>{{ complaint.contactNumber | mask: '(000) 000-0000' }}</span>
            <span>{{ complaint.preferredContact === 'Phone'? 'Preferred' : '' }}</span>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="caseId">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Case Id</mat-header-cell>
        <mat-cell *matCellDef="let complaint">
          <span class="mobile-label">Case Id</span>
          <div fxLayout="column">
            <span>{{ complaint.caseId}}</span>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="dueDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Notice Due Date</mat-header-cell>
        <mat-cell *matCellDef="let complaint">
          <span class="mobile-label">Notice Due Date</span>
          <div fxLayout="column">
            <span>{{ complaint.dueDate | date }}</span>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="complaintColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: complaintColumns" (click)="editComplaint(row)">
      </mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[25, 50, 100]" #complaintPaginator="matPaginator">
    </mat-paginator>

      <div *ngIf="loadingComplaints">
        <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
          <h1>Loading</h1>
        </div>
      </div>
    </mat-tab>

  </mat-tab-group>
</mat-card>
