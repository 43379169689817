import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ExcelExportComponent } from "@progress/kendo-angular-excel-export";
import { ISB303 } from "app/models/303/sb303";

@Component({
  selector: 'app-dialog-download-service-center-data',
  templateUrl: './dialog-download-service-center-data.component.html',
  styleUrls: ['./dialog-download-service-center-data.component.scss']
})
export class DialogDownloadServiceCenterDataComponent implements OnInit {
  @ViewChild('excelExport', { static: false }) excelExport: ExcelExportComponent;
  public gridData: any[] = [];

  constructor(public dialogRef: MatDialogRef<DialogDownloadServiceCenterDataComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ISB303[]) { }

  ngOnInit(): void {
    this.setUpData();
  }

  setUpData(): void {
    this.gridData = [
      { title: 'Number of clients, administration sessions, and group administration sessions', value: '' },
      { title: 'Clients Served', value: this.data[0].serviceCenterData.clientsServed },
      { title: 'Number of Individual Administration Sessions', value: this.data[0].serviceCenterData.individualAdministrationSessions },
      { title: 'Number of Group Administration Sessions', value: this.data[0].serviceCenterData.groupAdministrationSessions },

      { title: '', value: '' },
      { title: 'Number of individuals who were denied services', value: '' },
      { title: 'Individuals who were denied psilocybin services', value: this.data[0].serviceCenterData.deniedTotal },
      { title: 'Individuals who were denied psilocybin services due to use of Lithium', value: this.data[0].serviceCenterData.deniedLithium },
      { title: 'Individuals who were denied psilocybin services due to thoughts of causing harm to self or others', value: this.data[0].serviceCenterData.deniedHarm },
      { title: 'Individuals who were denied psilocybin services due to active psychosis', value: this.data[0].serviceCenterData.deniedPsychosis },
      { title: 'Individuals who were denied psilocybin services due to other reasons', value: this.data[0].serviceCenterData.deniedOther },

      { title: '', value: '' },
      { title: 'Number of adverse reactions', value: '' },
      { title: 'Clients who experienced adverse behavioral reactions', value: this.data[0].serviceCenterData.behavioralReactions },
      { title: 'Clients who experienced severe adverse behavioral reactions', value: this.data[0].serviceCenterData.severeBehavioralReactions },
      { title: 'Clients who experienced adverse medical reactions', value: this.data[0].serviceCenterData.medicalReactions },
      { title: 'Clients who experienced severe adverse medical reactions', value: this.data[0].serviceCenterData.severeMedicalReactions },

      { title: '', value: '' },
      { title: 'Averages', value: '' },
      { title: 'Average number of times per client that psilocybin services were received', value: this.data[0].serviceCenterData.averageClientUses },
      { title: 'Average number of clients participating in each group administration session', value: this.data[0].serviceCenterData.averageClientsGroupAdministration },
      { title: 'Average dose of psilocybin analyte consumed per client during an administration session', value: this.data[0].serviceCenterData.averageDose },

      { title: '', value: '' },
      { title: 'Post-session reactions', value: '' },
      { title: 'Number of clients who experienced post-session reactions', value: this.data[0].serviceCenterData.postSessionReactions}
    ];

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.excelExport.save();
      setTimeout(() => {
        this.dialogRef.close();
      }, 2000);
    }, 1000);
  }

}
