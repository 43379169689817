<div fxLayout="row">
  <span fxFlex></span>
  <button type="button" mat-flat-button color="warn" (click)="cancel()">Close</button>
</div>
<br/>

<div fxLayout="column" fxLayoutGap="12px">
  <div fxLayout="row">
    <div fxLayout="column" fxFlex="15">
      <mat-card>
        <ul navAccordion class="matero-sidemenu" style="width:auto" fxFlex>
          <li navAccordionItem class="matero-sidemenu-item" *ngFor="let question of questions; let index = index" [ngClass]="{'active': currentQuestion === index}">
              <a matRipple (click)="changeQuestion(index)">
                <span>{{question}}</span>
              </a>
            </li>
          </ul>
      </mat-card>
    </div>

    <div fxLayout="column" fxFlex="85">
      <mat-card fxFlex="100" style="border-radius: 0;">

        <div *ngIf="currentQuestion == 0">
          <span><b>1. Number of clients, administration sessions, and group administration sessions</b></span>
          <form [formGroup]="clientForm">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="clientsServed" type="number">
              </mat-form-field>
              <span fxFlex="80">Number of Clients Served</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="individualAdministrationSessions" type="number">
              </mat-form-field>
              <span fxFlex="80">Number of Individual Administration Sessions</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="groupAdministrationSessions" type="number">
              </mat-form-field>
              <span fxFlex="80">Number of Group Administration Sessions</span>
            </div>
          </form>
        </div>

        <div *ngIf="currentQuestion == 1">
          <span><b>2. Number of individuals who were denied services</b></span>
          <form [formGroup]="deniedForm">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="deniedTotal" type="number">
              </mat-form-field>
              <span fxFlex="80">Number of Individuals who were denied psilocybin services</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="deniedLithium" type="number">
              </mat-form-field>
              <span fxFlex="80">Number of Individuals who were denied psilocybin services due to use of Lithium.</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="deniedHarm" type="number">
              </mat-form-field>
              <span fxFlex="80">Number of Individuals who were denied psilocybin services due to thoughts of causing harm to self or others.</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="deniedPsychosis" type="number">
              </mat-form-field>
              <span fxFlex="80">Number of Individuals who were denied psilocybin services due to active psychosis.</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-left: 3%">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="deniedOther" type="number" style="margin-left: 3%">
              </mat-form-field>
              <span fxFlex="80">Number of individuals who were denied psilocybin services due to other reasons.</span>
            </div>
          </form>
        </div>

        <div *ngIf="currentQuestion == 2">
          <span><b>3. Number of adverse reactions</b></span>
          <form [formGroup]="reactionForm">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="behavioralReactions" type="number">
              </mat-form-field>
              <span fxFlex="80">Number of clients who experienced adverse behavioral reactions.</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="severeBehavioralReactions" type="number">
              </mat-form-field>
              <span fxFlex="80">Number of clients who experienced severe adverse behavioral reactions.</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="medicalReactions" type="number">
              </mat-form-field>
              <span fxFlex="80">Number of clients who experienced adverse medical reactions.</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="severeMedicalReactions" type="number">
              </mat-form-field>
              <span fxFlex="80">Number of clients who experienced severe adverse medical reactions.</span>
            </div>
          </form>
        </div>

        <div *ngIf="currentQuestion == 3">
          <span><b>4. Averages (computed by service center)</b></span>
          <form [formGroup]="averagesForm">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="averageClientUses" type="number">
              </mat-form-field>
              <span fxFlex="80">Average number of times per client that psilocybin services were received (numerical field, one decimal place)</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="averageClientsGroupAdministration" type="number">
              </mat-form-field>
              <span fxFlex="80">Average number of clients participating in each group administration session (numerical field, one decimal place)</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field fxFlex="10">
                <input matInput formControlName="averageDose" type="number">
              </mat-form-field>
              <span fxFlex="80">Average dose of psilocybin analyte consumed per client during an administration session (whole number, measured in milligrams)</span>
            </div>
          </form>
        </div>
      </mat-card>
    </div>
  </div>
</div>

<div *ngIf="loading">
  <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>
</div>
