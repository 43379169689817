import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { MAT_DATE_FORMATS, MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { AddUserDialogComponent } from 'app/components/add-user/add-user.component';
import { ApplicantComponent } from 'app/components/applicant/applicant/applicant.component';
import { EntityApplicantComponent } from 'app/components/applicant/entity-applicant/entity-applicant.component';
import { AuditLogDialogComponent } from 'app/components/audit-log/audit-log.component';
import { ContactInformationDialogComponent } from 'app/components/audit-log/contact-information-table/contact-information-table.component';
import { DialogAddMessageComponent } from 'app/components/internal-app-messaging/internal-app-messaging.component';
import { LicenseChecklistComponent } from 'app/components/license-checklist/license-checklist.component';
import { ManageNotesComponent } from 'app/components/notes/manage/manage-notes.component';
import { IPayment } from 'app/components/payments/models/payment';
import { PaymentService } from 'app/components/payments/services/payments.service';
import { EmailReasonDialog } from 'app/components/tasks/dialog-email-reason';
import { TaskHistoryComponent } from 'app/components/tasks/task-history/task-history.component';
import { IComplaint } from 'app/models/complaints/complaint';
import { ICompliance } from 'app/models/compliance/compliance';
import { IDocument } from 'app/models/documents/document';
import { IDocumentType } from 'app/models/documents/documentType';
import { IApplicant } from 'app/models/licenses/applicant';
import { ILicenseChecklist } from 'app/models/licenses/license-checklist';
import { ILicenseSearch } from 'app/models/licenses/license-search';
import { IManufacturerLicense } from 'app/models/licenses/manufacturer-license';
import { ISimpleLicense } from 'app/models/licenses/simple-license';
import { ITask } from 'app/models/tasks/task';
import { IEditAddUserProfile } from 'app/models/user-info/edit-add-user-profile';
import { IStaff } from 'app/models/user-info/staff';
import { AuditLogService } from 'app/services/audit-log/audit-log.service';
import { SharedService } from 'app/services/core/shared.service';
import { ManufacturerService } from 'app/services/licenses/manufacturer.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { PrintManufacturerComponent } from '../print-manufacturers/print-manufacturers.component';
import { RenewalChangesDialogComponent } from 'app/components/audit-log/renewal-change-table/renewal-change-table.component';
import { environment } from '@env/environment';
import { IEntityApplicant } from 'app/models/licenses/entity-applicant';
import { ApplicantService } from 'app/services/licenses/applicant.service';
import { IPropertyNamesWithInfo } from 'app/models/audit-logging/audit-logging';
import { ManagerNotesDialogComponent } from 'app/components/manager-notes/dialog/manager-notes-dialog.component';
import { IManagerNotes } from 'app/models/manager-notes/manager-notes';
import { IReportedEvent } from 'app/models/reported-events/reported-event';
import { IWorkerLicense } from 'app/models/licenses/worker/worker-license';

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'search-manufacturers',
  templateUrl: './search-manufacturers.component.html',
  styleUrls: ['./search-manufacturers.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})

export class SearchManufacturersComponent implements OnInit {
  @Input() assignedOnly: boolean = false;
  @Input() showSearch: boolean = true;
  @Input() showAddNew: boolean = true;
  @ViewChild(ManageNotesComponent) notes: ManageNotesComponent;
  @ViewChild(ApplicantComponent) individualApplicants: ApplicantComponent;
  @ViewChild(EntityApplicantComponent) entityApplicants: EntityApplicantComponent;
  @ViewChild(LicenseChecklistComponent) checklist: LicenseChecklistComponent;
  @ViewChild('select') select: MatSelect;

  public allSelected: boolean = false;
  public currentPage: number = 0;
  public pageSize: number = 0;
  public licenseCount: number = 0;
  public id: number = 0;
  public title: string = "Manage Manufacturers";
  public today: Date = new Date(Date.now());
  public licenseLoading: boolean = false;
  public cardColor: string = this.sharedService.gold + '20';
  public selectedIndex: number = 0;
  public licenseStaff: IStaff[] = [];
  public licensePaid: boolean = false;
  public licenseName: string = '';
  public licenses: ISimpleLicense[] = [];
  public changedFields: IPropertyNamesWithInfo[] = [];
  public showChecklist: boolean = false;
  public viewArchived: boolean = false;
  public licenseChecklist: ILicenseChecklist = {
    id: null,
    entityType: '',
    licenseName: '',
    licenseId: '',
    teamMember: '',
    reviewDate: '',
    demographicAndContactInfoComplete: false,
    attestationsAndSignedComplete: false,
    photoIdComplete: false,
    photoIdType: '',
    idNameMatchCompleted: false,
    legalAgeComplete: false,
    backgroundCheckPassed: false,
    backgroundCheckDate: '',
    reviewNotes: '',
    requirementsMetDate: '',
    applicantInfoComplete: false,
    manufacturerEndorsements: false,
    approvedLandUseCompatibility: false,
    propertyOwnerStatement: false,
    propertyOwnerStatementNA: false,
    premisesPlanMeetsRequirements: false,
    socialEquityPlanMeetsRequirements: false,
    socialEquityPlanEvaluationMeetsRequirements: false,
    authorizedIndividualsFormPlan: false,
    reducedLicensingFeeDocumentation: false,
    reducedLicensingFeeDocumentationNA: false,
    socialSecurityIncomeBenefitCompleted: false,
    applicantsCurrentEligibilityStatement: false,
    foodStampBenefits: false,
    servedInArmedForces: false,
    primaryContactIdentified: false,
    nonProfitEntity: false,
    individualEntityApplicantInfoComplete: false,
    applicantInformationFormsReceived: false,
    licenseChecklistApplicants: [],
    orelapCertificateValid: false,
    labListedOnline: false,
    notSubjectToHealthCareFacility: false,
    dateNotSubjectToHealthCareFacility: '',
    completedSchoolProximityAttestations: false,
    meetsSchoolProximityRequirements: false,
    gisLiasonConfirmed: false,
    completedPracticumSiteAttestations: false,
    indicatedServesAsPracticumSite: false,
    indicatedServesAsPracticumSiteNA: false,
    passedExam: false,
    trainingProgramCertificate: false,
    submitForApproval: false,
    submitForClosing: false,
    submitForDenial: false,
    submitForClosingNotes: '',
    submitForDenialNotes: '',
    responsiblePartyInformationComplete: false,
    registeredAgentInformationComplete: false,
    directorInformationComplete: false,
    firstLeadInformationComplete: false,
    secondLeadInformationComplete: false,
    responsiblePartyAttestationsComplete: false,
    registeredState: '',
    instructorNames: '',
    licenseChecklistCurriculums: [],
    workerPermitSubmitted: false,
    peerReviewComplete: false,
    peerReviewMember: '',
    peerReviewDate: ''
  };
  public license: IManufacturerLicense = {
    id: 0,
    userId: '',
    licenseId: '',
    status: '',
    accountName: '',
    accountEmail: '',
    accountIsB2C: true,
    entityName: '',
    entityWebsite: '',
    registeredBusiness: false,
    registryNumber: '',
    registeredIn: '',
    businessInOregon: false,
    secretaryofStateNumber: '',
    registrationAttestation: false,
    financialAttestation: false,
    physicalState: '',
    physicalCity: '',
    physicalStreet: '',
    physicalZip: '',
    physicalCounty: '',
    premisesDescription: '',
    mailingIsPhysical: false,
    mailingState: '',
    mailingCity: '',
    mailingStreet: '',
    mailingZip: '',
    mailingCounty: '',
    primaryContactName: '',
    primaryContactNumber: '',
    primaryContactEmail: '',
    primaryContactId: '',
    language: '',
    previousLicense: false,
    affiliatedLicenses: '',
    fungiCultivation: false,
    psilocybinExtraction: false,
    ediblePsilocybinProduction: false,
    edibleAttestation: false,
    denyEndorsementAttestation: false,
    canRenew: false,
    canOperate: false,
    reducedRequest: false,
    electronicSignature: '',
    attestation: false,
    applicationDate: '',
    approvedDate: '',
    expirationDate: '',
    renewedDate: '',
    entityApplicants: [],
    applicants: [],
    licenseDocuments: [],
    payments: [],
    tasks: [],
    submittedForApproval: false,
    isRenewal: false,
    hasRenewal: false,
    directoryConsent: false,
    requestWithdrawDate: '',
    approveWithdrawDate: '',
    denyWithdrawDate: '',
    requestSurrenderDate: '',
    approveSurrenderDate: '',
    denySurrenderDate: '',
    complianceAssignedToName: '',
    assignedToName: '',
    complianceMemberAssignedDate: '',
    inspectionCompletedDate: '',
    previousLicenseNames: [],
    enteredByStaff: false,
    previousPointsOfContact: [],
    timely: false,
    hiddenFromTracker: false,
    financialInterestCorrect: null,
    financialInterestCorrectReason: '',
    manufacturerInformationCorrect: null,
    manufacturerInformationCorrectReason: '',
    endorsementsCorrect: null,
    endorsementsCorrectReason: '',
    createdDate: '',
    applicantsCorrect: false,
    applicantsCorrectReason: '',
    directoryConsentWebsite: false,
    directoryConsentEmail: false,
    directoryConsentPhone: false,
    directoryPhone: '',
    directoryEmail: '',
    directoryWebsite: '',
    attorneyContact: false,
    attorneyName: '',
    attorneyEmail: '',
    attorneyPhone: '',
    attorneyNotes: '',
    attorneyConsent: false,
    managerFlag: '',
    managerNotes: '',
    reviewType: '',
    renewed: false,
    workerPermits: []
  }
  public search: ILicenseSearch = {
    entityName: '',
    licenseId: '',
    statuses: [],
    assignedTo: '',
    onlyPaid: false,
    street: '',
    city: '',
    county: '',
  }
  public searchForm = new UntypedFormGroup({
    entityName: new UntypedFormControl(""),
    licenseId: new UntypedFormControl(""),
    statuses: new UntypedFormControl(this.sharedService.defaultSearchStatuses),
    assignedTo: new UntypedFormControl(""),
    onlyPaid: new UntypedFormControl(false),
    street: new UntypedFormControl(""),
    city: new UntypedFormControl(""),
    county: new UntypedFormControl(""),
  });
  public complaints: IComplaint[] = [];
  public compliance: ICompliance = {
    id: 0,
    caseId: '',
    licenseId: '',
    entityId: 0,
    entityType: '',
    status: 'New',
    category: '',
    tier: '',
    dueDate: '',
    closedDate: '',
    type: '',
    sent: null,
    assignedTo: '',
    manager: '',
    outcome: '',
    agencyGenerated: false,
    locked: false,
    dateOfNonCompliance: '',
    dateOfCompliance: '',
    originalLicenseId: '',
    complianceRules: [],
    complaints: [],
    notes: [],
    complianceActions: [],
    investigations: [],
    compliancePlans: [],
    relatedCases: [],
    notices: [],
    referencedLicenses: [],
    complianceDocuments: [],
    noticeStatus: '',
    denialCriteria: '',
    administrativeAction: '',
  }
  public reportedEvent: IReportedEvent = {
    id: 0,
    eventId: '',
    eventType: '',
    eventDate: '',
    eventTime: '',
    description: '',
    entityId: 0,
    entityType: '',
    licenseesInvolved: '',
    reoccurringEvent: '',
    emergencyService: false
  }
  public status: string = '';
  public addingNewLicense: boolean = false;
  public expiredLicenses: IManufacturerLicense[] = [];
  public editingLicense: boolean = false;
  public isNewLicense: boolean = false;
  public hasRenewal: boolean = true;
  public licenseLocked: boolean = true;
  public newLicenseCreatedId: number = 0;
  public uploading: boolean = false;
  public downloading: boolean = false;
  public deleting: boolean = false;
  public documentsValid: boolean = true;
  public documentTypeReference: { [id: number]: string } = {};
  public documentTypes: IDocumentType[] = [];
  public lucs: string = 'Land Use Compatibility Statement'
  public premisesSketch: string = 'Premises Plan';
  public ownerConsent: string = 'Owner Consent';
  public socialEquityPlan: string = 'Social Equity Plan';
  public authorizedIndividual: string = 'Authorized Individual Form';
  public reducedFee: string = 'Reduced Fee';
  public physicalApplication: string = 'Physical Application';
  public otherApplication: string = 'Other';
  public gisReview: string = 'GIS Review';
  public interviewDocumentation: string = 'Interview Documentation';
  public siteInspectionFollowUp: string = 'Site Inspection Follow Up';
  public caseDocumentation: string = 'Case Documentation';
  public premisePicture: string = 'Premise Picture';
  public updatedEquityPlan: string = "Updated Social Equity Plan";
  public equityPlanEvaluation: string = "Social Equity Plan Evaluation";
  public updatedPremisePicture: string = "Updated Premise Picture";
  public lucsType: number = 0;
  public premisesType: number = 0;
  public ownerConsentType: number = 0;
  public socialEquityType: number = 0;
  public authorizedIndividualType: number = 0;
  public reducedFeeType: number = 0;
  public physicalType: number = 0;
  public otherType: number = 0;
  public gisReviewType: number = 0;
  public interviewDocumentationType: number = 0;
  public siteInspectionFollowUpType: number = 0;
  public caseDocumentationType: number = 0;
  public premisePictureType: number = 0;
  public updatedEquityPlanType: number = 0;
  public equityPlanEvaluationType: number = 0;
  public updatedPremisePictureType: number = 0;
  public editingApplicant: boolean = false;
  public editingEntityApplicant: boolean = false;
  public dedeuplicating: boolean = false;

  public licenseForm = new UntypedFormGroup({
    entityName: new UntypedFormControl('', Validators.required),
    entityWebsite: new UntypedFormControl('', Validators.pattern( /^(http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]/i )),
    businessInOregon: new UntypedFormControl(),
    registrationAttestation: new UntypedFormControl(false, Validators.required),
    registryNumber: new UntypedFormControl(''),
    registeredBusiness: new UntypedFormControl(),
    secretaryofStateNumber: new UntypedFormControl(''),
    registeredIn: new UntypedFormControl(''),
    financialAttestation: new UntypedFormControl(false, [Validators.requiredTrue]),
    physicalState: new UntypedFormControl('', Validators.required),
    physicalCity: new UntypedFormControl('', Validators.required),
    physicalStreet: new UntypedFormControl('', Validators.required),
    physicalZip: new UntypedFormControl('', [Validators.required, Validators.pattern(/^\d{5}(-\d{4})?$/)]),
    physicalCounty: new UntypedFormControl('', Validators.required),
    premisesDescription: new UntypedFormControl(''),
    mailingIsPhysical: new UntypedFormControl(false),
    mailingState: new UntypedFormControl(''),
    mailingCity: new UntypedFormControl(''),
    mailingStreet: new UntypedFormControl(''),
    mailingZip: new UntypedFormControl('', [Validators.pattern(/^\d{5}(-\d{4})?$/)]),
    mailingCounty: new UntypedFormControl(''),
    primaryContactName: new UntypedFormControl('', Validators.required),
    primaryContactNumber: new UntypedFormControl('', [Validators.required, Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
    primaryContactEmail: new UntypedFormControl('', [Validators.required, Validators.email]),
    primaryContactId: new UntypedFormControl(''),
    language: new UntypedFormControl(''),
    previousLicense: new UntypedFormControl(false),
    affiliatedLicenses: new UntypedFormControl(''),
    fungiCultivation: new UntypedFormControl(false),
    psilocybinExtraction: new UntypedFormControl(false),
    ediblePsilocybinProduction: new UntypedFormControl(false),
    edibleAttestation: new UntypedFormControl(false),
    denyEndorsementAttestation: new UntypedFormControl(false),
    electronicSignature: new UntypedFormControl('', [Validators.required]),
    attestation: new UntypedFormControl(false, [Validators.required]),
    status: new UntypedFormControl(''),
    directoryConsent: new UntypedFormControl(false),
    directoryConsentWebsite: new UntypedFormControl(false),
    directoryConsentEmail: new UntypedFormControl(false),
    directoryConsentPhone: new UntypedFormControl(false),
    directoryPhone: new UntypedFormControl(''),
    directoryEmail: new UntypedFormControl(''),
    directoryWebsite: new UntypedFormControl('', Validators.pattern( /^(http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]/i )),
    inspectionCompletedDate: new UntypedFormControl(''),
    approvedDate: new UntypedFormControl(""),
    renewedDate: new UntypedFormControl(""),
    timely: new UntypedFormControl(false),
    hiddenFromTracker: new UntypedFormControl(false),
    applicantsCorrect: new UntypedFormControl(false),
    applicantsCorrectReason: new UntypedFormControl(''),
    financialInterestCorrect: new UntypedFormControl(),
    financialInterestCorrectReason: new UntypedFormControl(''),
    manufacturerInformationCorrect: new UntypedFormControl(),
    manufacturerInformationCorrectReason: new UntypedFormControl(''),
    endorsementsCorrect: new UntypedFormControl(),
    endorsementsCorrectReason: new UntypedFormControl(''),
    attorneyContact: new UntypedFormControl(false),
    attorneyName: new UntypedFormControl(""),
    attorneyEmail: new UntypedFormControl("", [Validators.email]),
    attorneyPhone: new UntypedFormControl("", [Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
    attorneyNotes: new UntypedFormControl(""),
    attorneyConsent: new UntypedFormControl(false),
  }, {
    validators: [
      this.mailingStreetValidator(),
      this.mailingCityValidator(),
      this.mailingStateValidator(),
      this.mailingZipValidator(),
      this.previousLicenseValidator()
    ]
  });

  public documentsForm = new UntypedFormGroup({
    lucs: new UntypedFormControl(false),
    premisesSketch: new UntypedFormControl(false),
    ownerConsent: new UntypedFormControl(false),
    socialEquityPlan: new UntypedFormControl(false),
    reducedFee: new UntypedFormControl(false),
    physical: new UntypedFormControl(false),
    gisReview: new UntypedFormControl(false),
    interviewDocumentation: new UntypedFormControl(false),
    siteInspectionFollowUp: new UntypedFormControl(false),
    caseDocumentation: new UntypedFormControl(false),
    premisePicture: new UntypedFormControl(false),
    other: new UntypedFormControl(false),
    updatedEquityPlan: new UntypedFormControl(false),
    equityPlanEvaluation: new UntypedFormControl(false),
    updatedPremisePicture: new UntypedFormControl(false),
  });

  public licenseColumns: string[] = ['entityName', 'licenseId', 'tasks', 'status', 'applicationDate', 'approvedDate', 'expirationDate', 'assignedTo', 'assignedToCompliance', 'actions', 'managerFlag'];
  public licenseSource = new MatTableDataSource<ISimpleLicense>(this.licenses);
  @ViewChild('licensePaginator', {static: false}) licensePaginator: MatPaginator;
  @ViewChild('licenseSort', {static: false}) licenseSort: MatSort;

  public documentColumns: string[] = ['name', 'types', 'created', 'actions'];
  public documentSource = new MatTableDataSource<IDocument>(this.license.licenseDocuments);
  @ViewChild('documentPaginator', {static: false}) documentPaginator: MatPaginator;
  @ViewChild('documentSort', {static: false}) documentSort: MatSort;

  public complaintColumns: string[] = ['dateCreated', 'name', 'complaintText', 'contactEmail', 'contactNumber', 'status', 'actions'];
  public complaintSource = new MatTableDataSource<IComplaint>(this.complaints);
  @ViewChild('complaintPaginator', {static: false}) complaintPaginator: MatPaginator;
  @ViewChild('complaintSort', {static: false}) complaintSort: MatSort;

  public expiredLicensesColumns: string[] = ['licenseId', 'applicationDate', 'approvedDate', 'expirationDate',  'status'];
  public expiredLicensesSource = new MatTableDataSource<IManufacturerLicense>(this.expiredLicenses);
  @ViewChild('expiredLicensesPaginator', {static: false}) expiredLicensesPaginator: MatPaginator;
  @ViewChild('expiredLicensesSort', {static: false}) expiredLicensesSort: MatSort;

  public currentLicenseColumns: string[] = ['licenseId', 'applicationDate', 'approvedDate', 'renewedDate', 'expirationDate',  'status'];

  public taskColumns: string[] = ['task', 'actions'];
  public taskSource = new MatTableDataSource<ITask>(this.license.tasks.filter(t => !t.complete && !t.archived));

  constructor(public sharedService: SharedService,
              public licenseService: ManufacturerService,
              private dialog: MatDialog,
              public paymentService: PaymentService,
              private route: ActivatedRoute,
              public authService: AuthService,
              public toastr: ToastrService,
              private auditLogService: AuditLogService,
              private router: Router) {
    this.id = Number(this.route.snapshot.paramMap.get("id"));
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.title = this.assignedOnly ? "Your Assigned Manufacturers" : "Manage Manufacturers";
    this.licenseColumns = this.assignedOnly ? ['entityName', 'licenseId', 'tasks', 'status', 'applicationDate', 'approvedDate', 'expirationDate', 'actions', 'managerFlag'] :
                                              ['entityName', 'licenseId', 'tasks', 'status', 'applicationDate', 'approvedDate', 'expirationDate', 'assignedTo', 'assignedToCompliance', 'actions', 'managerFlag'];
    this.getDocumentTypes();
    this.getLicenseStaff();
    this.searchLicenses();
    setTimeout(() => {
      if(this.id > 0) {
        this.getLicense(this.id);
      }
    }, 100);
  }

  viewPrintScreen(license: IManufacturerLicense): void {
    if (license[0] != null) {
      this.dialog.open(ManufacturerDetailsDialog, {
        data: license[0],
        maxWidth: !this.sharedService.mobile ? '900px' : '800px',
        maxHeight: this.sharedService.mobile ? '750px' : '900px',
        autoFocus: false,
        panelClass: this.sharedService.darkMode ? "theme-dark" : ""
      });
    }
    else {
      this.dialog.open(ManufacturerDetailsDialog, {
        data: license,
        maxWidth: !this.sharedService.mobile ? '900px' : '800px',
        maxHeight: this.sharedService.mobile ? '750px' : '900px',
        autoFocus: false,
        panelClass: this.sharedService.darkMode ? "theme-dark" : ""
      });
    }
  }

  withdrawLicense(approved: boolean, id: number): void {
    if(approved)
      this.sharedService.openConfirm("Are you sure you wish to approve this withdraw request?");
    if(!approved)
      this.sharedService.openConfirm("Are you sure you wish to deny this withdraw request?");

    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.licenseService.withdrawLicense(approved,id).subscribe(
            response => {
              this.license.approveWithdrawDate = response.approveWithdrawDate;
              this.license.denyWithdrawDate = response.denyWithdrawDate;
              this.license.status = response.status;
              this.licenseForm.patchValue({
                status: this.license.status
              })
            },
            error => console.log('error', error),
          )
    }});
  }

  surrenderLicense(approved: boolean, id: number): void {
    if(approved)
      this.sharedService.openConfirm("Are you sure you wish to approve this surrender request?");
    if(!approved)
      this.sharedService.openConfirm("Are you sure you wish to deny this surrender request?");

    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.licenseService.surrenderLicense(approved,id).subscribe(
            response => {
              this.license.approveSurrenderDate = response.approveSurrenderDate;
              this.license.denySurrenderDate = response.denySurrenderDate;
              this.license.status = response.status;
              this.licenseForm.patchValue({
                status: this.license.status
              })
            },
            error => console.log('error', error),
          )
    }});
  }

  getDocumentTypes(): void {
    this.licenseService.getDocumentTypes().subscribe(
      response => this.documentTypes = response,
      error => console.log('error', error),
      () => {
        this.lucsType = this.documentTypes.find(dt => dt.type === this.lucs).id;
        this.premisesType = this.documentTypes.find(dt => dt.type === this.premisesSketch).id;
        this.ownerConsentType = this.documentTypes.find(dt => dt.type === this.ownerConsent).id;
        this.socialEquityType = this.documentTypes.find(dt => dt.type === this.socialEquityPlan).id;
        this.authorizedIndividualType = this.documentTypes.find(dt => dt.type === this.authorizedIndividual).id;
        this.reducedFeeType = this.documentTypes.find(dt => dt.type === this.reducedFee).id;
        this.physicalType = this.documentTypes.find(dt => dt.type === this.physicalApplication).id;
        this.otherType = this.documentTypes.find(dt => dt.type === this.otherApplication).id;
        this.gisReviewType = this.documentTypes.find(dt => dt.type === this.gisReview).id;
        this.interviewDocumentationType = this.documentTypes.find(dt => dt.type === this.interviewDocumentation).id;
        this.siteInspectionFollowUpType = this.documentTypes.find(dt => dt.type === this.siteInspectionFollowUp).id;
        this.caseDocumentationType = this.documentTypes.find(dt => dt.type === this.caseDocumentation).id;
        this.premisePictureType = this.documentTypes.find(dt => dt.type === this.premisePicture).id;
        this.updatedEquityPlanType = this.documentTypes.find(dt => dt.type === this.updatedEquityPlan).id;
        this.equityPlanEvaluationType = this.documentTypes.find(dt => dt.type === this.equityPlanEvaluation).id;
        this.updatedPremisePictureType = this.documentTypes.find(dt => dt.type === this.updatedPremisePicture).id;

        this.documentTypeReference[this.lucsType] = this.lucs;
        this.documentTypeReference[this.premisesType] = this.premisesSketch;
        this.documentTypeReference[this.ownerConsentType] = this.ownerConsent;
        this.documentTypeReference[this.socialEquityType] = this.socialEquityPlan;
        this.documentTypeReference[this.authorizedIndividualType] = this.authorizedIndividual;
        this.documentTypeReference[this.reducedFeeType] = this.reducedFee;
        this.documentTypeReference[this.physicalType] = this.physicalApplication;
        this.documentTypeReference[this.otherType] = this.otherApplication;
        this.documentTypeReference[this.gisReviewType] = this.gisReview;
        this.documentTypeReference[this.interviewDocumentationType] = this.interviewDocumentation;
        this.documentTypeReference[this.siteInspectionFollowUpType] = this.siteInspectionFollowUp;
        this.documentTypeReference[this.caseDocumentationType] = this.caseDocumentation;
        this.documentTypeReference[this.premisePictureType] = this.premisePicture;
        this.documentTypeReference[this.updatedEquityPlanType] = this.updatedEquityPlan;
        this.documentTypeReference[this.equityPlanEvaluationType] = this.equityPlanEvaluation;
        this.documentTypeReference[this.updatedPremisePictureType] = this.updatedPremisePicture;
      }
    );
  }

  getLicenseStaff(): void {
    this.licenseLoading = true;
    this.licenseService.getLicenseStaff().subscribe(
      response => this.licenseStaff = response,
      error => console.log('error', error),
      () => this.licenseLoading = false
    );
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
   optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  searchLicenses(): void {
    this.licenseLoading = true;
    let searchValues = this.searchForm.value;
    this.search.entityName = searchValues.entityName;
    this.search.licenseId = searchValues.licenseId;
    this.search.assignedTo = searchValues.assignedTo;
    this.search.statuses = searchValues.statuses;
    this.search.onlyPaid = searchValues.onlyPaid;
    this.search.street = searchValues.street;
    this.search.city = searchValues.city;
    this.search.county = searchValues.county;

    var method: Observable<ISimpleLicense[]> = this.assignedOnly? this.licenseService.getAssignedLicenses() :
                                                                             this.licenseService.searchLicenses(this.search);
    method.subscribe(
      response => {
        this.licenses = response;
        this.licenseCount = response.length;
      },
      error => {
        console.log('error', error);
      },
      () => {
        this.updateLicenseTable();
        this.licenseLoading = false;
      }
    );
  }

  storePaginationState() {
    this.currentPage = this.licensePaginator.pageIndex;
    this.pageSize = this.licensePaginator.pageSize;
  }

  updateLicenseTable(): void {
    this.licenseSource.data = this.licenses;
    this.licenseSource.sort = this.licenseSort;
    this.licenseSource.paginator = this.licensePaginator;
    this.licenseSource.sortingDataAccessor = this.customDateSortAccessor;
  }

  customDateSortAccessor = (item: any, property: string) => {
    const direction = this.licenseSort.direction;
    if (property === 'approvedDate') {
      const dateValue = item.approvedDate ? new Date(item.approvedDate) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }

    if (property === 'applicationDate') {
      const dateValue = item.applicationDate ? new Date(item.applicationDate) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }

    if (property === 'expirationDate') {
      const dateValue = item.expirationDate ? new Date(item.expirationDate) : null;
      if (dateValue === null) {
        return direction === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
      }
      return dateValue.getTime();
    }
    return item[property];
  };

  getEmptyLicense(): IManufacturerLicense{
    let emptyLicense: IManufacturerLicense = {
      id: 0,
      userId: '',
      licenseId: '',
      status: '',
      accountName: '',
      accountEmail: '',
      accountIsB2C: true,
      entityName: '',
      entityWebsite: '',
      registeredBusiness: false,
      businessInOregon: false,
      secretaryofStateNumber: '',
      registrationAttestation: false,
      registryNumber: '',
      registeredIn: '',
      financialAttestation: false,
      physicalState: '',
      physicalCity: '',
      physicalStreet: '',
      physicalZip: '',
      physicalCounty: '',
      premisesDescription: '',
      mailingIsPhysical: false,
      mailingState: '',
      mailingCity: '',
      mailingStreet: '',
      mailingZip: '',
      mailingCounty: '',
      primaryContactName: '',
      primaryContactNumber: '',
      primaryContactEmail: '',
      primaryContactId: '',
      language: '',
      previousLicense: false,
      affiliatedLicenses: '',
      fungiCultivation: false,
      psilocybinExtraction: false,
      ediblePsilocybinProduction: false,
      edibleAttestation: false,
      denyEndorsementAttestation: false,
      canRenew: false,
      canOperate: false,
      reducedRequest: false,
      electronicSignature: '',
      attestation: false,
      applicationDate: '',
      approvedDate: '',
      expirationDate: '',
      renewedDate: '',
      entityApplicants: [],
      applicants: [],
      licenseDocuments: [],
      payments: [],
      tasks: [],
      submittedForApproval: false,
      isRenewal: false,
      hasRenewal: false,
      directoryConsent: false,
      requestWithdrawDate: '',
      approveWithdrawDate: '',
      denyWithdrawDate: '',
      requestSurrenderDate: '',
      approveSurrenderDate: '',
      denySurrenderDate: '',
      complianceAssignedToName: '',
      assignedToName: '',
      complianceMemberAssignedDate: '',
      inspectionCompletedDate: '',
      previousLicenseNames: [],
      enteredByStaff: false,
      previousPointsOfContact: [],
      timely: false,
      hiddenFromTracker: false,
      financialInterestCorrect: null,
      financialInterestCorrectReason: '',
      manufacturerInformationCorrect: null,
      manufacturerInformationCorrectReason: '',
      endorsementsCorrect: null,
      endorsementsCorrectReason: '',
      createdDate: '',
      applicantsCorrect: false,
      applicantsCorrectReason: '',
      directoryConsentWebsite: false,
      directoryConsentEmail: false,
      directoryConsentPhone: false,
      directoryPhone: '',
      directoryEmail: '',
      directoryWebsite: '',
      attorneyContact: false,
      attorneyName: '',
      attorneyEmail: '',
      attorneyPhone: '',
      attorneyNotes: '',
      attorneyConsent: false,
      managerFlag: '',
      managerNotes: '',
      reviewType: '',
      renewed: false,
      workerPermits: []
    }
    return emptyLicense;
  }

  createLicense(): void {
    this.licenseService.createLicense().subscribe(
      response => this.license = response,
      error => console.log('error', error),
      () => {
        this.addingNewLicense = true;
        this.editingLicense = true;
        this.isNewLicense = true;
        this.newLicenseCreatedId = this.license.id;
        this.updateLicenseForm();
      }
    );
  }

  getLicense(licenseId: number): void {
    this.storePaginationState();
    this.changedFields = [];
    this.licenseService.getLicense(licenseId).subscribe(
      response => this.license = response,
      error => console.log('error', error),
      () => {
        this.updateLicenseForm();
        this.updateLicenseDocumentTable();
        this.licensePaid = this.license.payments.filter(payment => {
          return payment.type === this.paymentService.applicationFeeType ||
                 payment.type === this.paymentService.licenseFeeType ||
                 payment.type === this.paymentService.reducedLicenseFeeType;
          }).every(payment => payment.paid) && (this.license.payments.length > 1 || (this.license.isRenewal && this.license.payments.length >= 1));
        this.taskSource.data = this.license.tasks.filter(t => !t.complete && !t.archived);
        this.licenseService.getExpiredLicenses(this.license.licenseId).subscribe(
          response => {
            this.expiredLicensesSource.data = response.filter(t => t.id != this.license.id);
            this.expiredLicensesSource.sort = this.expiredLicensesSort;
            this.expiredLicensesSource.paginator = this.expiredLicensesPaginator;
            if(response.filter(t => t.status == this.sharedService.renewalSubmitted || t.status == this.sharedService.pendingRenewal || t.status == this.sharedService.renewalReviewTimely ||
              t.status == this.sharedService.renewalReviewUntimely || t.status == this.sharedService.renewalApprovedPendingPaymentandClearance).length == 0)
              this.hasRenewal = false;
          },
          error => console.log('error', error)
        );
      }
    );
  }

  updateLicenseForm(): void {
    this.licenseForm.patchValue({
      entityName: this.license.entityName,
      entityWebsite: this.license.entityWebsite,
      secretaryofStateNumber: this.license.secretaryofStateNumber,
      registeredBusiness: this.license.registeredBusiness,
      registeredIn: this.license.registeredIn,
      registryNumber: this.license.registryNumber,
      businessInOregon: this.license.businessInOregon,
      registrationAttestation: this.license.registrationAttestation,
      financialAttestation: this.license.financialAttestation,
      physicalState: this.license.physicalState,
      physicalCity: this.license.physicalCity,
      physicalStreet: this.license.physicalStreet,
      physicalZip: this.license.physicalZip,
      physicalCounty: this.license.physicalCounty,
      premisesDescription: this.license.premisesDescription,
      mailingIsPhysical: this.license.mailingIsPhysical,
      mailingState: this.license.mailingState,
      mailingCity: this.license.mailingCity,
      mailingStreet: this.license.mailingStreet,
      mailingZip: this.license.mailingZip,
      mailingCounty: this.license.mailingCounty,
      primaryContactName: this.license.primaryContactName,
      primaryContactNumber: this.license.primaryContactNumber,
      primaryContactEmail: this.license.primaryContactEmail,
      primaryContactId: this.license.primaryContactId,
      language: this.license.language,
      previousLicense: this.license.previousLicense,
      affiliatedLicenses: this.license.affiliatedLicenses,
      fungiCultivation: this.license.fungiCultivation,
      psilocybinExtraction: this.license.psilocybinExtraction,
      ediblePsilocybinProduction: this.license.ediblePsilocybinProduction,
      edibleAttestation: this.license.edibleAttestation,
      denyEndorsementAttestation: this.license.denyEndorsementAttestation,
      electronicSignature: this.license.electronicSignature,
      attestation: this.license.attestation,
      status: this.license.status,
      directoryConsent: this.license.directoryConsent,
      directoryConsentWebsite: this.license.directoryConsentWebsite,
      directoryConsentEmail: this.license.directoryConsentEmail,
      directoryConsentPhone: this.license.directoryConsentPhone,
      directoryPhone: this.license.directoryPhone,
      directoryEmail: this.license.directoryEmail,
      directoryWebsite: this.license.directoryWebsite,
      approvedDate: this.license.approvedDate,
      renewedDate: this.license.renewedDate,
      timely: this.license.timely,
      hiddenFromTracker: this.license.hiddenFromTracker,
      applicantsCorrect: this.license.applicantsCorrect,
      applicantsCorrectReason: this.license.applicantsCorrectReason,
      financialInterestCorrect: this.license.financialInterestCorrect,
      financialInterestCorrectReason: this.license.financialInterestCorrectReason,
      manufacturerInformationCorrect: this.license.manufacturerInformationCorrect,
      manufacturerInformationCorrectReason: this.license.manufacturerInformationCorrectReason,
      endorsementsCorrect: this.license.endorsementsCorrect,
      endorsementsCorrectReason: this.license.endorsementsCorrectReason,
      attorneyContact: this.license.attorneyContact,
      attorneyName: this.license.attorneyName,
      attorneyEmail: this.license.attorneyEmail,
      attorneyPhone: this.license.attorneyPhone,
      attorneyNotes: this.license.attorneyNotes,
      attorneyConsent: this.license.attorneyConsent
    });

    if (this.license.inspectionCompletedDate != null && this.license.inspectionCompletedDate.length > 0)
      this.licenseForm.patchValue({
        inspectionCompletedDate: new Date(this.license.inspectionCompletedDate)
      });
    if(this.license.approvedDate != null && this.license.approvedDate.length > 0)
      {
        this.licenseForm.patchValue({
          approvedDate: new Date(this.license.approvedDate)
        });
      }
      if(this.license.renewedDate != null && this.license.renewedDate.length > 0)
      {
        this.licenseForm.patchValue({
          renewedDate: new Date(this.license.renewedDate)
        });
      }
  }

  updateLicenseDocumentTable(): void {
    this.documentSource.data = this.license.licenseDocuments;
    this.documentSource.sort = this.documentSort;
    this.documentSource.paginator = this.documentPaginator;
  }

  assignLicense(event: MatSelectChange, licenseId: number): void {
    let user: IStaff = this.licenseStaff.find(staff => staff.name === event.value);
    if(user === undefined){
      let emptyUser: IStaff = {
        id: '',
        name: '',
        email: ''
      }
      user = emptyUser;
    }
    this.licenseService.assignLicense(user, licenseId).subscribe(
      () =>{
        this.licenses.find(tp => tp.id == licenseId).assignedTo = user.name;
        if(user === null || user.name === null || user.name.length === 0) {
          this.toastr.success("Manufacturer unassigned");
        }
        else {
          this.toastr.success("Manufacturer assigned to " + user.name);
        }
      },
      error => console.log('error', error)
    );
  }

  assignComplianceMember(event: MatSelectChange, licenseId: number): void {
    let user: IStaff = this.licenseStaff.find(staff => staff.name === event.value);
    if(user === undefined){
      let emptyUser: IStaff = {
        id: '',
        name: '',
        email: ''
      }
      user = emptyUser;
    }
    this.licenseService.assignComplianceMember(user, licenseId).subscribe(
      () =>{
        this.licenses.find(tp => tp.id == licenseId).assignedToCompliance = user.name;
        if(user === null || user.name === null || user.name.length === 0) {
          this.toastr.success("Manufacturer compliance member unassigned");
        }
        else {
          this.toastr.success("Manufacturer compliance assigned to " + user.name);
        }
      },
      error => console.log('error', error)
    );
  }

  claimLicense(license: IManufacturerLicense): void {
    this.licenseService.claimLicense(license.id).subscribe(
      () => {
        this.getLicense(license.id);
        this.toastr.success("Manufacturer Claimed");
      },
      error => console.log('error', error)
    );
  }


  releaseLicense(licenseId: number, event: Event): void {
    event.preventDefault();
    this.licenseService.releaseLicense(licenseId).subscribe(
      () => {
        let index = this.licenses.findIndex(fl => fl.id == licenseId);
        this.licenses[index].assignedTo = '';
        if(this.assignedOnly && (this.licenses[index].assignedTo == '' || this.licenses[index].assignedTo == null)){
          this.licenses = this.licenses.filter(fl => fl.id !== licenseId);
          this.updateLicenseTable();
        }
        this.toastr.success("Manufacturer Team Member Released");
      },
      error => console.log('error', error)
    );
  }

  releaseComplianceMember(licenseId: number, event: Event): void {
    event.preventDefault();
    this.licenseService.releaseComplianceMember(licenseId).subscribe(
      () => {
        let index = this.licenses.findIndex(fl => fl.id == licenseId);
        this.licenses[index].assignedToCompliance = '';
        if(this.assignedOnly && (this.licenses[index].assignedTo == '' || this.licenses[index].assignedTo == null)){
          this.licenses = this.licenses.filter(fl => fl.id !== licenseId);
          this.updateLicenseTable();
        }
        this.toastr.success("Manufacturer Compliance Member Released");
      },
      error => console.log('error', error)
    );
  }

  clearWorkingLicense(): void{
    this.editingLicense = false;
    this.addingNewLicense = false;
    this.isNewLicense = false;
    this.licensePaid = false;
    this.license = this.getEmptyLicense();
    setTimeout(() => {
      this.licenseSource.data = this.licenses;
      this.licenseSource.sort = this.licenseSort;
      this.licenseSource.paginator = this.licensePaginator;
      this.updateLicenseForm();
    }, 200);
  }

  cancelWorkingLicense(): void{
    this.licenseChecklist.id = null;
    this.showChecklist = false;
    // if user is cancelling new, delete the record
    if(this.isNewLicense == true) {
      this.licenseService.deleteLicense(this.license.id).subscribe(
        () => {
          this.clearWorkingLicense();
        },
        error => console.log('error', error)
      );
      this.isNewLicense = false;
    }
    else {
      this.clearWorkingLicense();
    }
  }

  editLicense(): void{
    this.editingLicense = !this.editingLicense;
  }

  updateChecklist(checklist: ILicenseChecklist): void {
    this.licenseChecklist = checklist;
  }

  createChecklist(): void {
    this.checklist.createChecklist();
    this.showChecklist = true;
  }


  toggleChecklist(): void {
    this.showChecklist = !this.showChecklist;
  }

  validateBackgroundCheckCompleted(): boolean {
    for (const applicant of this.license.applicants) {
      if (!applicant.backgroundCheckDate) {
        return false;
      }
      const backgroundCheckDate = new Date(applicant.backgroundCheckDate);
      const diffInYears = (this.today.getTime() - backgroundCheckDate.getTime()) / (1000 * 60 * 60 * 24 * 365);
      if (diffInYears >= 5 || !applicant.backgroundCheckPassed) {
        return false;
      }
    }
    return true;
  }

  validateChecklist(): boolean {
    if(this.licenseChecklist.id == 0 || this.checklist == null)
      return false
    return this.checklist.isFormValid();
  }
  updateLicense(close: boolean): void {
    this.checklist.save();
    let form = this.licenseForm.value;
    this.license.entityName = form.entityName;
    this.license.entityWebsite = form.entityWebsite;
    this.license.businessInOregon = form.businessInOregon;
    this.license.registrationAttestation = form.registrationAttestation;
    this.license.registryNumber = form.registryNumber;
    this.license.registeredBusiness = form.registeredBusiness
    this.license.secretaryofStateNumber = form.secretaryofStateNumber;
    this.license.registeredIn = form.registeredIn;
    this.license.financialAttestation = form.financialAttestation;
    this.license.physicalState = form.physicalState;
    this.license.physicalCity = form.physicalCity;
    this.license.physicalStreet = form.physicalStreet;
    this.license.physicalZip = form.physicalZip;
    this.license.physicalCounty = form.physicalCounty;
    this.license.premisesDescription = form.premisesDescription;
    this.license.mailingIsPhysical = form.mailingIsPhysical;
    if(form.mailingIsPhysical){
      this.license.mailingState = form.physicalState;
      this.license.mailingCity = form.physicalCity;
      this.license.mailingStreet = form.physicalStreet;
      this.license.mailingZip = form.physicalZip;
      this.license.mailingCounty = form.physicalCounty;
    }
    else{
      this.license.mailingState = form.mailingState;
      this.license.mailingCity = form.mailingCity;
      this.license.mailingStreet = form.mailingStreet;
      this.license.mailingZip = form.mailingZip;
      this.license.mailingCounty = form.mailingCounty;
    }
    this.license.primaryContactName = form.primaryContactName;
    this.license.primaryContactNumber = form.primaryContactNumber;
    this.license.primaryContactEmail = form.primaryContactEmail;
    this.license.primaryContactId = form.primaryContactId;
    this.license.language = form.language;
    this.license.previousLicense = form.previousLicense;
    this.license.affiliatedLicenses = form.affiliatedLicenses;
    this.license.submittedForApproval = (form.status === this.sharedService.submittedForApproval || form.status === this.sharedService.renewalSubmittedForApproval);
    this.license.fungiCultivation = form.fungiCultivation;
    this.license.psilocybinExtraction = form.psilocybinExtraction;
    this.license.ediblePsilocybinProduction = form.ediblePsilocybinProduction;
    this.license.edibleAttestation = form.edibleAttestation;
    this.license.denyEndorsementAttestation = form.denyEndorsementAttestation;
    this.license.directoryConsent = form.directoryConsent;
    this.license.directoryConsentWebsite = form.directoryConsentWebsite;
    this.license.directoryConsentEmail = form.directoryConsentEmail;
    this.license.directoryConsentPhone = form.directoryConsentPhone;
    this.license.directoryPhone = form.directoryPhone;
    this.license.directoryEmail = form.directoryEmail;
    this.license.directoryWebsite = form.directoryWebsite;
    this.license.inspectionCompletedDate = form.inspectionCompletedDate;
    this.license.timely = form.timely;
    this.license.hiddenFromTracker = form.hiddenFromTracker;
    this.license.attestation = form.attestation;
    this.license.applicantsCorrect = form.applicantsCorrect;
    this.license.applicantsCorrectReason = form.applicantsCorrectReason;
    this.license.financialInterestCorrect = form.financialInterestCorrect;
    this.license.financialInterestCorrectReason = form.financialInterestCorrectReason;
    this.license.manufacturerInformationCorrect = form.manufacturerInformationCorrect;
    this.license.manufacturerInformationCorrectReason = form.manufacturerInformationCorrectReason;
    this.license.endorsementsCorrect = form.endorsementsCorrect;
    this.license.endorsementsCorrectReason = form.endorsementsCorrectReason;
    this.license.attorneyContact = form.attorneyContact;
    this.license.attorneyName = form.attorneyName;
    this.license.attorneyEmail = form.attorneyEmail;
    this.license.attorneyPhone = form.attorneyPhone;
    this.license.attorneyNotes = form.attorneyNotes;
    this.license.attorneyConsent = form.attorneyConsent;

    if(this.license.isRenewal)
    {
      this.license.approvedDate = form.approvedDate;
      this.license.renewedDate = form.renewedDate;
    }

    if(form.status === this.license.status){
      this.saveLicense(close);
      return;
    }

    this.sharedService.openConfirm('Change manufacturer status to ' + form.status + '?');
    this.sharedService.confirmed().subscribe(confirmed => {
      if(confirmed){
        if(this.license.status === this.sharedService.awaitingPayment){
          let task: ITask = this.license.tasks.find(t => (t.taskText === this.sharedService.approvedPendingPayment && (!t.archived && !t.complete)));
          if(task !== undefined){
            this.licenseService.deleteTask(task.id).subscribe(
              () => {},
              error => console.log('error', error)
            );
          }
        }
        this.license.status = form.status;
        setTimeout(() => {
          this.saveLicense(close);
        }, 500);
      }
    });
  }

  saveLicense(close: boolean): void {
    this.licenseService.updateLicense(this.license).subscribe(
      response => {
        this.license.approvedDate = response.approvedDate;
        this.license.expirationDate = response.expirationDate;
        this.taskSource.data = response.tasks.filter(t => !t.complete && !t.archived);
        this.license.tasks = response.tasks;
        let index = this.licenses.findIndex(fl => fl.id == response.id);
        if(index > -1){
          this.licenses[index] = response;
        }
        else{
          this.licenses.push(response);
        }
      },
      error => console.log('error', error),
      () => {
        this.isNewLicense = false;
        this.toastr.success("Manufacturer Updated!");
        if(close){
          this.clearWorkingLicense();
        }
      }
    );
  }

  openChangeLog() {
    this.dialog.open(AuditLogDialogComponent, {
      data: { entityId: this.license.id.toString(), entityType: this.sharedService.manufacturerEntityType},
      minWidth: !this.sharedService.mobile? '1000px' : '300px',
      maxWidth: !this.sharedService.mobile? '1000px' : '300px',
      maxHeight: !this.sharedService.mobile? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
  }

  exportLicense(){
    this.auditLogService.initiateSearch(this.license.id.toString(), this.sharedService.manufacturerEntityType).subscribe(
      search => {
        search.displayLicense = true;
        search.displayApplicants = true;
         this.licenseService.getPDF(search).subscribe(
          response => {
            this.dialog.open(PrintManufacturerComponent, {
              data: response,
              role: 'dialog',
              ariaLabel: 'Original Manufacturer License',
              minWidth: !this.sharedService.mobile ? '900px' : '300px',
              maxWidth: !this.sharedService.mobile ? '980px' : '300px'
            });
          },
      error => {
        console.log(error);
      });
      },
      error => {
        console.log(error);
      }
    );
  }

  openContactInformationChangeLog() {
    this.dialog.open(ContactInformationDialogComponent, {
      data: { entityId: this.license.id.toString(), entityType: this.sharedService.manufacturerEntityType},
      minWidth: !this.sharedService.mobile? '700px' : '300px',
      maxWidth: !this.sharedService.mobile? '800px' : '300px',
      maxHeight: !this.sharedService.mobile? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
  }

  setPointOfContact(applicant: IApplicant): void {
    this.licenseForm.patchValue({
      primaryContactName: applicant.legalFirstName + ' ' + applicant.legalLastName,
      primaryContactNumber: applicant.phone,
      primaryContactEmail: applicant.email,
      primaryContactId: applicant.applicantId,
      language: applicant.language,
    });
    this.updateLicense(false);
  }

  editApplicant(editing: boolean): void {
    this.editingApplicant = editing;
  }

  deduplicateApplicant(dedeuplicating: boolean): void {
    this.dedeuplicating = dedeuplicating;
  }

  updateApplicant(applicant: IApplicant): void {
    let index = this.license.applicants.findIndex(a => a.id == applicant.id);
    if(index == -1)
      this.license.applicants.push(applicant);
    else
      this.license.applicants[index] = applicant;
  }

  deleteApplicant(applicantId: Number): void {
    this.license.applicants = this.license.applicants.filter(a => a.id !== applicantId);
    console.log(this.license.applicants);
  }

  editEntityApplicant(editing: boolean): void {
    this.editingEntityApplicant = editing;
  }

  uploadLicenseDocument(event: Event): void {
    let documentForm = this.documentsForm.value;
    let types: number[] = [];

    if (documentForm.lucs) {
      types.push(this.lucsType);
    }
    if (documentForm.premisesSketch){
      types.push(this.premisesType);
    }
    if (documentForm.ownerConsent){
      types.push(this.ownerConsentType);
    }
    if (documentForm.socialEquityPlan) {
      types.push(this.socialEquityType);
    }
    if (documentForm.reducedFee) {
      types.push(this.reducedFeeType);
    }
    if (documentForm.physical) {
      types.push(this.physicalType);
    }
    if (documentForm.other) {
      types.push(this.otherType);
    }
    if (documentForm.gisReview) {
      types.push(this.gisReviewType);
    }
    if (documentForm.interviewDocumentation) {
      types.push(this.interviewDocumentationType);
    }
    if (documentForm.siteInspectionFollowUp) {
      types.push(this.siteInspectionFollowUpType);
    }
    if (documentForm.caseDocumentation) {
      types.push(this.caseDocumentationType);
    }
    if(documentForm.premisePicture) {
      types.push(this.premisePictureType);
    }
    if (documentForm.updatedEquityPlan) {
      types.push(this.updatedEquityPlanType);
    }
    if (documentForm.equityPlanEvaluation) {
      types.push(this.equityPlanEvaluationType);
    }
    if (documentForm.updatedPremisePicture) {
      types.push(this.updatedPremisePictureType);
    }

    if(types.length > 0){
      let dirtyFile = (event.target as HTMLInputElement).files[0];
      let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, ''));
      let upload: IDocument = {
        id: 0,
        name: file.name,
        comments: "",
        extension: "",
        dateCreated: "",
        dateLastUpdated: "",
        createdBy: "",
        lastUpdatedBy: "",
        parentId: this.license.id,
        types: types,
        deprecated: false,
        pendingUpdate: false,
        adminOnly: false
      }

      const formData = new FormData();
      formData.append("file", file, file.name);
      formData.append("document", JSON.stringify(upload));
      this.licenseService.uploadDocument(formData).subscribe(
        response => this.license.licenseDocuments.unshift(response),
        error => {
          (event.target as HTMLInputElement).value = '';
          console.log('error', error);
        },
        () => {
          (event.target as HTMLInputElement).value = '';
          this.validateLicenseDocuments();
          this.documentsForm.patchValue({
            lucs: false,
            premisesSketch: false,
            ownerConsent: false,
            socialEquityPlan: false,
            reducedFee: false,
            physical: false,
            other: false,
            gisReview: false,
            interviewDocumentation: false,
            siteInspectionFollowUp: false,
            caseDocumentation: false,
            premisePicture: false,
            updatedEquityPlan: false,
            equityPlanEvaluation: false,
            updatedPremisePicture: false
          });
        });
    }
    else{
      (event.target as HTMLInputElement).value = '';
      this.toastr.error("Please select at least one requirement met by the document");
    }
  }

  downloadDocument(fileId: number, fileName: string): void {
    this.licenseService.downloadDocument(fileId).subscribe(
      (response) => this.saveFile(fileName, response),
      (error) => console.log("error", error)
    );
  }

  saveFile(fileName: string, blob: Blob) {
    let file = URL.createObjectURL(blob);
    var fileDownload = document.createElement("a");
    fileDownload.href = file;
    fileDownload.download = fileName;
    fileDownload.click();
  }

  deleteDocument(id: number, name: string) {
    this.sharedService.openConfirm("Delete " + name + "?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.licenseService.deleteDocument(id).subscribe(
            () => {
              this.license.licenseDocuments = this.license.licenseDocuments.filter(item => item.id !== id);
              this.validateLicenseDocuments();
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  deprecateDocument(id: number) {
    let archived = false;
    let currentDocumentIndex = this.license.licenseDocuments.findIndex(ad => ad.id == id);
    archived = this.license.licenseDocuments[currentDocumentIndex].deprecated;
    this.sharedService.openConfirm(archived ? "Unarchive Document?" : "Archive Document?");
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.licenseService.deprecateDocument(id).subscribe(
            () => {
                let index = this.license.licenseDocuments.findIndex(ad => ad.id == id);
                this.license.licenseDocuments[index].deprecated = !this.license.licenseDocuments[index].deprecated;
            },
            error => console.log('error', error)
          );
        }
      }
    );
  }

  viewArchivedDocuments(): void {
    this.viewArchived = !this.viewArchived;
  }

  validateLicenseDocuments(): void {
    this.documentsValid = true;
    let requiredTypes: number[] = [
      this.lucsType,
      this.premisesType,
      this.socialEquityType,
      this.premisePictureType
    ];

    let types: number[] = [];
    this.license.licenseDocuments.forEach(ld => ld.types.forEach(t => types.push(t)));

    requiredTypes.forEach(type => {
      if(!types.includes(type)){
        this.documentsValid = false;
      }
    });
  }

  complianceCreated(compliance: ICompliance): void {
    this.compliance = compliance;
    this.selectedIndex = 1;
  }

  acknowledgeTask(taskId: number): void {
    this.sharedService.openConfirm('Acknowledge Task?');
    this.sharedService.confirmed().subscribe(confirmed => {
      if(confirmed){
    this.licenseService.acknowledgeTask(taskId).subscribe(
      () => {
        this.updateTasks(taskId,'send');
        this.toastr.success('Task Acknowledged!');
      },
      error => console.log('error', error)
    );
  }
});
  }

  sendTaskNotice(task: ITask): void {
    if(task.requiresReason){
      const dialogRef = this.dialog.open(EmailReasonDialog, {
        minWidth: !this.sharedService.mobile? '400px' : '300px',
        maxWidth: !this.sharedService.mobile? '700px' : '300px',
        maxHeight: !this.sharedService.mobile? '1000px' : '500px',
        panelClass: this.sharedService.darkMode ? "theme-dark" : ""
      });
      dialogRef.afterClosed().subscribe((response) => {
        if (!this.sharedService.isCancelled(response)) {
          task.reason = response;
          this.licenseService.sendTaskNotice(task).subscribe(
            () => {
              this.updateTasks(task.id, 'send');
              this.toastr.success('Notice Sent!');
            },
            error => console.log('error', error)
          );
        }
      });
    }
    else{
      this.sharedService.openConfirm(task.taskText + '?');
      this.sharedService.confirmed().subscribe(confirmed => {
        if(confirmed){
          task.reason = 'none';
          this.licenseService.sendTaskNotice(task).subscribe(
            () => {
              this.updateTasks(task.id,'send');
              this.toastr.success('Notice Sent!');
            },
            error => console.log('error', error)
          );
        }
      });
    }
  }

  deleteTask(task: ITask): void {
    this.sharedService.openConfirm('Archive ' + task.taskText + '?');
    this.sharedService.confirmed().subscribe(confirmed => {
      if(confirmed){
        this.licenseService.deleteTask(task.id).subscribe(
          () => this.updateTasks(task.id, 'delete'),
          error => console.log('error', error)
        );
      }
    });
  }

  getCurrentTime(): string{
    const currentDate = new Date();

    const formattedDate = currentDate.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });

    const formattedTime = currentDate.toLocaleTimeString('en-US', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    });

    return formattedDate + ' ' + formattedTime;
  }

  private updateTasks(taskId: number, action:string): void {
    let index = this.licenses.findIndex(license => license.id === this.license.id);
    let taskIndex = this.license.tasks.findIndex(t => t.id === taskId);
    this.licenses[index].tasks = this.licenses[index].tasks.filter(t => t.id !== taskId);
    this.license.tasks[taskIndex].dateLastUpdated = this.getCurrentTime();
    if(action == 'delete'){
      this.license.tasks[taskIndex].archived = true;
    }
    if(action == "send"){
      this.license.tasks[taskIndex].complete = true;
    }
    this.taskSource.data = this.license.tasks.filter(t => !t.complete && !t.archived);
  }

  paymentUpdated(payments: IPayment[]) {
    this.license.payments = payments;
    this.licensePaid = this.license.payments.filter(payment => {
      return payment.type === this.paymentService.applicationFeeType ||
             payment.type === this.paymentService.licenseFeeType ||
             payment.type === this.paymentService.reducedLicenseFeeType;
      }).every(payment => payment.paid) && (this.license.payments.length > 1 || (this.license.isRenewal && this.license.payments.length >= 1));
    //update tasks
    let index = this.licenses.findIndex(tp => tp.id == this.license.id);
    let applicationPaid: boolean = payments.some(p => p.type === this.paymentService.applicationFeeType && p.paid);
    let needsLicensePayment: boolean = payments.some(p => (p.type === this.paymentService.licenseFeeType ||
                                                           p.type === this.paymentService.reducedLicenseFeeType) &&
                                                           !p.paid);
    if(applicationPaid) {
      this.license.tasks = this.license.tasks.filter(task => task.taskText !== this.sharedService.applicationPaymentDue);
      this.licenses[index].tasks = this.licenses[index].tasks.filter(task => task.taskText !== this.sharedService.applicationPaymentDue);
    }
    else{
      if(!this.license.isRenewal)
        this.updatePaymentTask(this.sharedService.applicationPaymentDue, index);
    }

    if(!needsLicensePayment){
      this.license.tasks = this.license.tasks.filter(task => task.taskText !== this.sharedService.licensePaymentDue);
      this.licenses[index].tasks = this.licenses[index].tasks.filter(task => task.taskText !== this.sharedService.licensePaymentDue);;
    }
    else{
      this.updatePaymentTask(this.sharedService.licensePaymentDue, index);
    }

    if(this.licensePaid && this.license.status != this.sharedService.approved && this.license.status != this.sharedService.renewed)
    {
      this.updatePaymentTask(this.sharedService.PaymentClearedPendingApproval, index);
    }
    else
    {
      this.license.tasks = this.license.tasks.filter(task => task.taskText !== this.sharedService.PaymentClearedPendingApproval);
      this.licenses[index].tasks = this.licenses[index].tasks.filter(task => task.taskText !== this.sharedService.PaymentClearedPendingApproval);
    }

    setTimeout(() => {
      this.taskSource.data = this.license.tasks.filter(t => !t.complete && !t.archived);
    }, 100);
  }

  private updatePaymentTask(taskText: string, index: number): void {
    let newTask: ITask = {
      id: 0,
      taskText: '',
      entityId: this.license.id.toString(),
      entityType: 'ManufacturerLicense',
      complete: false,
      completedDate: '',
      reason: '',
      sendNotice: false,
      requiresReason: false,
      acknowledge: false,
      dateCreated: '',
      dateLastUpdated: '',
      createdBy: '',
      lastUpdatedBy: '',
      canEdit: false,
      archived: false
    }

    let hasTask: boolean = this.license.tasks.some(task => task.taskText === taskText);
    if(!hasTask){
      newTask.taskText = taskText;
      this.licenses[index].tasks.push(newTask);
      this.license.tasks.push(newTask);
    }
  }

  public get hasPaidApplicationFee(): boolean {
    if(this.license.payments == null || this.license.payments.length == 0) {
      return false;
    }

    return this.license.payments.some(tp => tp.paid && tp.type == this.paymentService.applicationFeeType);
  }

  changeStep(step: number): void {
    this.selectedIndex = step;
    if(step === 0){
      this.compliance = {
        id: 0,
        caseId: '',
        licenseId: '',
        entityId: 0,
        entityType: '',
        status: 'New',
        category: '',
        tier: '',
        dueDate: '',
        closedDate: '',
        type: '',
        sent: null,
        assignedTo: '',
        manager: '',
        outcome: '',
        agencyGenerated: false,
        locked: false,
        dateOfNonCompliance: '',
        dateOfCompliance: '',
        originalLicenseId: '',
        complianceRules: [],
        complaints: [],
        notes: [],
        complianceActions: [],
        investigations: [],
        compliancePlans: [],
        relatedCases: [],
        notices: [],
        referencedLicenses: [],
        complianceDocuments: [],
        noticeStatus: '',
        denialCriteria: '',
        administrativeAction: '',
      }
      this.reportedEvent = {
        id: 0,
        eventId: '',
        eventType: '',
        eventDate: '',
        eventTime: '',
        description: '',
        entityId: 0,
        entityType: '',
        licenseesInvolved: '',
        reoccurringEvent: '',
        emergencyService: false
      }
    }
  }

  addUserAccount(): void {
    let user: IEditAddUserProfile = {
      userId: '',
      firstName: '',
      middleInitial: '',
      lastName: '',
      currentPage: '',
      currentStep: '',
      theme: '',
      email: '',
      phone: '',
      dob: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      county: '',
      licenseType: 'Manufacturer License'
    }
    this.updateUserAccount(user);
  }

  editUserAccount(): void {
    let splitName: string[] = this.license.accountName.split(' ');
    let user: IEditAddUserProfile = {
      userId: this.license.userId,
      firstName: splitName[0],
      middleInitial: '',
      lastName: splitName[1],
      currentPage: '',
      currentStep: '',
      theme: '',
      email: this.license.accountEmail,
      phone: '',
      dob: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      county: '',
      licenseType: 'Manufacturer License'
    }

    this.updateUserAccount(user);
  }

  updateUserAccount(user: IEditAddUserProfile): void {
    const dialogRef = this.dialog.open(AddUserDialogComponent, {
      data: user,
      closeOnNavigation: false,
      minWidth: !this.sharedService.mobile? '400px' : '',
      maxWidth: !this.sharedService.mobile? '700px' : '300px',
      maxHeight: !this.sharedService.mobile? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : "",
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        this.license.userId = response.userId;
        this.licenseService.updateLicenseAccount(this.license.id, response.userId).subscribe(
          () => {
            this.license.accountEmail = response.email;
            this.license.accountName = response.firstName + " " + response.lastName;
            this.license.accountIsB2C = response.accountIsB2C;
          },
          error => console.log('error', error)
        );
      }
    });
  }

  openTaskHistory(){
    const dialogRef = this.dialog.open(TaskHistoryComponent, {
      minWidth: !this.sharedService.mobile? '800px' : '',
      maxWidth: !this.sharedService.mobile? '700px' : '300px',
      maxHeight: !this.sharedService.mobile? '1000px' : '500px',
      data: this.license.tasks.filter(t => (t.complete || t.archived)),
    });
    dialogRef.afterClosed().subscribe(result => { });
  }

  openAddMessageDialog(name: string, email: string) {
    const dialogRef = this.dialog.open(DialogAddMessageComponent, {
      width: '1000px',
      data: {
        id: '0',
        name: name,
        email: email
      },
    });
    dialogRef.afterClosed().subscribe(result => { });
  }

  //Custom Validators
  previousLicenseValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasPreviousLicense = control.value.previousLicense;
      const affiliatedLicenses = control.value.affiliatedLicenses;
      if(!hasPreviousLicense){
        return null;
      }
      return (affiliatedLicenses !== null && affiliatedLicenses !== '' && affiliatedLicenses !== undefined) ? null : { licensesRequired: true };
    }
  }

  validatePreviousLicense(): boolean {
    if (this.licenseForm.hasError('licensesRequired') && this.licenseForm.get('affiliatedLicenses').touched) {
      this.licenseForm.get('affiliatedLicenses').setErrors(['licensesRequired']);
      return true;
    }
    this.licenseForm.get('affiliatedLicenses').clearValidators();
    this.licenseForm.get('affiliatedLicenses').updateValueAndValidity();
    return false;
  }

  mailingIsSame(): void {
    this.licenseForm.get('mailingStreet').updateValueAndValidity();
    this.licenseForm.get('mailingCity').updateValueAndValidity();
    this.licenseForm.get('mailingState').updateValueAndValidity();
    this.licenseForm.get('mailingZip').updateValueAndValidity();
  }

  mailingStreetValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const street = control.value.mailingStreet;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (street !== null && street !== '' && street !== undefined) ? null : { mailingStreetRequired: true };
      }
      return null;
    };
  }

  validateMailingStreet(): boolean {
    if (this.licenseForm.hasError('mailingStreetRequired') && this.licenseForm.get('mailingStreet').touched) {
      this.licenseForm.get('mailingStreet').setErrors(['required']);
      return true;
    }
    this.licenseForm.get('mailingStreet').clearValidators();
    this.licenseForm.get('mailingStreet').updateValueAndValidity();
    return false;
  }

  mailingCityValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const city = control.value.mailingCity;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (city !== null && city !== '' && city !== undefined) ? null : { mailingCityRequired: true };
      }
      return null;
    };
  }

  validateMailingCity(): boolean {
    if (this.licenseForm.hasError('mailingCityRequired') && this.licenseForm.get('mailingCity').touched) {
      this.licenseForm.get('mailingCity').setErrors(['required']);
      return true;
    }
    this.licenseForm.get('mailingCity').clearValidators();
    this.licenseForm.get('mailingCity').updateValueAndValidity();
    return false;
  }

  mailingStateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const state = control.value.mailingState;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (state !== null && state !== '' && state !== undefined) ? null : { mailingStateRequired: true };
      }
      return null;
    };
  }

  validateMailingState(): boolean {
    if (this.licenseForm.hasError('mailingStateRequired') && this.licenseForm.get('mailingState').touched) {
      this.licenseForm.get('mailingState').setErrors(['required']);
      return true;
    }
    this.licenseForm.get('mailingState').clearValidators();
    this.licenseForm.get('mailingState').updateValueAndValidity();
    return false;
  }

  mailingZipValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const zip = control.value.mailingZip;
      const same = control.value.mailingIsPhysical;
      if (!same) {
        return (zip !== null && zip !== '' && zip !== undefined) ? null : { mailingZipRequired: true };
      }
      return null;
    };
  }

  validateMailingZip(): boolean {
    if (this.licenseForm.hasError('mailingZipRequired') && this.licenseForm.get('mailingZip').touched) {
      this.licenseForm.get('mailingZip').setErrors(['required']);
      return true;
    }
    this.licenseForm.get('mailingZip').clearValidators();
    this.licenseForm.get('mailingZip').updateValueAndValidity();
    return false;
  }
  //End of Validators
  openRenewalChangeLog() {
    const dialogRef = this.dialog.open(RenewalChangesDialogComponent, {
      data: { entityId: this.license.id.toString(), entityType: this.sharedService.manufacturerEntityType, startDate:this.license.createdDate, endDate: this.license.applicationDate},
      minWidth: !this.sharedService.mobile? '700px' : '300px',
      maxWidth: !this.sharedService.mobile? '800px' : '300px',
      maxHeight: !this.sharedService.mobile? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });

    dialogRef.componentInstance.highlightChanges.subscribe(result => {
      if(this.changedFields.length == 0)
        this.changedFields = result;
      else
        this.changedFields = [];
    });
  }

  isFieldUpdated(propertyName: string, tableName: string, keyValue: string): boolean {
    return this.changedFields.some(field =>
      field.propertyName === propertyName &&
      field.tableName === tableName &&
      field.keyValue === keyValue
    );
  }

  createRenewal(license: IManufacturerLicense): void {

    this.sharedService.openConfirm("Are you sure you want to start a renewal application?");

    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.licenseService.createRenewalLicense(license.id).subscribe(
            response =>
            {
              this.hasRenewal = true;
              this.license = response;
              this.showChecklist = false;
              this.editingLicense = true;
              this.updateLicenseForm();
              this.updateLicenseDocumentTable();
              this.licensePaid = this.license.payments.filter(payment => {
                return payment.type === this.paymentService.applicationFeeType ||
                       payment.type === this.paymentService.licenseFeeType ||
                       payment.type === this.paymentService.reducedLicenseFeeType;
                }).every(payment => payment.paid) && (this.license.payments.length > 1 || (this.license.payments.length >= 1 && this.license.isRenewal));
              this.taskSource.data = this.license.tasks.filter(t => !t.complete && !t.archived);
              this.licenseService.getExpiredLicenses(this.license.licenseId).subscribe(
                response => {
                  this.expiredLicensesSource.data = response.filter(t => t.id != this.license.id);
                  this.expiredLicensesSource.sort = this.expiredLicensesSort;
                  this.expiredLicensesSource.paginator = this.expiredLicensesPaginator;
                  if(response.filter(t => t.status == this.sharedService.renewalSubmitted || t.status == this.sharedService.pendingRenewal ||
                    t.status == this.sharedService.renewalReviewTimely || t.status == this.sharedService.renewalReviewUntimely || t.status == this.sharedService.renewalApprovedPendingPaymentandClearance).length == 0)
                    this.hasRenewal = false;
                },
                error => console.log('error', error)
              );
            },
            error =>
            {
              console.log('error', error)
            });
    }});
  }

  canTransfer(): boolean {
    if(this.license.status != 'Renewed')
      return false;
    const licenses = this.expiredLicensesSource.data.filter(l => l.status == 'Approved');
    if(licenses.length == 1)
      return true;
    return false;
  }

  transferPTS(expiredLicenseId: number): void{
    this.sharedService.openConfirm("Are you sure you want to transfer all PTS items to the new license?");

    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.licenseService.transferPTSItems(expiredLicenseId, this.expiredLicensesSource.data.find(l => l.status == 'Approved').id).subscribe(
            response =>
            {
              if(response)
                this.toastr.success("PTS items transferred");
              else
              this.toastr.error("Could not transfer PTS items");
            },
            error =>
            {
              console.log('error', error)
            });
    }});
  }

  removePTSPermit(permitId: string) : void{
    this.license.workerPermits = this.license.workerPermits.filter(wp => wp.licenseId !== permitId);
  }

  addPermit(permits: IWorkerLicense[]): void {
    this.license.workerPermits = [...this.license.workerPermits, ...permits]
  }

  openManagerNotes(license: ISimpleLicense): void {
    const dialogRef = this.dialog.open(ManagerNotesDialogComponent, {
      width: '800px',
      disableClose: true,
      data: {
        entityId: license.id,
        entityType: this.sharedService.manufacturerEntityType,
        managerFlag: license.managerFlag,
        reviewType: license.reviewType,
        managerNotes: license.managerNotes,
        isAdmin: this.authService.isAdmin
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        license.managerFlag = result.flag;
        license.reviewType = result.reviewType;
        license.managerNotes = result.notes;
      }
     });
  }

  managerNotesUpdated(managerNotes: IManagerNotes): void {
    this.license.managerFlag = managerNotes.flag;
    this.license.reviewType = managerNotes.reviewType;
    this.license.managerNotes = managerNotes.notes;
  }
}

@Component({
  selector: "manufacturer-details-dialog",
  templateUrl: "../manufacturer-proof-of-license/manufacturer-details.html"
})

export class ManufacturerDetailsDialog {
  public url = "";
  public applicants: IApplicant[] = [];
  public entityApplicants: IEntityApplicant[] = [];

  constructor(public dialogRef: MatDialogRef<ManufacturerDetailsDialog>,
    public sharedService: SharedService,
    public paymentService: PaymentService,
    public applicantService: ApplicantService,
    @Inject(MAT_DIALOG_DATA) public license: IManufacturerLicense) {
      if(environment.production)
        this.url = "https://psilocybin.oregon.gov/";
      else
        this.url = "https://test-psilocybin.oregon.gov/"
    }

    ngOnInit() {
      this.applicantService.getApplicants(this.sharedService.manufacturer, this.license.id).subscribe(
        response => this.applicants = response.filter(a => !a.archived),
        error => console.log('error', error),
      );
      this.applicantService.getEntityApplicants(this.sharedService.manufacturer, this.license.id).subscribe(
        response => this.entityApplicants = response.filter(a => !a.archived),
        error => console.log('error', error),
      );
    }

    print() {
      window.print();
    }

  cancel(): void {
    this.dialogRef.close('cancel');
  }
}
